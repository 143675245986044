import { FC } from "react";
import { Button, Modal } from "@epcnetwork/core-ui-kit";

import deleteImg from "assets/images/delete.svg";
import styles from "./delete-error-modal.module.css";

type DeleteErrorModalProps = {
  value: string;
  isOpen: boolean;
  closeModal: VoidFunction;
};

export const DeleteErrorModal: FC<DeleteErrorModalProps> = ({ isOpen, closeModal, value }) => {
  return (
    <Modal isOpen={isOpen} setClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.imgWrapper}>
          <img width={14} height={16} src={deleteImg} alt="" className={styles.img} />
        </div>
        <div className={styles.title}>Ops!</div>
        <div className={styles.subtitle}>
          But you haven't any access to delete <br /> <span className={styles.value}>{value}</span>
        </div>
        <Button className={styles.button} onClick={closeModal}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};
