import Smartlook from "smartlook-client";
import { FileQueueItem } from "@epcnetwork/core-ui-kit";

import { appEnvironment } from "config/environment.config";

export const useTracking = () => {
  const init = () => {
    Smartlook.init(appEnvironment.smartlookKey);
  };

  const pipelineSubmitEvent = (eventName: string, files: FileQueueItem[]) => {
    const fileSize = files.reduce((acc, item) => acc + item.originalFile.size, 0);

    Smartlook.track(eventName, { fileSize, totalFiles: files.length });
  };

  return { init, pipelineSubmitEvent };
};
