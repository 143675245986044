import { FC } from "react";

import { SlideType } from "../login-slider";

import styles from "./slider-content.module.css";

type SliderContentProps = {
  currentSlide: SlideType;
};

const SliderContent: FC<SliderContentProps> = ({ currentSlide }) => {
  return (
    <div className={styles.slideContent}>
      <img className={styles.slideImg} src={currentSlide.img} alt="" />
      <div className={styles.slideDescription}>{currentSlide.description}</div>
    </div>
  );
};

export { SliderContent };
