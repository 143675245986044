import { FC, useCallback, useRef, useState } from "react";
import { FormikErrors, FormikHelpers, FormikProps } from "formik";
import {
  Button,
  Form,
  FormButtons,
  FormField as UiKitFormField,
  FormField,
  Loader,
  MessageField,
  Modal,
  SelectOption,
  useCall,
  useDidMount,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { getFileUuidName } from "utils";
import { FormFileTableHeaders } from "types";
import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { FormField as LocalFormField } from "components";
import { PipelinesDomain } from "api/pipelines/pipelines.interface";
import { getFileEmailsDomains } from "api";
import { DomainOption, WarmUpFileItem, WarmUpValues } from "../warm-up.types";
import { typeOptions, validationSchema } from "../warm-up.constants";
import { TotalEmails } from "./total-emails/total-emails";

import baseStyles from "../warm-up.module.css";
import styles from "./warm-up-form-modal.module.css";

type WarmUpModalFormProps = {
  file: WarmUpFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: WarmUpFileItem) => void;
};

export const WarmUpFormModal: FC<WarmUpModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const formikRef = useRef<FormikProps<WarmUpValues> | null>(null);
  const { loading, parsedFile, error } = useFileParser(file.originalFile);
  const [isInEdit, setIsInEdit] = useState(false);

  const initialEmailsNumber = file.data.days.reduce((acc, cur) => {
    if (!cur.data) return acc;

    cur.data.forEach((c) => {
      acc += c.numberOfEmails;
    });

    return acc;
  }, 0);

  const [initialValues] = useState<WarmUpValues>({
    fileValues: {
      hasHeaders: file.data.hasHeaders,
      emailIndex: file.data.emailIndex,
      headers: file.data.headers,
      suppress: file.data.suppress,
    },
    type: file.data.type,
    daysNumber: file.data.days.length,
    domainNumber: file.data.days[0].data.length,
    initialEmailsNumber,
    domainsEmailsSpread: file.data.days[0].data,
  });
  const [, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [domains, setDomains] = useState<PipelinesDomain[]>([]);
  const [domainOptions, setDomainOptions] = useState<SelectOption<DomainOption["domain"]>[]>([]);

  const {
    submit,
    submitting,
    onCallSuccess,
    onCallError,
    error: countError,
  } = useCall(getFileEmailsDomains.onRequestProgress(setProgress));
  onCallSuccess((payload) => {
    setTotal(payload.total);
    setDomains(payload.domains);
    setDomainOptions(
      payload.domains.map((domain) => ({
        label: `${domain.key} (${domain.amount})`,
        value: domain.key,
      })),
    );
  });
  onCallError((error) => {
    formikRef.current?.setFieldError("fileValues", error.message);
  });

  const totalFromSelectedDomains = useCallback(
    (selectedDomains: DomainOption[]) => {
      if (!domains) return undefined;

      return selectedDomains.reduce((acc, value) => {
        const currentDomain = domains.find((domain) => domain.key === value.domain);
        if (currentDomain) return acc + currentDomain.amount;
        return acc;
      }, 0);
    },
    [domains],
  );

  const onSubmit = (values: WarmUpValues, { setSubmitting }: FormikHelpers<WarmUpValues>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.fileValues,
        type: values.type,
        days: getDays(values.daysNumber, values.domainsEmailsSpread),
      },
    });
    setSubmitting(false);
  };

  const getDays = (
    daysNumber: WarmUpValues["daysNumber"],
    domainsEmailsSpread: WarmUpValues["domainsEmailsSpread"],
  ) => {
    return getDaysFields(Number(daysNumber)).map((index) => {
      const daySpread = domainsEmailsSpread.map((spread) => ({
        numberOfEmails: getEmailsSpread(
          Number(spread.numberOfEmails || 0),
          getCountByDomain(spread.domain),
          index,
        ),
        domain: spread.domain,
      }));
      return { data: daySpread };
    });
  };

  const getDayEmailsSpread = (initialCount: number, index: number) => {
    const days = getDaysFields(index + 1);

    return days.reduce((acc, index) => {
      if (index === 0) {
        acc = initialCount;
      } else {
        acc *= 2;
      }
      return acc;
    }, 0);
  };

  const getFullSpread = (initialCount: number, index: number) => {
    const days = getDaysFields(index + 1);

    return days.reduce((acc, index) => {
      acc += getDayEmailsSpread(initialCount, index);
      return acc;
    }, 0);
  };

  useDidMount(() => {
    if (initialValues.fileValues.emailIndex === -1) return;
    const formData = new FormData();
    formData.append(
      "fileInfo",
      JSON.stringify({
        emailIndex: initialValues.fileValues.emailIndex,
      }),
    );
    formData.append("file", file.originalFile, file.data.fileName);

    submit({
      data: formData,
    });
    setIsInEdit(true);
  });

  const getEmailsSpread = (initialValue: number, maxSpread: number, index = 0): number => {
    if (initialValue <= 0) return 0;

    const currentSpread = getTypeNumberOfEmails(getDayEmailsSpread(initialValue, index));
    const fullSpread = getTypeNumberOfEmails(getFullSpread(initialValue, index - 1));
    const possibleSpread = Math.max(0, maxSpread - fullSpread);

    if (fullSpread + currentSpread > maxSpread) {
      return possibleSpread;
    }
    return currentSpread;
  };

  const getDomainsFields = (value: number) => {
    if (Number(value) < 0) {
      return Array.from(Array(1).keys());
    }
    return Array.from(Array(Number(value)).keys());
  };

  const getDaysFields = (value: number) => getDomainsFields(value);

  const onEmailSelect = (value: FormFileTableHeaders) => {
    setProgress(0);
    formikRef.current?.setFieldValue("domainsEmailsSpread", []);

    if (value.emailIndex !== -1) {
      const formData = new FormData();
      formData.append(
        "fileInfo",
        JSON.stringify({
          emailIndex: value.emailIndex,
        }),
      );
      formData.append("file", file.originalFile, getFileUuidName(file));

      submit({
        data: formData,
      });
    }
  };

  const resetEmailsSpread = (domainsEmailsSpread: WarmUpValues["domainsEmailsSpread"]) => {
    formikRef.current?.setFieldValue(
      "domainsEmailsSpread",
      domainsEmailsSpread.map((domain) => ({
        ...domain,
        numberOfEmails: 0,
      })),
    );
  };

  const adjustDomainsList = (
    domainNumber: WarmUpValues["domainNumber"],
    domainsEmailsSpread: WarmUpValues["domainsEmailsSpread"],
  ) => {
    formikRef.current?.setFieldValue(
      "domainsEmailsSpread",
      domainsEmailsSpread.filter((_, index) => index < Number(domainNumber)),
    );
  };

  const getCountByDomain = (domain: string | undefined): number => {
    const currDomain = domains.find((d) => d.key === domain);

    return currDomain?.amount || 0;
  };

  const getDomainOptions = (currentDomain?: string | undefined) => {
    if (formikRef.current) {
      const domains = formikRef.current.values.domainsEmailsSpread;
      const usedValues = (domains || []).map((domain) => domain.domain);
      return domainOptions.filter(
        (domain) =>
          !usedValues.find(
            (usedDomain) => usedDomain === domain.value && usedDomain !== currentDomain,
          ),
      );
    }
    return domainOptions;
  };

  const getEmailsCount = (domains: { numberOfEmails: number; domain: string }[]) => {
    return domains.reduce((acc, curr) => {
      acc += Number(curr?.numberOfEmails || 0);
      return acc;
    }, 0);
  };

  const getTypeNumberOfEmails = (numberOfEmails: number) => {
    const initialEmailsNumber = formikRef?.current?.values?.initialEmailsNumber || 100;
    const type = formikRef?.current?.values?.type || 1;

    if (type === 2) {
      return Math.floor((Number(initialEmailsNumber) * numberOfEmails) / 100);
    }
    return numberOfEmails;
  };

  const handleChangeDomain = (index: number) => {
    if (isInEdit) {
      formikRef.current?.setFieldValue(`domainsEmailsSpread[${index}].numberOfEmails`, 0);
    }
  };

  const handleChangeInitNumbers = (value: string, index: number) => {
    const cloneArr = [...(formikRef.current?.values?.domainsEmailsSpread || [])];
    cloneArr[index].numberOfEmails = Number(value);
    const count = getEmailsCount(cloneArr);
    formikRef.current?.setFieldValue("initialEmailsNumber", count);
  };

  const getError = (error: FormikErrors<DomainOption[]> | string | string[] | undefined) => {
    if (typeof error === "string") {
      return error || "";
    }
    return "";
  };

  const warningMessage = !domainOptions.length
    ? "Please choose the email column to setup the domains"
    : "";

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema(domains)}
      >
        {({ values, touched, errors }) => {
          const emailColumnSelected = values.fileValues.emailIndex >= 0;

          return (
            <>
              <LocalFormField
                type="file-table"
                name="fileValues"
                label="Select Email column"
                parsedFile={parsedFile}
                onFieldChange={onEmailSelect}
              />

              <div className={styles.row}>
                <FormField type="number" name="daysNumber" label="Days" />
                <FormField
                  type="select"
                  name="type"
                  label="Type"
                  options={typeOptions}
                  disabled
                  onFieldChange={() => resetEmailsSpread(values.domainsEmailsSpread)}
                />
              </div>
              <div className={styles.row}>
                <FormField
                  type="number"
                  name="domainNumber"
                  label="Domain number"
                  min={1}
                  onFieldChange={(value: unknown) =>
                    adjustDomainsList(value as number, values.domainsEmailsSpread)
                  }
                />
                <FormField
                  type="number"
                  name="initialEmailsNumber"
                  label="Total initial emails"
                  disabled
                  min={1}
                  max={totalFromSelectedDomains(values.domainsEmailsSpread)}
                  onFieldChange={() => resetEmailsSpread(values.domainsEmailsSpread)}
                />
              </div>
              {!submitting && warningMessage && <MessageField message={warningMessage} />}
              {submitting && (
                <div className={styles.loader}>
                  <Loader size={15} type="puff-loader" />
                  <span>Loading domain list</span>
                </div>
              )}
              {getDomainsFields(Number(values.domainNumber)).map((option, index) => {
                const selectedDomainKey = values.domainsEmailsSpread[index]?.domain;
                const currentDomain = domains.find((domain) => domain.key === selectedDomainKey);
                const domainEmailsError = Array.isArray(errors.domainsEmailsSpread)
                  ? errors.domainsEmailsSpread[index]
                  : null;

                return (
                  <div className={styles.row} key={index}>
                    <FormField
                      type="select"
                      label={`Domain ${index + 1}`}
                      name={`domainsEmailsSpread[${index}].domain`}
                      placeholder="Choose from the list"
                      options={getDomainOptions(values.domainsEmailsSpread[index]?.domain)}
                      asyncOptions={{
                        loading: submitting,
                      }}
                      disabled={!domainOptions.length}
                      isSearchable
                      error={countError?.message}
                      onChange={() => {
                        handleChangeDomain(index);
                      }}
                    />
                    <FormField
                      type="text"
                      inputMode="numeric"
                      name={`domainsEmailsSpread[${index}].numberOfEmails`}
                      placeholder="Fill the number"
                      label="Initial emails"
                      error={typeof domainEmailsError === "string" ? domainEmailsError : undefined}
                      min={0}
                      max={currentDomain?.amount}
                      disabled={
                        !domainOptions.length || !values.domainsEmailsSpread?.[index]?.domain
                      }
                      onChange={(value) => handleChangeInitNumbers(value, index)}
                    />
                  </div>
                );
              })}
              <MessageField
                message={
                  (touched.domainsEmailsSpread && getError(errors.domainsEmailsSpread)) || ""
                }
              />

              <TotalEmails total={total} domains={domains} />

              <div className={baseStyles.actions}>
                <UiKitFormField
                  type="checkbox"
                  name="fileValues.suppress"
                  label={suppressionCheckboxLabel}
                  disabled={!emailColumnSelected}
                  disableError
                />
                <FormButtons>
                  <Button appearance="secondary" onClick={setClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Set values</Button>
                </FormButtons>
              </div>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
