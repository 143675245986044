import React, { useMemo, useState } from "react";
import { Loader, useFetch } from "@epcnetwork/core-ui-kit";

import { usePayload, useShowContent } from "hooks";
import { getSummaryMetrics } from "api";
import { Range } from "../stats.types";
import { mapPipelineResponse } from "../stats.constants";
import { RangeSwitcher } from "../range-switcher/range-switcher";
import { PieChart } from "./pie-chart/pie-chart";
import { SummaryList } from "./list/summary-list";

import styles from "./summary.module.scss";

export const Summary: React.FC = () => {
  const [range, setRange] = useState<Range>(7);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const apiResponse = useFetch(getSummaryMetrics.setQueryParams(`?days=${range}`), {
    dependencies: [range],
  });

  const { showLoader } = useShowContent(apiResponse);
  const { payload: data } = usePayload(apiResponse);

  const { pipelineData, isEmptyChart } = useMemo(() => {
    const pipelineData = mapPipelineResponse(data)?.sort((a, b) => b.value - a.value);
    const isEmptyChart = pipelineData?.every((item) => item.value === 0);

    return { pipelineData, isEmptyChart };
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Summary</h3>

        <RangeSwitcher currentRange={range} setRange={setRange} />
      </div>

      <section className={styles.chartContainer}>
        {showLoader && (
          <div className={styles.chartContentWrapper}>
            <Loader />
          </div>
        )}
        {isEmptyChart && !showLoader && (
          <div className={styles.chartContentWrapper}>
            <span>No data to display</span>
          </div>
        )}
        {!isEmptyChart && !showLoader && (
          <PieChart
            data={pipelineData || []}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </section>

      <SummaryList
        data={pipelineData || []}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        loading={showLoader}
      />
    </div>
  );
};
