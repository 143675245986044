import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FC, useState } from "react";
import { useCall, useDidUpdate, Loader } from "@epcnetwork/core-ui-kit";

import { isActiveRoute } from "utils";
import { RootState, setUser } from "store";
import { DEFAULT_PAGE, LOGIN_PAGE } from "constants/routes.constants";
import { PageRoute, routes } from "config/routes.config";
import { getMyProfile } from "api";

const App: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { token, user } = useSelector((state: RootState) => state.auth);

  const [isLoading, setIsLoading] = useState(true);

  const { submit, onCallSuccess, onCallError } = useCall(getMyProfile);
  onCallSuccess((payload) => {
    dispatch(setUser(payload));
    setIsLoading(false);
  });
  onCallError(() => {
    setIsLoading(false);
  });

  useDidUpdate(
    () => {
      if (token && !user) {
        setIsLoading(true);
        submit();
      } else {
        const isLoginPage = isActiveRoute(LOGIN_PAGE.path, location);
        if (isLoginPage) navigate(DEFAULT_PAGE.path);

        setIsLoading(false);
      }
    },
    [token, user],
    true,
  );

  if (isLoading) {
    return <Loader type="puff-loader" position="absolute" />;
  }

  return (
    <Routes>
      {routes.map(({ element, ...route }) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={<PageRoute element={element} {...route} />}
          />
        );
      })}
    </Routes>
  );
};

export { App };
