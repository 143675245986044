import React from "react";
import cn from "classnames";

import { SummaryMetricsResponse } from "api/pipelines/pipelines.interface";
import { PipelineDetails } from "../../stats.types";

import styles from "./summary-list.module.scss";

interface Props {
  data: (SummaryMetricsResponse & PipelineDetails)[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  loading: boolean;
}

export const SummaryList: React.FC<Props> = ({ data, activeIndex, setActiveIndex, loading }) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className={styles.wrapper}>
      <div className={cn({ [styles.list]: !loading, [styles.loadingList]: loading })}>
        {data.map((item, index) => {
          const percentage = ((item.value / total) * 100).toFixed(2);
          const percentageToDisplay = total !== 0 ? percentage : "0";

          return (
            <div
              key={item.name}
              className={cn(styles.listItem, { [styles.itemActive]: activeIndex === index })}
              onMouseEnter={() => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(-1)}
            >
              <div className={styles.row}>
                <div className={styles.colorBox} style={{ backgroundColor: item.fill }} />
                <div>
                  <span>
                    {item?.fullName || item.label} - {item.value}{" "}
                  </span>
                </div>
              </div>
              <span className={styles.details}>
                ({item.value}/{total} - {percentageToDisplay}%)
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
