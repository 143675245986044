import { FC, useState } from "react";
import cn from "classnames";
import {
  useFilters,
  Modal,
  Button,
  useFetch,
  Table,
  Container,
  usePagination,
  List,
  useDevice,
} from "@epcnetwork/core-ui-kit";

import { FiltersType } from "types";
import { useAuthorization, usePayload } from "hooks";
import { USERS_PERMISSION } from "constants/roles.constants";
import { searchMinLength } from "constants/form.constants";
import { Toolbar } from "components";
import { getUsers, UserDetails } from "api";
import { CreateUser, UpdateUser, UpdateRoles } from "../forms";
import { TABLE_COLUMNS, initialQueryParams } from "./users-list.constants";
import { UserRow } from "./user-table-row/user-table-row";

import addImg from "assets/images/add-with-background.svg";
import styles from "./users-list.module.css";

const UsersListPage: FC = () => {
  const ability = useAuthorization();

  const { isMobileDevice } = useDevice();
  const { queryString, searchValue, setSearch } = useFilters<FiltersType>({
    initialState: initialQueryParams,
    searchOptions: { searchKey: "value", searchMinLength },
  });

  const [userToEdit, setUserToEdit] = useState<number | null>(null);
  const [rolesToEdit, setRolesToEdit] = useState<number | null>(null);
  const [createUserOpen, setCreateUserOpen] = useState<boolean>(false);

  const apiResponse = useFetch(getUsers.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, loading, list, refresh, error } = usePayload(apiResponse);

  const pagination = usePagination({ listPayload: payload });

  const toggleAddUser = (isOpen: boolean) => () => {
    setCreateUserOpen(isOpen);
  };

  const toggleEditUser = (userId: number | null) => () => {
    setUserToEdit(userId);
  };

  const toggleEditRoles = (userId: number | null) => () => {
    setRolesToEdit(userId);
  };

  const handleItemChange = (values: UserDetails) => {
    const elementExists = list.some((el) => el.id === values.id);
    const filteredList = list.filter((listElem) => listElem.id !== values.id);

    const newData: List<UserDetails> = {
      ...payload,
      data: [values, ...filteredList],
      total: elementExists ? Number(payload.total) : Number(payload.total) + 1,
    };

    apiResponse.actions.setData(newData);
    setUserToEdit(null);
    setRolesToEdit(null);
  };

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch}>
        {ability.can("create", USERS_PERMISSION.name) && (
          <Button className={styles.addBtn} onClick={toggleAddUser(true)}>
            <img src={addImg} alt="" width={16} height={16} />
            <span className={styles.addBtnText}> Add User</span>
          </Button>
        )}
      </Toolbar>
      <div className={styles.wrapper}>
        <Table
          columns={TABLE_COLUMNS}
          list={list}
          error={error?.message}
          loading={loading}
          refresh={refresh}
          pagination={{ ...pagination, className: cn({ [styles.pagination]: isMobileDevice }) }}
          isTabTable
          row={(item) => (
            <UserRow
              key={item.id}
              item={item}
              setUserToEdit={toggleEditUser}
              setRolesToEdit={toggleEditRoles}
            />
          )}
        />
        <Modal isOpen={createUserOpen} setClose={toggleAddUser(false)}>
          <CreateUser handleItemChange={handleItemChange} handleCloseModal={toggleAddUser(false)} />
        </Modal>

        <Modal isOpen={Boolean(userToEdit)} setClose={toggleEditUser(null)}>
          {userToEdit && (
            <UpdateUser
              id={userToEdit}
              handleCloseModal={toggleEditUser(null)}
              handleItemChange={handleItemChange}
            />
          )}
        </Modal>

        <Modal isOpen={Boolean(rolesToEdit)} setClose={toggleEditRoles(null)}>
          {rolesToEdit && (
            <UpdateRoles
              id={rolesToEdit}
              handleCloseModal={toggleEditRoles(null)}
              handleItemChange={handleItemChange}
            />
          )}
        </Modal>
      </div>
    </Container>
  );
};

export { UsersListPage };
