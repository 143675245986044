import { FC } from "react";

export interface RouteConfig<ComponentType extends FC = FC, Path extends string = string> {
  path: Path;
  component: ComponentType;
  name: string;
  exact: boolean;
  auth: boolean;
  showNavigation: boolean;
  pageNameTooltipText?: string;
}

export type PageType<C extends FC = FC, P extends string = string> = Omit<
  RouteConfig<C, P>,
  "component"
>;

export const LOGIN_PAGE: PageType = {
  path: "/login",
  name: "Login",
  exact: false,
  auth: false,
  showNavigation: false,
};
export const LOGOUT_PAGE: PageType = {
  path: "/logout",
  name: "Logout",
  exact: false,
  auth: false,
  showNavigation: false,
};
export const FORGOT_PASSWORD_PAGE: PageType = {
  path: "/forgot-password",
  name: "Forgot Password",
  exact: true,
  auth: false,
  showNavigation: false,
};
export const RESET_PASSWORD_PAGE: PageType = {
  path: "/reset-password",
  name: "Reset Password",
  exact: true,
  auth: false,
  showNavigation: false,
};
export const DASHBOARD_PAGE: PageType = {
  path: "/dashboard",
  name: "Dashboard",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const DATABASE_PAGE: PageType = {
  path: "/database",
  name: "Database",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const JOBS_LIST_PAGE: PageType = {
  path: "/jobs",
  name: "Jobs",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const JOBS_DETAILS_PAGE: PageType = {
  path: "/jobs/:jobId",
  name: "Jobs",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const SUPPRESSION_LIST_PAGE: PageType = {
  path: "/suppression",
  name: "Suppression Values",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const SUPPRESSION_IMPORT_PAGE: PageType = {
  path: "/suppression/import",
  name: "Suppression Import",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const SUPPRESSION_SETTINGS_LIST_PAGE: PageType = {
  path: "/list-settings",
  name: "Lists",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const PIPELINES_LIST_PAGE: PageType = {
  path: "/pipelines",
  name: "Pipelines",
  exact: true,
  auth: true,
  showNavigation: true,
};
export const STATS_PAGE: PageType = {
  path: "/stats",
  name: "Stats",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const USERS_LIST_PAGE: PageType = {
  path: "/users",
  name: "Users",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const ACTIVITY_LIST_PAGE: PageType = {
  path: "/activities",
  name: "Activities log",
  exact: false,
  auth: true,
  showNavigation: true,
};
export const MY_ACCOUNT_PAGE: PageType = {
  path: "/account/me",
  name: "My account",
  exact: false,
  auth: true,
  showNavigation: true,
};

export const DEFAULT_PAGE: PageType = { ...DASHBOARD_PAGE, path: "/", exact: true };
export const NOT_FOUND_PAGE: PageType = {
  path: "*",
  name: "Not Found",
  exact: false,
  auth: true,
  showNavigation: true,
};
