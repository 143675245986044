import { object, string, SchemaOf } from "yup";
import { SelectOption } from "@epcnetwork/core-ui-kit";

import { selectMultipleValidation } from "utils";
import { SuppressionTypeOption } from "models";
import { requiredFieldText, searchMinLengthFieldText } from "constants/form.constants";
import {
  CreateSuppressionRequiredValues,
  CreateSuppressionValues,
  UpdateSuppressionRequiredValues,
  UpdateSuppressionValues,
} from "./suppression-form.types";

export const suppressionTypes: SuppressionTypeOption[] = [
  { value: "Email", label: "Email" },
  { value: "Domain", label: "Domain" },
  { value: "Keyword", label: "Keyword" },
  { value: "Regex", label: "Regex" },
];

export const suppressionFilterTypes: SuppressionTypeOption[] = [
  ...suppressionTypes,
  { value: "Regex", label: "Regex" },
];

export const suppressionKindOptions: SelectOption[] = [
  { value: "false", label: "Local" },
  { value: "true", label: "Global" },
];

export const createSuppressionInitialValues: CreateSuppressionValues = {
  values: "",
  categories: [],
  esps: [],
  listIds: [],
  type: "",
};

export const updateSuppressionInitialValues: UpdateSuppressionValues = {
  categories: [],
  esps: [],
  listIds: [],
};

export const createValidationSchema: SchemaOf<CreateSuppressionRequiredValues> = object().shape({
  values: string().min(3, searchMinLengthFieldText).required(requiredFieldText),
  type: string().required(requiredFieldText),
  listIds: selectMultipleValidation(),
});

export const updateValidationSchema: SchemaOf<UpdateSuppressionRequiredValues> = object().shape({
  listIds: selectMultipleValidation(),
});
