import { useNavigate } from "react-router-dom";
import { FC, memo, useRef, useState, useCallback } from "react";
import {
  Button,
  Container,
  FileItem,
  FileUpload,
  DropAcceptedFunc,
  useWillUnmount,
  useDidUpdate,
  notification,
  Nullable,
  useFileQueue,
  Checkbox,
} from "@epcnetwork/core-ui-kit";

import { getPipelineRoute, getAcceptedFilesText, getSupportedFormats, onFileRedirect } from "utils";
import { useTracking } from "hooks";
import { SMARTLOOK_EVENTS } from "constants/smartlook.constants";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { postDiffAndCommonFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { DiffCommonFormModal } from "./form-modal/diff-common-form-modal";
import {
  DiffCommonFileInfo,
  DiffCommonFileItem,
  PipelineDiffAndCommonData,
} from "./diff-common.types";
import { supportedFormats, maxFiles, getInitialData } from "./diff-common.constants";

import exampleImg from "assets/images/diff-and-common-example.svg";
import styles from "./diff-common.module.css";

const DiffCommonPage: FC = memo(() => {
  const componentIsMounted = useRef(true);
  const navigate = useNavigate();

  const { pipelineSubmitEvent } = useTracking();

  const pipeline = getPipelineRoute("Diff and Common");

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitAtOnce,
    hasAllSuccessFiles,
  } = useFileQueue<DiffCommonFileItem, PipelineDiffAndCommonData>(
    pipeline.title,
    postDiffAndCommonFile,
  );

  useWillUnmount(() => {
    componentIsMounted.current = false;
  });

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [suppress, setSuppress] = useState(true);
  const [editedFile, setEditedFile] = useState<Nullable<DiffCommonFileItem>>(null);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: DiffCommonFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSuppressChange = useCallback((value: string, isChecked: boolean) => {
    setSuppress(isChecked);
  }, []);

  const handleSubmit = () => {
    const formData = new FormData();

    files.forEach((file, index) => {
      const { fileName, emailIndex, hasHeaders } = file.data;
      const fileInfo: DiffCommonFileInfo = { emailIndex, hasHeaders, suppress };
      formData.append(`file${index + 1}Info`, JSON.stringify(fileInfo));
      formData.append(`file${index + 1}`, file.originalFile, fileName);
    });

    submitAtOnce(
      files.map(({ id }) => id),
      formData,
    );
    pipelineSubmitEvent(SMARTLOOK_EVENTS.diffCommonSubmit, files);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, navigate });
  };

  const handleRedirectToPipelines = () => {
    navigate(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <div className={styles.example}>
            <img src={exampleImg} alt="" width="444" height="91" />
          </div>
          <FileUpload
            className={styles.dropZone}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            maxFiles={maxFiles}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleConfigureItem}
                onEditValuesClick={handleConfigureItem}
              >
                {data.emailIndex >= 0 && (
                  <div className={styles.additionalInfo}>
                    <span>Selected email column:</span> {data.emailIndex + 1}
                  </div>
                )}
              </FileItem>
            ))}
          </div>
          {editedFile && (
            <DiffCommonFormModal
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.actions}>
            <Checkbox
              checked={suppress}
              value="suppress"
              onChange={handleSuppressChange}
              label={suppressionCheckboxLabel}
              disabled={false}
              className={styles.checkbox}
              disableError
            />
            <div className={styles.buttons}>
              <Button
                appearance="secondary"
                onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
                disabled={isEntityFinished && !hasAllSuccessFiles}
              >
                Cancel
              </Button>
              {!isEntityFinished && (
                <Button
                  onClick={handleSubmit}
                  disabled={!hasAllConfigured || files.length < 2}
                  loading={!isEntityInConfiguration}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
});

export { DiffCommonPage };
