import { ref, object, string, AnyObjectSchema } from "yup";

import { optionalValidation, passwordValidation } from "utils";
import { Values } from "./reset-password.types";

export const initialValues: Values = {
  password: "",
  confirmPassword: "",
};

export const validationSchema = (id: string | null): AnyObjectSchema =>
  object().shape({
    password: string()
      .min(8, "Password needs to include at least 8 characters")
      .max(30, "Password is too long")
      .test("password validation", "", passwordValidation)
      .when("$password", optionalValidation(Boolean(id), "Password is required")),
    confirmPassword: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .when(
        "$passwordConfirm",
        optionalValidation(Boolean(id), "Password confirmation is required"),
      ),
  });
