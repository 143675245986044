import { FC, ReactNode } from "react";
import { Search, SearchProps, useFilters } from "@epcnetwork/core-ui-kit";

import styles from "./toolbar.module.css";

type ToolbarProps = Omit<SearchProps, "setSearch"> & {
  children: ReactNode;
  filters?: ReactNode;
  setSearch: ReturnType<typeof useFilters>["setSearch"];
};

export const Toolbar: FC<ToolbarProps> = ({ filters, children, setSearch, ...rest }) => {
  const handleSearch: SearchProps["setSearch"] = (value) => {
    setSearch(value);
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.searchContent}>
        <Search width="small" setSearch={handleSearch} {...rest} className={styles.search} />
        {filters}
      </div>
      <div className={styles.spacer} />
      <div>{children}</div>
    </div>
  );
};
