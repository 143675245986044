import { FC } from "react";
import { useWindowSize } from "@epcnetwork/core-ui-kit";

import { AuthorizationActionType, AuthorizationEntityNameType } from "types";
import { useAuthorization } from "hooks";
import {
  JOBS_LIST_PAGE,
  USERS_LIST_PAGE,
  PIPELINES_LIST_PAGE,
  SUPPRESSION_LIST_PAGE,
  SUPPRESSION_SETTINGS_LIST_PAGE,
  STATS_PAGE,
} from "constants/routes.constants";
import {
  JOBS_PERMISSION,
  PIPELINES_LIST_PERMISSION,
  STATS_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  SUPPRESSION_PERMISSION,
  USERS_PERMISSION,
} from "constants/roles.constants";
import { MenuLink } from "./menu-link";
import { Link } from "./link";

import monitoringIcon from "assets/images/monitoring.svg";
import usersIcon from "assets/images/menu-users.svg";
import suppressionIcon from "assets/images/menu-suppression.svg";
import pipelinesIcon from "assets/images/menu-pipeline.svg";
import listSettingsIcon from "assets/images/menu-list-settings.svg";
import jobsIcon from "assets/images/menu-jobs.svg";
import styles from "../sidebar.module.css";

export type LinksType = {
  src: string;
  text: string;
  to: string;
  authAction?: AuthorizationActionType;
  authEntity?: AuthorizationEntityNameType;
  initialQuery?: string;
  exact?: boolean;
  isMobile: boolean;
  links?: LinksType[];
  initiallyOpened?: boolean;
};

const Menu: FC = () => {
  const ability = useAuthorization();
  const { width } = useWindowSize();

  const isVisibleForMobile = (link: LinksType) => {
    if (link.isMobile) return true;
    return width > 992;
  };

  const permittedLinks = links.filter((link) => {
    const { authEntity, authAction } = link;
    if (authEntity) {
      return ability.can(authAction || "read", authEntity);
    }
    return true;
  });

  const availableLinks = permittedLinks.filter((link) => isVisibleForMobile(link));

  const menuComponent = (link: LinksType) => {
    const { links, text } = link;
    if (links) {
      return <MenuLink triggerLink={link} links={links} key={text} />;
    }
    return <Link {...link} key={text} />;
  };

  return <div className={styles.menu}>{availableLinks.map((link) => menuComponent(link))}</div>;
};

export { Menu };

const links: LinksType[] = [
  {
    src: jobsIcon,
    text: JOBS_LIST_PAGE.name,
    to: JOBS_LIST_PAGE.path,
    exact: true,
    authAction: "read",
    authEntity: JOBS_PERMISSION.name,
    isMobile: true,
  },
  {
    src: pipelinesIcon,
    text: PIPELINES_LIST_PAGE.name,
    to: PIPELINES_LIST_PAGE.path,
    isMobile: true,
    authAction: "read",
    authEntity: PIPELINES_LIST_PERMISSION.name,
  },
  {
    src: suppressionIcon,
    text: SUPPRESSION_LIST_PAGE.name,
    to: SUPPRESSION_LIST_PAGE.path,
    initialQuery: "order%5BcreatedAt%5D=desc",
    authAction: "read",
    authEntity: SUPPRESSION_PERMISSION.name,
    isMobile: true,
  },
  {
    src: listSettingsIcon,
    text: SUPPRESSION_SETTINGS_LIST_PAGE.name,
    to: SUPPRESSION_SETTINGS_LIST_PAGE.path,
    authAction: "read",
    authEntity: SUPPRESSION_LIST_PERMISSION.name,
    isMobile: true,
  },
  {
    src: monitoringIcon,
    text: STATS_PAGE.name,
    to: STATS_PAGE.path,
    authAction: "read",
    authEntity: STATS_PERMISSION.name,
    isMobile: true,
  },
  {
    src: usersIcon,
    text: USERS_LIST_PAGE.name,
    to: USERS_LIST_PAGE.path,
    authAction: "read",
    authEntity: USERS_PERMISSION.name,
    isMobile: false,
  },
];
