import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { FC, useMemo } from "react";
import cn from "classnames";

import "react-circular-progressbar/dist/styles.css";
import pauseImg from "assets/images/progress-circle-pause.svg";
import failedImg from "assets/images/progress-circle-failed.svg";
import doneImg from "assets/images/progress-circle-done.svg";
import styles from "./circular-progress.module.css";

type CircularProgressProps = {
  value: number;
  isPaused?: boolean;
  isFailed?: boolean;
  isDone?: boolean;
};

export const CircularProgress: FC<CircularProgressProps> = ({
  value,
  isPaused,
  isFailed,
  isDone,
}) => {
  const percentage = Math.min(100, Math.round(value));

  const showFailed = Boolean(isFailed);
  const showDone = Boolean(!showFailed && isDone);
  const showPaused = Boolean(!showFailed && !showDone && isPaused);
  const showProgress = Boolean(!showFailed && !showDone && !showPaused);

  const circleStyles = useMemo(() => getStyles(showProgress), [showProgress]);

  return (
    <div className={cn(styles.container, { [`${styles.inProgress}`]: showProgress })}>
      <CircularProgressbarWithChildren
        value={value / 100}
        maxValue={1}
        styles={circleStyles}
        strokeWidth={6}
      >
        {showProgress && (
          <div className={styles.text}>
            {percentage}
            <span>%</span>
          </div>
        )}
        {showFailed && <img src={failedImg} alt="" width={20} height={20} />}
        {showPaused && <img src={pauseImg} alt="" width={20} height={20} />}
        {showDone && <img src={doneImg} alt="" width={20} height={20} />}
      </CircularProgressbarWithChildren>
    </div>
  );
};

const getStyles = (showProgress: boolean) =>
  buildStyles({
    textSize: "10px",
    trailColor: showProgress ? "rgba(26, 86, 197,0.15)" : "transparent",
    strokeLinecap: "transparent",
    pathColor: showProgress ? "#1a56c5" : "transparent",
    pathTransitionDuration: 0.5,
  });
