import { FC } from "react";
import { Filters, Select, DateInput, useAutocomplete } from "@epcnetwork/core-ui-kit";

import { convertToNumber } from "utils";
import { getUsers, getGroups, getLists } from "api";
import { FiltersType } from "../suppression-list.types";
import {
  nonClearableKeys,
  suppressionTypeOptions,
  suppressionCategoryOptions,
  suppressionKindOptions,
  suppressionEspOptions,
  suppressionStatusOptions,
} from "../suppression-list.constants";

import styles from "../suppression-list.module.css";

const initialFilters: FiltersType = {
  userIds: "",
  types: "",
  groupIds: "",
  listIds: "",
  isGlobal: "",
  categories: "",
  esps: "",
  statuses: "",
  createdAtFrom: "",
  createdAtTo: "",
};

export const SuppressionListFilters: FC = () => {
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");
  const usersAutocomplete = useAutocomplete(getUsers, "id", "email");
  const listAutocomplete = useAutocomplete(getLists, "id", "name");
  const currentDate = new Date();

  const getListTypeOptions = (isGlobal: string) => {
    const kind = isGlobal && isGlobal === "true";
    const options = isGlobal
      ? listAutocomplete.fetchOptions.filter((el) => Boolean(el.isGlobal) === kind)
      : listAutocomplete.fetchOptions;

    return options.map((list) => {
      return {
        ...list,
        locked: list.locked && false,
      };
    });
  };

  return (
    <Filters
      initialFilters={initialFilters}
      searchKey="value"
      nonClearableKeys={nonClearableKeys as Array<keyof FiltersType>}
    >
      {({ setSelect, getSelect, getDatePickerDate, setDatePickerDate, state }) => {
        const [startFrom, startTo] = getDatePickerDate(["createdAtFrom", "createdAtTo"]);
        const userIds = convertToNumber(getSelect("userIds", true));
        const listIds = convertToNumber(getSelect("listIds", true));
        const groupIds = convertToNumber(getSelect("groupIds", true));

        return (
          <div>
            <Filters.ContentItem>
              <Select
                label="Users"
                onChange={setSelect("userIds", true)}
                selectedOptionsKeys={userIds}
                asyncOptions={usersAutocomplete}
                isSearchable
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Types"
                onChange={setSelect("types", true)}
                selectedOptionsKeys={getSelect("types", true)}
                options={suppressionTypeOptions}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="List kind"
                selectedOptionsKeys={getSelect("isGlobal")}
                onChange={setSelect("isGlobal")}
                options={suppressionKindOptions}
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Lists"
                onChange={setSelect("listIds", true)}
                selectedOptionsKeys={listIds}
                options={getListTypeOptions(state.isGlobal)}
                isSearchable
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Categories"
                onChange={setSelect("categories", true)}
                selectedOptionsKeys={getSelect("categories", true)}
                options={suppressionCategoryOptions}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="User groups"
                onChange={setSelect("groupIds", true)}
                selectedOptionsKeys={groupIds}
                asyncOptions={groupsAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Email Service Providers"
                onChange={setSelect("esps", true)}
                selectedOptionsKeys={getSelect("esps", true)}
                options={suppressionEspOptions}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Statuses"
                onChange={setSelect("statuses", true)}
                selectedOptionsKeys={getSelect("statuses", true)}
                options={suppressionStatusOptions}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <DateInput
                className={styles.inputDate}
                dateFrom={startFrom}
                dateTo={startTo}
                max={currentDate}
                onChange={setDatePickerDate(["createdAtFrom", "createdAtTo"], true)}
                isMulti
                disableError
              />
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};
