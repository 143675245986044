import { boolean, object, SchemaOf } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { emailColumnValidation, headersValidation, selectMultipleValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { SuppressFileData, SuppressValues } from "./match-and-eliminate.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const validationSchema: SchemaOf<SuppressValues> = object({
  fileValues: object({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    databases: selectMultipleValidation(),
    suppress: boolean(),
  }).required(requiredFieldText),
});

export const getInitialData = (item: File): SuppressFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  emailIndex: -1,
  databases: [],
  suppress: true,
});
