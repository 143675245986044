import { FC, useState } from "react";
import { MessageField, Button, useCall, notification } from "@epcnetwork/core-ui-kit";

import { SuppressionModel } from "models";
import { deleteSuppression } from "api";

import styles from "./delete-suppression-modal.module.css";

type DeleteModalProps = {
  email: string;
  itemToDelete: SuppressionModel;
  refresh: VoidFunction;
  closeModal: VoidFunction;
};

export const DeleteSuppressionModal: FC<DeleteModalProps> = ({
  itemToDelete,
  email,
  closeModal,
  refresh,
}) => {
  const [error, setError] = useState("");

  const { submit, onCallError, onCallSuccess } = useCall(deleteSuppression);
  onCallSuccess(() => {
    refresh();
    closeModal();
    setError("");
    notification.success("Suppression deleted", "Successfully deleted suppression");
  });
  onCallError((msg) => {
    setError(msg.message);
  });

  const handleDeleteItems = async () => {
    await submit({ params: { suppressionId: itemToDelete.id } });
  };

  const isOnMultipleLists = itemToDelete.lists.length > 1;
  const lists = itemToDelete.lists.map((list) => (
    <span key={list.id} className={styles.value}>
      {list.name}
    </span>
  ));

  return (
    <div>
      {error && <MessageField message={error} className={styles.errorMsg} />}
      <div className={styles.title}>Are you sure you want to delete the email?</div>
      {isOnMultipleLists && (
        <div className={styles.subtitle}>
          Email <span className={styles.value}>{email}</span> is on a multiple suppression lists:{" "}
          <br />
          {lists}
        </div>
      )}

      {!isOnMultipleLists && (
        <div className={styles.subtitle}>
          Email <span className={styles.value}>{email}</span> is on a single suppression list:{" "}
          <br />
          {lists}
        </div>
      )}

      <div className={styles.buttons}>
        <Button appearance="secondary" onClick={closeModal} className={styles.button}>
          Back
        </Button>
        <Button className={styles.button} onClick={handleDeleteItems}>
          Yes, delete
        </Button>
      </div>
    </div>
  );
};
