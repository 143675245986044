import { useNavigate } from "react-router-dom";
import { FC, useCallback, useRef, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  DropAcceptedFunc,
  notification,
  Nullable,
  useWillUnmount,
  useDidUpdate,
  useFileQueue,
  FileUpload,
  FileItem,
} from "@epcnetwork/core-ui-kit";

import { getPipelineRoute } from "utils/pipelines.utils";
import { getAcceptedFilesText, getSupportedFormats, onFileRedirect } from "utils";
import { useTracking } from "hooks";
import { SMARTLOOK_EVENTS } from "constants/smartlook.constants";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { postCutFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { CutFormModal } from "./form-modal/cut-form-modal";
import { CutFileInfo, CutFileItem, PipelineCutData } from "./cut.types";
import { getInitialData, supportedFormats } from "./cut.constants";

import styles from "./cut.module.css";

const CutPage: FC = () => {
  const componentIsMounted = useRef(true);
  const navigate = useNavigate();

  const { pipelineSubmitEvent } = useTracking();

  const pipeline = getPipelineRoute("Cut");

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<CutFileItem, PipelineCutData>(pipeline.title, postCutFile);

  useWillUnmount(() => {
    componentIsMounted.current = false;
  });

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [editedFile, setEditedFile] = useState<Nullable<CutFileItem>>(null);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: CutFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();
      const { fileName, columns } = file.data;
      const data: CutFileInfo = { columns };

      formData.append("fileInfo", JSON.stringify(data));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
    pipelineSubmitEvent(SMARTLOOK_EVENTS.cutSubmit, files);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, navigate });
  };

  const handleRedirectToPipelines = () => {
    navigate(PIPELINES_LIST_PAGE.path);
  };

  const renderSelectedColumns = (hasHeaders: boolean, firstRow: string[], headers: string[]) => {
    if (hasHeaders) {
      const selectedHeaders = firstRow
        .map((column, index) => {
          if (headers[index]) return column;
          return "";
        })
        .filter(Boolean);

      return selectedHeaders.join(", ");
    }
    return "";
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => {
              const { hasHeaders, firstRow, headers } = data;
              const selectedColumns = renderSelectedColumns(hasHeaders, firstRow, headers);

              return (
                <FileItem
                  {...rest}
                  key={id}
                  id={id}
                  file={originalFile}
                  onCrossClick={removeFiles}
                  onSetValuesClick={handleConfigureItem}
                  onEditValuesClick={handleConfigureItem}
                >
                  {selectedColumns && (
                    <div className={styles.additionalInfo}>
                      <span>Selected columns:</span> {selectedColumns}
                    </div>
                  )}
                </FileItem>
              );
            })}
          </div>
          {editedFile && (
            <CutFormModal
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.actions}>
            <div className={styles.buttons}>
              <Button
                appearance="secondary"
                onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
                disabled={isEntityFinished && !hasAllSuccessFiles}
              >
                Cancel
              </Button>
              {!isEntityFinished && (
                <Button
                  onClick={handleSubmit}
                  disabled={!hasAllConfigured || !files.length}
                  loading={!isEntityInConfiguration}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export { CutPage };
