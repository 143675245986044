import { FC } from "react";

import { LinksType } from "./menu";
import { Link } from "./link";

import styles from "../sidebar.module.css";

type MenuLinkTypes = {
  triggerLink: LinksType;
  links: LinksType[];
};

const MenuLink: FC<MenuLinkTypes> = ({ triggerLink, links }) => {
  return (
    <div>
      <Link {...triggerLink} className={styles.menuTrigger} />
      <div className={styles.menuLinks}>
        {links.map((link) => (
          <Link {...link} key={link.text} className={styles.nestedLink} />
        ))}
      </div>
    </div>
  );
};

export { MenuLink };
