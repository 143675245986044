import { FC, useState } from "react";
import cn from "classnames";
import {
  Container,
  useFetch,
  Table,
  Tabs,
  useDidUpdate,
  usePagination,
  useDevice,
  useFilters,
  useQuery,
} from "@epcnetwork/core-ui-kit";

import { findKeyByValue } from "utils";
import { JobModel } from "models";
import { usePayload, useSocket } from "hooks";
import { searchMinLength } from "constants/form.constants";
import { Toolbar } from "components";
import { getJobs } from "api";
import { JobRow } from "./table-row/job-row";
import { JobFilters } from "./list-filters/job-filters";
import { JobListQueryType, FiltersQueryType } from "./jobs-list.types";
import { tableColumns, queryKeys, tabStatesMap, initialFilters } from "./jobs-list.constants";

import styles from "./jobs-list.module.css";

const JobListPage: FC = () => {
  const nonClearableKeys = ["status"].concat(queryKeys);

  const { isMobileDevice } = useDevice();
  const { queryString, query, searchValue, setSearch, updateQueryParams } = useFilters<
    FiltersQueryType | JobListQueryType
  >({
    searchOptions: { searchKey: "value", searchMinLength },
    nonClearableKeys,
    initialState: initialFilters.initialState,
  });
  const { onSocket } = useSocket();

  const apiResponse = useFetch(getJobs.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, list, loading, refresh, error } = usePayload(apiResponse);
  const pagination = usePagination({ listPayload: apiResponse.payload });

  const [activeTab, setActiveTab] = useState(
    Number(findKeyByValue(tabStatesMap, query.status)) || 0,
  );

  const [jobsList, setJobsList] = useState<typeof list>(list);

  useDidUpdate(() => {
    setJobsList(list);
  }, [JSON.stringify(list)]);

  onSocket((socket) => {
    let previousFile = "";
    let previousProgress = 0;

    socket.on("progress", (data) => {
      // Backend socket is sending 100 responses per second with progress, this statement prevent app to die :)
      if (data.id === previousFile && data.progress === previousProgress) return;
      previousFile = data.id;
      previousProgress = data.progress;
      handleUpdateItem(data.id, { progress: data.progress, status: "in progress" });
    });

    socket.on("done", (data) => {
      refresh();
    });

    socket.on("failed", (data) => {
      refresh();
    });
  });

  const handleUpdateItem = (id: string, data: Partial<JobModel>) => {
    setJobsList((prev) => {
      const jobsObj = [...prev];
      prev.forEach((item, index) => {
        if (id === item.id) {
          jobsObj[index] = {
            ...item,
            ...data,
          };
        }
      });
      return jobsObj;
    });
  };

  const onTabClick = (index: number) => {
    updateQueryParams({
      status: tabStatesMap[index],
      offset: 0,
    });
    setActiveTab(index);
  };

  const tableProps = {
    columns: tableColumns,
    error: error?.message,
    loading,
    refresh,
  };

  const tabs = [
    {
      tab: { name: `All Files (${payload?.all || 0})` },
      list: jobsList,
    },
    {
      tab: { name: `Done (${payload?.done || 0})` },
      list: jobsList,
    },
    {
      tab: { name: `In process (${payload?.inProgress || 0})` },
      list: jobsList,
    },
    {
      tab: { name: `Queued (${payload?.queued || 0})` },
      list: jobsList,
    },
    {
      tab: { name: `Failed (${payload?.failed || 0})` },
      list: jobsList,
    },
  ];

  const tabsComponents = tabs.map((tab) => {
    return {
      tab: tab.tab,
      tabComponent: (
        <Table
          {...tableProps}
          list={tab.list}
          isTabTable
          row={(item) => <JobRow key={item.id} item={item} refresh={refresh} />}
          pagination={{ ...pagination, className: cn({ [styles.pagination]: isMobileDevice }) }}
        />
      ),
    };
  });

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch} filters={<JobFilters />}>
        <div className={styles.spacer} />
      </Toolbar>
      <Tabs tabs={tabsComponents} activeTab={activeTab} onTabClick={onTabClick} />
    </Container>
  );
};

export { JobListPage };
