import { boolean, object, SchemaOf } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { emailColumnValidation, headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { DiffAndCommonValues, DiffCommonFileData } from "./diff-common.types";

export const supportedFormats = [csvFormat, txtFormat].flat();
export const maxFiles = 2;

export const getInitialData = (item: File): DiffCommonFileData => ({
  fileName: sanitizeFileName(item.name),
  emailIndex: -1,
  headers: [],
  hasHeaders: false,
});

export const validationSchema: SchemaOf<DiffAndCommonValues> = object({
  headerValues: object({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
});
