import { useState, FC } from "react";
import {
  Button,
  notification,
  FormButtons,
  useAutocomplete,
  FormField,
  useSubmit,
  useFormValues,
  Form,
  MessageField,
  NonNullableKeys,
} from "@epcnetwork/core-ui-kit";

import { putUser, getUser, getGroups, UpdateUserData } from "api";
import { UpdateUserProps } from "./update-user.types";
import { initialData, updateUserValidationSchema } from "./update-user.constants";

import styles from "./update-user.module.css";

export const UpdateUser: FC<UpdateUserProps> = ({ id, handleItemChange, handleCloseModal }) => {
  const [error, setError] = useState("");

  const { formProps, mapInitialValues, payload } = useFormValues(
    initialData,
    getUser.setParams({ userId: id as number }),
    !!id,
  );

  mapInitialValues((payload) => {
    return {
      email: payload.email,
      groupId: payload.groupId,
    };
  });

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    putUser.setParams({ userId: id as number }),
  );
  onSubmitSuccess((payload) => {
    handleItemChange(payload);
    notification.success("User edited", "User successfully edited");
  });
  onSubmitError((error) => {
    setError(error.message);
  });

  const onSubmit = onSubmitMapping((values: NonNullableKeys<UpdateUserData>) => {
    return {
      groupId: values.groupId,
    };
  });

  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");

  return (
    <div className={styles.container}>
      <Form {...formProps} onSubmit={onSubmit} validationSchema={updateUserValidationSchema}>
        {({ isValid }) => (
          <>
            <div className={styles.title}>User Form</div>
            <MessageField message={error} className={styles.errorMessage} />
            <div className={styles.row}>
              <FormField
                type="text"
                name="email"
                label="Email"
                placeholder="name@email.com"
                required
                disabled
              />
              <FormField
                type="select"
                name="groupId"
                label="Group"
                placeholder="Group"
                asyncOptions={groupsAutocomplete}
                required
              />
            </div>
            <FormButtons className={styles.buttons}>
              <Button type="button" onClick={handleCloseModal} appearance="secondary">
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Submit
              </Button>
            </FormButtons>
          </>
        )}
      </Form>
    </div>
  );
};
