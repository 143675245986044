import { object, SchemaOf, string } from "yup";

import { requiredFieldText, searchMinLengthFieldText } from "constants/form.constants";
import { ListData } from "api/lists/lists.types";

export const initialValues: ListData = {
  name: "",
};

export const validationSchema: SchemaOf<ListData> = object().shape({
  name: string().min(3, searchMinLengthFieldText).required(requiredFieldText),
});
