import { useState, useRef, FC, KeyboardEvent } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  notification,
  FormButtons,
  useAutocomplete,
  FormField,
  useSubmit,
  MessageField,
  NonNullableKeys,
} from "@epcnetwork/core-ui-kit";

import { postUser, getGroups } from "api";
import { UserFormData, UserFromProps } from "./create-user.types";
import { createUserValidationSchema, initialData, roles } from "./create-user.constants";

import styles from "./create-user.module.css";

export const CreateUser: FC<UserFromProps> = ({ handleItemChange, handleCloseModal }) => {
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");
  const inputRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);

  const [error, setError] = useState("");

  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(postUser);

  onSubmitSuccess((payload) => {
    handleItemChange(payload);

    notification.success("User added", "Successfully added user");
    handleCloseModal();
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const onSubmit = onSubmitMapping((values: NonNullableKeys<UserFormData>) => {
    const { email, password, role, groupId } = values;

    return {
      email,
      password,
      groupId,
      roles: [role],
    };
  });

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      inputRef.current?.focus();
    }
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialData}
        onSubmit={onSubmit}
        validationSchema={createUserValidationSchema}
        validateOnMount
      >
        {({ isValid }) => {
          return (
            <Form autoComplete="new-password">
              <div className={styles.title}>User Form</div>
              <MessageField message={error} className={styles.errorMessage} />
              <div className={styles.row}>
                <FormField
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="name@email.com"
                  required
                  // @ts-ignore
                  autocomplete="new-password"
                  autoComplete="new-password"
                  aria-autocomplete="none"
                />
                <FormField
                  type="select"
                  name="role"
                  label="Role"
                  placeholder="Role"
                  options={roles}
                  required
                />
                <FormField
                  type="password"
                  name="password"
                  placeholder="********"
                  label="Password"
                  autoComplete="new-password"
                  // @ts-ignore
                  autocomplete="new-password"
                  aria-autocomplete="none"
                  required
                  onKeyDown={handleKeyPress}
                  messageFieldClassName={styles.passwordError}
                />
                <FormField
                  type="password"
                  name="confirmPassword"
                  placeholder="********"
                  label="Confirm Password"
                  required
                  forwardRef={inputRef}
                  messageFieldClassName={styles.passwordError}
                  autoComplete="new-password"
                  // @ts-ignore
                  autocomplete="new-password"
                  aria-autocomplete="none"
                />
                <FormField
                  type="select"
                  name="groupId"
                  label="Groups"
                  placeholder="Groups"
                  asyncOptions={groupsAutocomplete}
                  required
                />
              </div>
              <FormButtons>
                <Button type="button" onClick={handleCloseModal} appearance="secondary">
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Submit
                </Button>
              </FormButtons>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
