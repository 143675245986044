import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useDidUpdate, useWillUnmount, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";

// Response types
type ProgressSocketPayload = {
  id: string;
  progress: number;
};
type DonePayload = {
  id: string;
  outputFilename: string;
};
type FailedPayload = {
  id: string;
  failedReason: string;
};

// Callback types
type ProgressSocket = (
  endpoint: "progress",
  callback: (data: ProgressSocketPayload) => void,
) => void;
type DownloadUrlSocket = (endpoint: "done", callback: (data: DonePayload) => void) => void;
type FailedSocket = (endpoint: "failed", callback: (data: FailedPayload) => void) => void;

// All sockets
type SocketsActions = ProgressSocket & DownloadUrlSocket & FailedSocket;

type SocketType = {
  on: SocketsActions;
} & Omit<Socket, "on">;

type UseSubmitReturnType = {
  socket: SocketType | null;
  onSocket: OnSocketType;
  clearSocket: () => void;
};

type OnSocketCallbackType = (socket: SocketType) => void;
type OnSocketType = (callback: OnSocketCallbackType) => void;

const initializeSocket = (token: string | null) => {
  return io(appEnvironment.apiUrl, {
    query: { token: token || "" },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    transports: ["polling"],
  });
};

let socket: SocketType | null = null;

const useSocket = (): UseSubmitReturnType => {
  const { token } = useSelector((state: RootState) => state.auth);

  const onSocketRef = useRef<OnSocketCallbackType | null>(null);

  useDidMount(() => {
    if (!socket) {
      socket = initializeSocket(token);
    }
    if (onSocketRef.current) onSocketRef.current(socket);
  });

  useDidUpdate(() => {
    if (socket) {
      socket.io.opts.query = {
        token,
      };
    }
  }, [token]);

  useWillUnmount(() => {
    clearSocket();
  });

  const clearSocket = () => {
    socket?.removeAllListeners();
  };

  const onSocket: OnSocketType = (socketCallback) => (onSocketRef.current = socketCallback);

  return {
    socket,
    onSocket,
    clearSocket,
  };
};

export { useSocket };
