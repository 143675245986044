import {
  FiltersType,
  QueryParams,
  NotificationProps,
} from "pages/suppression-lists/list/suppression-lists.types";

export const tableColumns = [
  { label: "List name", queryKey: "name" },
  { label: "Number of items", queryKey: "suppressionsAmount" },
  { label: "Group (Creator)", queryKey: "group" },
  { label: "Kind", queryKey: "isGlobal" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "" },
];

export const queryKeys = tableColumns.map(({ queryKey }) => `order[${queryKey}]`);
export const nonClearableKeys: string[] | Array<keyof FiltersType> = ["value"].concat(queryKeys);

export const initialQueryParams: QueryParams = {
  limit: 10,
  offset: 0,
};

interface DeletingMessageType {
  [notificationType: string]: NotificationProps;
}

export const deletingMessage: DeletingMessageType = {
  confirm: {
    title: "Delete this list?",
    message: "All emails will be deleted along with the list. Proceed?",
  },
  success: {
    title: "Suppression list type deleted",
    message: "Successfully deleted suppression list type",
  },
  error: {
    title: "Deleting error",
    message: "Cannot delete item",
  },
};
