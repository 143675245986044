import { array, boolean, object, SchemaOf, string } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat } from "constants/file.constants";
import { CutFileData, CutValues } from "./cut.types";

export const supportedFormats = [csvFormat].flat();

export const getInitialData = (item: File): CutFileData => ({
  fileName: sanitizeFileName(item.name),
  columns: [],
  headers: [],
  hasHeaders: false,
  emailIndex: -1,
  firstRow: [],
});

export const validationSchema: SchemaOf<CutValues> = object({
  fileValues: object({
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
    // firstRow: array().of(string().required()).notRequired(),
  }).required(requiredFieldText),
});
