import { useSelector } from "react-redux";
import { FC, useCallback } from "react";
import { format } from "date-fns";
import {
  Menu,
  MenuButton,
  Chip,
  TableCell,
  TableRow,
  TextEllipsis,
  Tooltip,
} from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { SuppressionModel } from "models";
import { useAuthorization, useUser } from "hooks";
import { SUPPRESSION_PERMISSION } from "constants/roles.constants";
import {
  getCategories,
  getEsp,
  getStatusAppearance,
  getSuppressionKind,
  getListNames,
} from "../suppression-list.constants";

import clockImg from "assets/images/clock.svg";
import calendarImg from "assets/images/calendar.svg";
import styles from "./suppression-table-row.module.css";

type RowProps = {
  item: SuppressionModel;
  refresh: VoidFunction;
  handleEditSuppression: (id: number | null) => void;
  handleDeleteSuppression: (itemToDelete: SuppressionModel) => void;
  handleSetEmail: (id: string) => void;
};

export const SuppressionRow: FC<RowProps> = ({
  item,
  handleEditSuppression,
  handleDeleteSuppression,
  handleSetEmail,
}) => {
  const ability = useAuthorization();

  const { isAdmin } = useUser();
  const { user } = useSelector((state: RootState) => state.auth);

  const createdAtDate = new Date(item.createdAt);
  const isUserInGroup = item.lists.some((list) => list.groupId === user?.groupId);

  const handleItemDelete = () => {
    handleSetEmail(item.value);
    if (isAdmin || isUserInGroup) {
      handleDeleteSuppression(item);
    }
  };

  const handleItemEdit = () => {
    handleEditSuppression(item.id);
  };

  const getManagePermissions = useCallback((): boolean => {
    const canUpdateSuppression = ability.can("update", SUPPRESSION_PERMISSION.name);
    const hasGlobalList = item.lists.some((list) => list.isGlobal);

    if (isAdmin) return true;
    if (!isAdmin && hasGlobalList) return false;
    return canUpdateSuppression;
  }, [ability, isAdmin, item.lists]);

  const canManage = getManagePermissions();

  const editButton = (
    <MenuButton onClick={handleItemEdit} icon="edit" disabled={!canManage}>
      Edit
    </MenuButton>
  );

  const deleteButton = (
    <MenuButton appearance="delete" icon="delete" onClick={handleItemDelete} disabled={!isAdmin}>
      Delete
    </MenuButton>
  );

  return (
    <TableRow id={item.id}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" className={styles.value}>
          {item.value}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" className={styles.value}>
          {item.type}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {item.lists.length === 1 ? <Chip text={item.lists[0].name} /> : getListNames(item.lists)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <span className={styles.capitalizedValue}>{item.source}</span>
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getCategories(item.categories)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getEsp(item.esps)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getSuppressionKind(item.lists)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        {item.status && (
          <Chip
            className={styles.chip}
            text={item.status}
            appearance={getStatusAppearance(item.status)}
            borderStyle="rounded"
          />
        )}
        {!item.status && "-"}
      </TableCell>
      <TableCell className={styles.createdAt}>
        <img src={calendarImg} alt="" width={11} height={11} />
        {createdAtDate ? format(createdAtDate, "MMM dd, yyyy") : "-"}
        <img src={clockImg} alt="" width={11} height={11} />
        {createdAtDate ? format(createdAtDate, "hh:mm aaa") : "-"}
      </TableCell>
      <TableCell>
        <Menu>
          {canManage ? (
            editButton
          ) : (
            <Tooltip
              trigger="hover"
              position="top-left"
              triggerElement={editButton}
              portalClassName={styles.tooltip}
            >
              You have no access to update this suppression value
            </Tooltip>
          )}
          {isAdmin ? (
            deleteButton
          ) : (
            <Tooltip
              trigger="hover"
              position="bottom-left"
              triggerElement={deleteButton}
              portalClassName={styles.tooltip}
            >
              Only admin can delete suppression values
            </Tooltip>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};
