import { FC } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import { FormField, ArrayElement, useDidUpdate } from "@epcnetwork/core-ui-kit";

import {
  SUPPRESSION_LIST_PERMISSION,
  SUPPRESSION_PERMISSION,
  USERS_PERMISSION,
} from "constants/roles.constants";
import { RoleEntitiesOptionType, RoleFormValues, RoleEntitiesType } from "../update-roles.types";

import globalStyles from "assets/styles/global.module.css";
import styles from "../update-roles.module.css";

type FormTableProps = {
  options: RoleEntitiesOptionType[];
  permissionAll: RoleEntitiesType["permissionAll"];
  headKeys: string[];
  isAccess?: boolean;
};

const FormTable: FC<FormTableProps> = ({ options, permissionAll, isAccess, headKeys }) => {
  const { values, setFieldValue } = useFormikContext<RoleFormValues>();

  useDidUpdate(
    () => {
      // set READ user list when checking a CREATE user permission
      // it's necessary to have READ user list permission in order to create a user
      if (
        values.roles[`${USERS_PERMISSION.permission}_C`] &&
        !values.roles[`${USERS_PERMISSION.permission}_R`]
      ) {
        setFieldValue("roles", { ...values.roles, [`${USERS_PERMISSION.permission}_R`]: true });
      }
    },
    [values.roles],
    true,
  );

  useDidUpdate(
    () => {
      // set READ suppression list when checking a CREATE suppression permission
      // it's necessary to have a READ suppression list permission in order to create a suppression
      if (
        values.roles[`${SUPPRESSION_PERMISSION.permission}_C`] &&
        !values.roles[`${SUPPRESSION_LIST_PERMISSION.permission}_R`]
      ) {
        setFieldValue("roles", {
          ...values.roles,
          [`${SUPPRESSION_LIST_PERMISSION.permission}_R`]: true,
        });
      }
    },
    [values.roles],
    true,
  );

  const hasFullAccess =
    (Object.keys(values.roles).includes(permissionAll) && headKeys.includes(permissionAll)) ||
    undefined ||
    Object.keys(values.roles).includes("ADMIN");

  const showActionCheckbox = (
    option: RoleEntitiesOptionType,
    action: ArrayElement<RoleEntitiesOptionType["actions"]>,
  ) => {
    return option.actions.includes(action);
  };

  const stylesForFullAccess = cn(
    { [styles.checkBoxChecked]: hasFullAccess },
    styles.checkboxWrapper,
  );

  return (
    <div className={`${styles.responsiveTable} ${globalStyles.addScrollStyles}`}>
      <table className={styles.roleTable}>
        <thead>
          {isAccess && (
            <tr className={styles.tableRow}>
              <td>Section</td>
              <td className={styles.tableCellCenter}>Access</td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          )}
          {!isAccess && (
            <tr className={styles.tableRow}>
              <td>Section</td>
              <td className={styles.tableCellCenter}>Read</td>
              <td className={styles.tableCellCenter}>Create</td>
              <td className={styles.tableCellCenter}>Update</td>
            </tr>
          )}
        </thead>
        <tbody>
          {isAccess &&
            options.map((option) => (
              <tr key={option.name}>
                <td>{option.name}</td>
                <td className={styles.accessPermissionRow}>
                  <FormField
                    disabled={hasFullAccess}
                    type="checkbox"
                    inputSize="big"
                    name={`roles[${option.permission}]`}
                    className={stylesForFullAccess}
                    disableError
                  />
                </td>
              </tr>
            ))}
          {!isAccess &&
            options.map((option) => {
              const actions: RoleEntitiesOptionType["actions"] = ["read", "create", "update"];

              const [showRead, showCreate, showUpdate] = actions.map((action) =>
                showActionCheckbox(option, action),
              );

              const createUserChecked =
                option.permission === USERS_PERMISSION.permission &&
                Boolean(values.roles[`${USERS_PERMISSION.permission}_C`]);

              const createSuppressionChecked =
                option.permission === SUPPRESSION_LIST_PERMISSION.permission &&
                Boolean(values.roles[`${SUPPRESSION_PERMISSION.permission}_C`]);

              return (
                <tr key={option.name}>
                  <td>{option.name}</td>
                  <td className={showRead ? styles.permissionRow : ""}>
                    {showRead && (
                      <FormField
                        disabled={hasFullAccess || createUserChecked || createSuppressionChecked}
                        type="checkbox"
                        inputSize="big"
                        name={`roles[${option.permission}_R]`}
                        className={stylesForFullAccess}
                        disableError
                      />
                    )}
                  </td>
                  <td className={showCreate ? styles.permissionRow : ""}>
                    {showCreate && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        inputSize="big"
                        name={`roles[${option.permission}_C]`}
                        className={stylesForFullAccess}
                        disableError
                      />
                    )}
                  </td>
                  <td className={showUpdate ? styles.permissionRow : ""}>
                    {showUpdate && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        inputSize="big"
                        name={`roles[${option.permission}_U]`}
                        className={stylesForFullAccess}
                        disableError
                      />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export { FormTable };
