import { useNavigate } from "react-router-dom";
import { FC, useCallback, useRef, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  FileItem,
  FileUpload,
  useWillUnmount,
  useDidUpdate,
  useFileQueue,
  Nullable,
  DropAcceptedFunc,
  notification,
  Checkbox,
} from "@epcnetwork/core-ui-kit";

import {
  getAcceptedFilesText,
  getFileUuidName,
  getPipelineRoute,
  getSupportedFormats,
  onFileRedirect,
} from "utils";
import { useTracking } from "hooks";
import { SMARTLOOK_EVENTS } from "constants/smartlook.constants";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { postMergeFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { MergeFileItem, PipelineMergeData } from "./merge.types";
import { supportedFormats, maxFiles, getInitialData } from "./merge.constants";
import { MergeFormModal } from "./form-modal/merge-form-modal";

import styles from "./merge.module.css";

const MergePage: FC = () => {
  const componentIsMounted = useRef(true);
  const navigate = useNavigate();

  const { pipelineSubmitEvent } = useTracking();

  const pipeline = getPipelineRoute("Merge");

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitAtOnce,
    hasAllSuccessFiles,
  } = useFileQueue<MergeFileItem, PipelineMergeData>(pipeline.title, postMergeFile);

  useWillUnmount(() => {
    componentIsMounted.current = false;
  });

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [suppress, setSuppress] = useState(true);
  const [editedFile, setEditedFile] = useState<Nullable<MergeFileItem>>(null);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: MergeFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSuppressChange = useCallback((value: string, isChecked: boolean) => {
    setSuppress(isChecked);
  }, []);

  const handleSubmit = () => {
    const filesInfo: PipelineMergeData["filesInfo"] = files.map((file) => {
      const { emailIndex, fileName } = file.data;
      const id = getFileUuidName(file);

      return { emailIndex, filename: fileName, suppress, id };
    });

    const formData = new FormData();
    formData.append("filesInfo", JSON.stringify(filesInfo));
    files.forEach((file) => {
      formData.append("files", file.originalFile, getFileUuidName(file));
    });

    submitAtOnce(
      files.map(({ id }) => id),
      formData,
    );
    pipelineSubmitEvent(SMARTLOOK_EVENTS.mergeSubmit, files);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, navigate });
  };

  const handleRedirectToPipelines = () => {
    navigate(PIPELINES_LIST_PAGE.path);
  };

  const isSubmitDisabled = !hasAllConfigured || files.length <= 1 || files.length >= maxFiles;

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <div className={styles.example}>A + B = C</div>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            maxFiles={maxFiles}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleConfigureItem}
                onEditValuesClick={handleConfigureItem}
              >
                {data.emailIndex >= 0 && (
                  <div className={styles.additionalInfo}>
                    <span>Selected column:</span> {data.emailIndex + 1}
                  </div>
                )}
              </FileItem>
            ))}
          </div>
          {editedFile && (
            <MergeFormModal
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.actions}>
            <Checkbox
              checked={suppress}
              value="suppress"
              onChange={handleSuppressChange}
              label={suppressionCheckboxLabel}
              disabled={false}
              disableError
            />
            <div className={styles.buttons}>
              <Button
                appearance="secondary"
                onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
                disabled={isEntityFinished && !hasAllSuccessFiles}
              >
                Cancel
              </Button>
              {!isEntityFinished && (
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                  loading={!isEntityInConfiguration}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export { MergePage };
