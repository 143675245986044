import { memo } from "react";
import { useFormContext } from "@epcnetwork/core-ui-kit";

import { FileTableFormik, FileTableFormikProps } from "./components/file-table-formik";

type FileTableProps = { type: "file-table" } & FileTableFormikProps;

type FormFieldProps = FileTableProps;

export const FormField = memo<FormFieldProps>(({ type = "file-table", disabled, ...rest }) => {
  const [formState] = useFormContext();
  const { isLoading, isDisabled } = formState;

  const inactive = isLoading || isDisabled || disabled;

  return <FileTableFormik {...(rest as FileTableProps)} disabled={inactive} />;
});
