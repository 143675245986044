import { FC } from "react";
import { Filters, Select, DateInput, useAutocomplete } from "@epcnetwork/core-ui-kit";

import { convertToNumber } from "utils";
import { useAuthorization } from "hooks";
import { PIPELINES_LIST } from "constants/pipelines.constants";
import { getUsers, getGroups, getDatabasesNames } from "api";
import { queryKeys } from "../jobs-list.constants";

import styles from "../jobs-list.module.css";

export type FiltersType = {
  pipelineNames: string;
  databases: string[];
  userIds: string[];
  groupIds: string[] | null;
  createdAtFrom: string;
  createdAtTo: string;
};

export const JobFilters: FC = () => {
  const ability = useAuthorization();
  const nonClearableKeys = ["value", "status"].concat(queryKeys);
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");
  const usersAutocomplete = useAutocomplete(getUsers, "id", "email");
  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");
  const currentDate = new Date();

  const pipelinesOptions = PIPELINES_LIST.map((pipeline) => ({
    value: pipeline.backendName,
    label: pipeline.title,
  }));

  return (
    <Filters searchKey="value" nonClearableKeys={nonClearableKeys}>
      {({ setSelect, getSelect, setDatePickerDate, getDatePickerDate }) => {
        const [startFrom, startTo] = getDatePickerDate(["createdAtFrom", "createdAtTo"]);
        const userIds = convertToNumber(getSelect("userIds", true));
        const groupIds = convertToNumber(getSelect("groupIds", true));

        return (
          <div>
            <Filters.ContentItem>
              <Select
                label="Pipeline"
                onChange={setSelect("pipelineNames", true)}
                selectedOptionsKeys={getSelect("pipelineNames", true)}
                options={pipelinesOptions}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Database"
                onChange={setSelect("databases", true)}
                selectedOptionsKeys={getSelect("databases", true)}
                asyncOptions={databasesAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="User"
                onChange={setSelect("userIds", true)}
                selectedOptionsKeys={userIds}
                asyncOptions={usersAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Group"
                onChange={setSelect("groupIds", true)}
                selectedOptionsKeys={groupIds}
                asyncOptions={groupsAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <DateInput
                className={styles.inputDate}
                dateFrom={startFrom}
                dateTo={startTo}
                max={currentDate}
                onChange={setDatePickerDate(["createdAtFrom", "createdAtTo"], true)}
                isMulti
                disableError
              />
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};
