import { useNavigate } from "react-router-dom";
import { FC, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  FileItem,
  FileUpload,
  useDidUpdate,
  useFileQueue,
  Nullable,
  DropAcceptedFunc,
  notification,
} from "@epcnetwork/core-ui-kit";

import { getAcceptedFilesText, getPipelineRoute, getSupportedFormats, onFileRedirect } from "utils";
import { useTracking } from "hooks";
import { SMARTLOOK_EVENTS } from "constants/smartlook.constants";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { postReverseFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { FilePresetSwitch } from "../components/file-preset-switch/file-preset-switch";
import { PipelineReverseData, ReverseFileInfo, ReverseFileItem } from "./reverse.types";
import { getInitialData, supportedFormats } from "./reverse.constants";
import { ReverseFormModal } from "./form-modal/reverse-form-modal";

import styles from "./reverse.module.css";

const ReversePage: FC = () => {
  const navigate = useNavigate();

  const { pipelineSubmitEvent } = useTracking();

  const pipeline = getPipelineRoute("Reverse");

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<ReverseFileItem, PipelineReverseData>(pipeline.title, postReverseFile);

  const presetFile: ReverseFileItem | undefined = useMemo(() => files[0], [files]);
  const [editedFile, setEditedFile] = useState<Nullable<ReverseFileItem>>(null);
  const [isAppliedToAll, setIsAppliedToAll] = useState(false);

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  useDidUpdate(() => {
    if (files.length <= 1 && isAppliedToAll) {
      setIsAppliedToAll(false);
    }
  }, [files]);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) =>
          createInitialFile(file, {
            isTuned: isAppliedToAll && presetFile?.isTuned,
            data:
              isAppliedToAll && presetFile
                ? {
                    ...presetFile.data,
                    fileName: file.name,
                  }
                : getInitialData(file),
          }),
        ),
      );
    },
    [addFiles, createInitialFile, isAppliedToAll, presetFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: ReverseFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();
      const { fileName, hashType, databases, suppress, headers } = file.data;

      const hashIndex = headers.findIndex((header) => Boolean(header));
      const data: ReverseFileInfo = {
        hashType,
        hashIndex,
        databases,
        suppress,
      };

      formData.append("fileInfo", JSON.stringify(data));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
    pipelineSubmitEvent(SMARTLOOK_EVENTS.reverseSubmit, files);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, navigate });
  };

  const handleRedirectToPipelines = () => {
    navigate(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />
        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          {files.length > 1 && (
            <FilePresetSwitch
              isAppliedToAll={isAppliedToAll}
              presetFile={presetFile}
              files={files}
              updateFiles={updateFiles}
              setIsAppliedToAll={setIsAppliedToAll}
            />
          )}
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => {
              const hashIndex = data.headers.findIndex((header) => Boolean(header));

              return (
                <FileItem
                  {...rest}
                  key={id}
                  id={id}
                  file={originalFile}
                  onCrossClick={removeFiles}
                  onSetValuesClick={handleConfigureItem}
                  onEditValuesClick={handleConfigureItem}
                >
                  {hashIndex >= 0 && (
                    <div className={styles.additionalInfo}>
                      <span>Selected column:</span> {hashIndex + 1}
                    </div>
                  )}
                  {!!data?.databases.length && (
                    <div className={styles.additionalInfo}>
                      <span>Databases:</span> {data.databases.join(", ")}
                    </div>
                  )}
                </FileItem>
              );
            })}
          </div>
          {editedFile && (
            <ReverseFormModal
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}

          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
              disabled={isEntityFinished && !hasAllSuccessFiles}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!hasAllConfigured || !files.length}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export { ReversePage };
