import { boolean, mixed, object, SchemaOf } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { headersValidation, selectMultipleValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { HashTypesEnum, ReverseFileData, ReverseValues } from "./reverse.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const hashOptions = [
  { value: HashTypesEnum.md5, label: "MD5" },
  { value: HashTypesEnum.sha256, label: "SHA256" },
];

export const validationSchema: SchemaOf<ReverseValues> = object({
  fileValues: object({
    headers: headersValidation(),
    suppress: boolean(),
    databases: selectMultipleValidation(),
    hashType: mixed<HashTypesEnum>()
      .oneOf(Object.values(HashTypesEnum))
      .required(requiredFieldText),
  }).required(requiredFieldText),
});

export const getInitialData = (item: File): ReverseFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  databases: [],
  hashType: hashOptions[0].value,
  suppress: true,
});
