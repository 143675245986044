import { FC, useState, ChangeEvent } from "react";
import cn from "classnames";
import { FormField } from "@epcnetwork/core-ui-kit";

import { RoleEntitiesType } from "../update-roles.types";

import collapseArrowImg from "assets/images/collapse-arrow.svg";
import styles from "../update-roles.module.css";

type HeadRowProps = {
  entity: RoleEntitiesType;
  handleSelectHeadKeys: (id: string) => void;
  disabled?: boolean;
};

const HeadRow: FC<HeadRowProps> = ({ entity, disabled, handleSelectHeadKeys }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckbox = (
    _value: string,
    _isChecked: boolean,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
    const value = event.target.value.replace("roles[", "").replace("]", "");
    handleSelectHeadKeys(value);
  };

  const handleOpen = () => setIsOpen((prev) => !prev);

  const arrowStyles = cn(styles.collapseArrow, {
    [styles.open]: isOpen,
  });

  return (
    <button className={styles.headSection} type="button" onClick={handleOpen}>
      <img src={collapseArrowImg} alt="" className={arrowStyles} width={11} height={8} />
      <div className={styles.entityName}>{entity.name}</div>
      <FormField
        type="switch"
        name={`roles[${entity.permissionAll}]`}
        disabled={disabled}
        label="Full access"
        disableError
        onChange={handleCheckbox}
        defaultChecked
      />
    </button>
  );
};

export { HeadRow };
