import { boolean, object, SchemaOf } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { emailColumnValidation, headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { SHA256FileData, Sha256Values } from "./sha256.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const validationSchema: SchemaOf<Sha256Values> = object({
  fileValues: object({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
    suppress: boolean(),
  }).required(requiredFieldText),
});

export const getInitialData = (item: File): SHA256FileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  emailIndex: -1,
  suppress: true,
});
