import { FC } from "react";

import { SlideType } from "../login-slider";

import styles from "./slider-controllers.module.css";

type SliderControllersProps = {
  sliders: SlideType[];
  currentSlide: SlideType;
  setCurrentSlide: (slide: SlideType) => void;
};

const SliderControllers: FC<SliderControllersProps> = ({
  setCurrentSlide,
  sliders,
  currentSlide,
}) => (
  <div className={styles.slideContollers}>
    {sliders.map((slide) => (
      <div
        onClick={() => setCurrentSlide(slide)}
        key={slide.id}
        className={
          currentSlide.id === slide.id
            ? `${styles.slideController} ${styles.slideControllerActive}`
            : styles.slideController
        }
      />
    ))}
  </div>
);

export { SliderControllers };
