import { object, string } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { Values } from "./send-email.types";

export const initialValues: Values = {
  email: "",
};

export const validationSchema = object().shape({
  email: string().email("Invalid Email").required(requiredFieldText),
});
