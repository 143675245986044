import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import React from "react";

import { pipelineDetails } from "pages/stats/stats.constants";
import { Pipelines, RangeMetricsResponse } from "api/pipelines/pipelines.interface";
import { CustomTooltip } from "../custom-tooltip/custom-tooltip";

interface Props {
  data: RangeMetricsResponse;
  currentPipelines?: Pipelines[];
}

export const LineChart: React.FC<Props> = ({ data, currentPipelines }) => {
  return (
    <div style={{ marginLeft: "-40px" }}>
      <ResponsiveContainer width="100%" height={260}>
        <RechartsLineChart data={data}>
          <CartesianGrid />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip content={(props) => <CustomTooltip tooltipProps={props} />} />

          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            padding={{
              right: 3,
              left: 3,
            }}
            tickLine={false}
          />
          <YAxis tickLine={false} width={50} />

          {currentPipelines?.map((pipeline) => {
            const color = pipelineDetails[pipeline].fill;

            return (
              <Line
                key={pipeline}
                type="monotone"
                dataKey={pipeline}
                stroke={color}
                activeDot={{ r: 4 }}
              />
            );
          })}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
};
