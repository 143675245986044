import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { FC, ReactNode } from "react";
import {
  FileQueueProvider,
  ThemeProvider,
  NotificationProvider,
  ErrorBoundary,
  useDidMount,
} from "@epcnetwork/core-ui-kit";

import store from "store";
import { useTracking } from "hooks";
import { lightTheme } from "constants/themes.constants";
import { DEFAULT_PAGE } from "constants/routes.constants";
import { FileQueueNotifications } from "components";
import { AuthorizationGuard } from "../authorization/authorization";

const themes = [lightTheme];

interface ProvidersProps {
  children: ReactNode;
}

export const Providers: FC<ProvidersProps> = ({ children }) => {
  const { init } = useTracking();

  const handleRefresh = () => (window.location.href = DEFAULT_PAGE.path);

  useDidMount(() => {
    init();
  });

  return (
    <Provider store={store}>
      <AuthorizationGuard>
        <ThemeProvider themes={themes} initialTheme={themes[0]}>
          <BrowserRouter>
            <ErrorBoundary
              title="Oops! Something went wrong"
              subtitle="Page could not be loaded. Please try later"
              refreshText="Go to homepage"
              refreshClick={handleRefresh}
            >
              <FileQueueProvider>
                <NotificationProvider>{children}</NotificationProvider>
                <FileQueueNotifications />
              </FileQueueProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </AuthorizationGuard>
    </Provider>
  );
};
