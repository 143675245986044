import { Link as RouterLink, useLocation } from "react-router-dom";
import { FC, useMemo } from "react";
import cn from "classnames";
import { useLocalStorage } from "@epcnetwork/core-ui-kit";

import { isActiveRoute } from "utils";
import { JOBS_LIST_PAGE } from "constants/routes.constants";
import { SIDEBAR_COLLAPSED_FIELD } from "../sidebar.constants";

import styles from "../sidebar.module.css";

interface LinkProps {
  src: string;
  text: string;
  to: string;
  initialQuery?: string;
  exact?: boolean;
  className?: string;
}

const Link: FC<LinkProps> = ({ src, text, to, initialQuery, exact, className = "" }) => {
  const location = useLocation();

  const { value } = useLocalStorage(SIDEBAR_COLLAPSED_FIELD);

  const getActiveStyle = () => {
    const isActive = isActiveRoute(to, location);

    if (to === JOBS_LIST_PAGE.path && (location.pathname === "" || location.pathname === "/")) {
      return styles.activeLink;
    }

    return isActive ? styles.activeLink : "";
  };

  const path = useMemo(() => {
    if (!initialQuery) return to;
    return `${to}?${initialQuery}`;
  }, [to, initialQuery]);

  const activeStyle = getActiveStyle();
  const linkStyles = cn(styles.link, activeStyle, className, { [styles.collapsed]: value });

  return (
    <RouterLink to={path} className={linkStyles}>
      <img src={src} alt="" />
      <span className={styles.linkText}>{text}</span>
    </RouterLink>
  );
};

export { Link };
