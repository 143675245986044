import { useNavigate } from "react-router-dom";
import { FC } from "react";
import cn from "classnames";

import styles from "./pipeline-card.module.css";

type PipelineCardProps = {
  src: string;
  path: string;
  title: string;
  subtitle: string;
  db: boolean;
};

const PipelineCard: FC<PipelineCardProps> = ({ src, path, title, subtitle, db }) => {
  const navigate = useNavigate();

  const handleLink = () => {
    navigate(path);
  };

  return (
    <button className={styles.card} onClick={handleLink}>
      <div className={cn(styles.cardImageWrapper, { [styles.cardImageWrapperWithBd]: db })}>
        <img className={cn(styles.cardImage, { [styles.cardImageWithBd]: db })} src={src} alt="" />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </button>
  );
};

export { PipelineCard };
