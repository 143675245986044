import { FC, ReactNode } from "react";

import { PipelineRoutes } from "constants/pipelines.constants";
import { PipelineCard } from "../pipeline-card/pipeline-card";

import styles from "./pipeline-wrapper.module.css";

type PipelineWrapperProps = {
  list: PipelineRoutes[];
  title: string;
  subtitle: ReactNode;
};

const PipelineWrapper: FC<PipelineWrapperProps> = ({ list, title, subtitle }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.descriptionWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>

      <div className={styles.list}>
        {list.map((pipeline) => (
          <PipelineCard {...pipeline} key={pipeline.title} />
        ))}
      </div>
    </div>
  );
};

export { PipelineWrapper };
