import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  MessageField,
  FormButtons,
  useFileParser,
  FormField as UiKitFormField,
} from "@epcnetwork/core-ui-kit";

import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { FormField } from "components";
import { MD5FileItem, Md5Values } from "../md5.types";
import { validationSchema } from "../md5.constants";

import styles from "../md5.module.css";

type MD5ModalFormProps = {
  file: MD5FileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: MD5FileItem) => void;
};

const MD5ModalForm: FC<MD5ModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<Md5Values>({ fileValues: file.data });

  const onSubmit = (values: Md5Values, { setSubmitting }: FormikHelpers<Md5Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: { ...file.data, ...values.fileValues },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        {({ values }) => {
          const emailColumnSelected = values.fileValues.headers.includes("email");

          return (
            <>
              <FormField
                type="file-table"
                name="fileValues"
                label="Select Email column"
                parsedFile={parsedFile}
                loading={loading}
              />

              <div className={styles.actions}>
                <UiKitFormField
                  type="checkbox"
                  name="fileValues.suppress"
                  label={suppressionCheckboxLabel}
                  disabled={!emailColumnSelected}
                  disableError
                />

                <FormButtons>
                  <Button appearance="secondary" onClick={setClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Set values</Button>
                </FormButtons>
              </div>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export { MD5ModalForm };
