import { AbilityBuilder, AnyAbility, createMongoAbility } from "@casl/ability";

import { PermissionsModel, AuthorizationActionType, AuthorizationEntityNameType } from "types";
import { UserModel } from "models";
import {
  PIPELINES_PERMISSIONS,
  PIPELINES_PERMISSION,
  USERS_PERMISSION,
  SUPPRESSION_PERMISSION,
  OPEN_PERMISSION,
  ADMIN_PERMISSION,
  JOBS_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  SUPPRESSION_FILE_PERMISSION,
  PIPELINES_LIST_PERMISSION,
  STATS_PERMISSION,
} from "constants/roles.constants";

export const authorizationSuffixes = {
  create: "_C",
  read: "_R",
  update: "_U",
  delete: "_D",
};

export const createAuthorizationConfig = (user: UserModel | null): AnyAbility => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  const canUserWithPermissions = (
    authorizedPermissions: PermissionsModel,
    action: AuthorizationActionType,
    entityName: AuthorizationEntityNameType,
    subPermission?: PermissionsModel,
  ) => {
    const userRoles = user?.roles || [];
    const actionSuffix = authorizationSuffixes[action];

    const contextPermissions = [
      subPermission || authorizedPermissions,
      (authorizedPermissions + actionSuffix) as PermissionsModel,
    ];

    const neededPermissions = [ADMIN_PERMISSION, ...contextPermissions];

    const isPermitted = !!neededPermissions.find((permission) => userRoles.includes(permission));
    const isUnlimitedAccess = authorizedPermissions.includes(OPEN_PERMISSION);

    if (isUnlimitedAccess || isPermitted) {
      can(action, entityName);
    } else {
      cannot(action, entityName);
    }
  };

  // Jobs
  canUserWithPermissions(JOBS_PERMISSION.permission, "read", JOBS_PERMISSION.name);

  // Pipelines
  canUserWithPermissions(
    PIPELINES_LIST_PERMISSION.permission,
    "read",
    PIPELINES_LIST_PERMISSION.name,
    PIPELINES_PERMISSION.permission,
  );

  PIPELINES_PERMISSIONS.forEach((pipelinePermission) => {
    canUserWithPermissions(
      pipelinePermission.permission,
      "create",
      pipelinePermission.name,
      PIPELINES_PERMISSION.permission,
    );
  });

  // Suppression
  canUserWithPermissions(SUPPRESSION_PERMISSION.permission, "create", SUPPRESSION_PERMISSION.name);
  canUserWithPermissions(SUPPRESSION_PERMISSION.permission, "read", SUPPRESSION_PERMISSION.name);
  canUserWithPermissions(SUPPRESSION_PERMISSION.permission, "update", SUPPRESSION_PERMISSION.name);

  // Suppression file
  canUserWithPermissions(
    SUPPRESSION_FILE_PERMISSION.permission,
    "create",
    SUPPRESSION_FILE_PERMISSION.name,
    SUPPRESSION_PERMISSION.permission,
  );

  // Suppression list
  canUserWithPermissions(
    SUPPRESSION_LIST_PERMISSION.permission,
    "create",
    SUPPRESSION_LIST_PERMISSION.name,
    SUPPRESSION_PERMISSION.permission,
  );
  canUserWithPermissions(
    SUPPRESSION_LIST_PERMISSION.permission,
    "read",
    SUPPRESSION_LIST_PERMISSION.name,
    SUPPRESSION_PERMISSION.permission,
  );
  canUserWithPermissions(
    SUPPRESSION_LIST_PERMISSION.permission,
    "update",
    SUPPRESSION_LIST_PERMISSION.name,
    SUPPRESSION_PERMISSION.permission,
  );

  // Stats
  canUserWithPermissions(STATS_PERMISSION.permission, "read", STATS_PERMISSION.name);

  // Users
  canUserWithPermissions(USERS_PERMISSION.permission, "create", USERS_PERMISSION.name);
  canUserWithPermissions(USERS_PERMISSION.permission, "read", USERS_PERMISSION.name);
  canUserWithPermissions(USERS_PERMISSION.permission, "update", USERS_PERMISSION.name);
  canUserWithPermissions(USERS_PERMISSION.permission, "delete", USERS_PERMISSION.name);

  return build();
};
