import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  MessageField,
  FormButtons,
  useAutocomplete,
  FormField,
  useFileParser,
  FormField as UiKitFormField,
} from "@epcnetwork/core-ui-kit";

import { filterDatabasesPipelines } from "utils";
import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { FormField as LocalFormField } from "components";
import { getDatabasesNames } from "api";
import { SuppressFileItem, SuppressValues } from "../match-and-eliminate.types";
import { validationSchema } from "../match-and-eliminate.constants";

import styles from "../match-and-eliminate.module.css";

type SuppressModalFormProps = {
  file: SuppressFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SuppressFileItem) => void;
};

export const MatchAndEliminateFormModal: FC<SuppressModalFormProps> = ({
  file,
  setClose,
  onSubmitClick,
}) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<SuppressValues>({
    fileValues: {
      emailIndex: file.data.emailIndex,
      headers: file.data.headers,
      suppress: file.data.suppress,
      databases: file.data.databases,
    },
  });

  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");
  databasesAutocomplete.fetchOptions = filterDatabasesPipelines(databasesAutocomplete.fetchOptions);

  const onSubmit = (values: SuppressValues, { setSubmitting }: FormikHelpers<SuppressValues>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.fileValues,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.modal}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        {({ values }) => {
          const emailColumnSelected = values.fileValues.emailIndex >= 0;

          return (
            <>
              <FormField
                type="select"
                name="fileValues.databases"
                label="Databases"
                placeholder="Databases"
                asyncOptions={databasesAutocomplete}
                highlightKeys={["suppression"]}
                isMulti
                canSelectAll
              />
              <LocalFormField
                type="file-table"
                name="fileValues"
                label="Select Email column"
                parsedFile={parsedFile}
                loading={loading}
              />

              <div className={styles.actions}>
                <UiKitFormField
                  type="checkbox"
                  name="fileValues.suppress"
                  label={suppressionCheckboxLabel}
                  disabled={!emailColumnSelected}
                  disableError
                />
                <FormButtons>
                  <Button appearance="secondary" onClick={setClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Set values</Button>
                </FormButtons>
              </div>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
