import { useState, FC } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  FormButtons,
  Button,
  useCall,
  notification,
  CollapseSelfControlled,
  MessageField,
  useFormValues,
} from "@epcnetwork/core-ui-kit";

import { PIPELINES_PERMISSIONS } from "constants/roles.constants";
import { putUserRoles, getUser } from "api";
import { RoleFormValues, RolesFormProps } from "./update-roles.types";
import {
  roleEntities,
  allHeadKeys,
  animationDuration,
  initialValues,
  PIPELINE_LIST_PERMISSION_KEY,
} from "./update-roles.constant";
import { HeadRow } from "./components/head-row";
import { FormTable } from "./components/form-table";

import styles from "./update-roles.module.css";

export const UpdateRoles: FC<RolesFormProps> = ({ id, handleCloseModal, handleItemChange }) => {
  const [headKeys, setHeadKeys] = useState<string[]>([]);
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState("");
  const [isAdminRole, setIsAdminRole] = useState(false);

  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getUser.setParams({ userId: id as number }),
    !!id,
  );

  const title = userEmail && `${userEmail} Role Permissions`;

  mapInitialValues((payload) => {
    const list = payload.roles.filter((role) => allHeadKeys.includes(role));
    setHeadKeys(list);
    setUserEmail(payload.email);

    if (payload.roles.includes("ADMIN")) setIsAdminRole(true);

    const roles = payload.roles.reduce((acc: Record<string, string>, cur) => {
      if (!cur) return acc;

      acc[cur] = cur;
      return acc;
    }, {});

    return {
      ...payload,
      roles,
    };
  });

  const { submit, onCallSuccess, onCallError } = useCall(putUserRoles);
  onCallSuccess((payload) => {
    notification.success("Successfully edited permissions", "");
    handleItemChange(payload);
    handleCloseModal();
  });
  onCallError((error) => {
    setError(error.message);
  });

  const handleSelectHeadKeys = (id: string) => {
    if (headKeys.includes(id)) {
      const newHeadKeys = headKeys.filter((key) => key !== id);
      setHeadKeys(newHeadKeys);
      return;
    }

    const newHeadKeys = [...headKeys, id];
    setHeadKeys(newHeadKeys);
  };

  const onSubmit = async (values: RoleFormValues, helpers: FormikHelpers<RoleFormValues>) => {
    const rolesKeys = Object.keys(values.roles);
    let result = rolesKeys.filter((key) => {
      if (typeof values.roles[key] === "boolean" && !values.roles[key]) {
        return;
      }

      return key;
    });

    const allowedAnyPipeline = PIPELINES_PERMISSIONS.some((p) =>
      result.includes(`${p.permission}_C`),
    );

    if (allowedAnyPipeline) {
      const containsListPermission = result.includes(PIPELINE_LIST_PERMISSION_KEY);

      if (!containsListPermission) result = result.concat([PIPELINE_LIST_PERMISSION_KEY]);
    } else {
      result = result.filter((value) => value !== PIPELINE_LIST_PERMISSION_KEY);
    }

    await submit({ params: { userId: id as number }, data: { roles: result } });
    helpers.setSubmitting(false);
  };

  return (
    <Form
      className={styles.form}
      enableReinitialize
      validationSchema={null}
      onSubmit={onSubmit}
      {...formProps}
    >
      <div className={styles.title}>{title}</div>
      <MessageField message={error} />
      {roleEntities.map((entity) => (
        <CollapseSelfControlled
          key={entity.name + id}
          triggerClassName={styles.triggerCollapse}
          triggerElement={
            <HeadRow
              entity={entity}
              disabled={isAdminRole}
              handleSelectHeadKeys={handleSelectHeadKeys}
            />
          }
          openDuration={animationDuration}
          hideDuration={animationDuration}
        >
          <FormTable
            options={entity.options}
            permissionAll={entity.permissionAll}
            isAccess={entity.isAccess}
            headKeys={headKeys}
          />
        </CollapseSelfControlled>
      ))}
      <FormButtons className={styles.formButtons}>
        <Button type="reset" appearance="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </FormButtons>
    </Form>
  );
};
