import {
  PIPELINES_PERMISSIONS,
  SUPPRESSION_FILE_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  USERS_PERMISSION,
  PIPELINES_PERMISSION,
  RECORDS_PERMISSIONS,
  DATABASES_PERMISSIONS,
  SUPPRESSION_PERMISSION,
  DATABASES_PERMISSION,
} from "constants/roles.constants";
import { RoleEntitiesType, RoleFormValues } from "./update-roles.types";

export const roleEntities: RoleEntitiesType[] = [
  {
    name: "User Management",
    permissionAll: USERS_PERMISSION.permission,
    options: [
      {
        name: USERS_PERMISSION.name,
        permission: USERS_PERMISSION.permission,
        actions: ["create", "read"],
      },
    ],
  },
  {
    name: "Suppression",
    permissionAll: SUPPRESSION_PERMISSION.permission,
    options: [
      {
        name: SUPPRESSION_PERMISSION.name,
        permission: SUPPRESSION_PERMISSION.permission,
        actions: ["create", "read", "update"],
      },
      {
        name: SUPPRESSION_FILE_PERMISSION.name,
        permission: SUPPRESSION_FILE_PERMISSION.permission,
        actions: ["create"],
      },
      {
        name: SUPPRESSION_LIST_PERMISSION.name,
        permission: SUPPRESSION_LIST_PERMISSION.permission,
        actions: ["create", "read", "update"],
      },
    ],
  },
  {
    name: "Records",
    permissionAll: "RECORDS",
    options: RECORDS_PERMISSIONS.map((recordPermission) => ({
      name: recordPermission.name,
      permission: recordPermission.permission,
      actions: ["read"],
    })),
  },
  {
    name: "Databases",
    permissionAll: DATABASES_PERMISSION.permission,
    isAccess: true,
    options: DATABASES_PERMISSIONS.map((databasePermission) => ({
      name: databasePermission.name,
      permission: databasePermission.permission,
      actions: ["read"],
    })),
  },
  {
    name: "Pipelines",
    permissionAll: PIPELINES_PERMISSION.permission,
    options: PIPELINES_PERMISSIONS.map((pipelinePermission) => ({
      name: pipelinePermission.name,
      permission: pipelinePermission.permission,
      actions: ["create"],
    })),
  },
];

export const allHeadKeys = ["USERS", "SUPPRESSION", "RECORDS", "PIPELINE_DB_ALL", "PIPELINE"];

export const initialValues: RoleFormValues = {
  roles: {},
};

export const animationDuration = 150;

export const PIPELINE_LIST_PERMISSION_KEY = "PIPELINE_LIST_R";
