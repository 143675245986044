import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { xlsxFormat } from "constants/file.constants";
import { ConvertFileData } from "./convert.types";

export const supportedFormats = [xlsxFormat].flat();

export const getInitialData = (item: File): ConvertFileData => ({
  fileName: sanitizeFileName(item.name),
});
