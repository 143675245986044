import { FC, useState } from "react";

import { SliderControllers } from "./slider-controllers/slider-controllers";
import { SliderContent } from "./slider-content/slider-content";

import slide4Img from "assets/images/login-slide-4.svg";
import slide3Img from "assets/images/login-slide-3.svg";
import slide2Img from "assets/images/login-slide-2.svg";
import slide1Img from "assets/images/login-slide-1.svg";
import styles from "./login-slider.module.css";

export type SlideType = {
  id: number;
  description: string;
  img: string;
};

const sliders: SlideType[] = [
  {
    id: 0,
    description: "See all statistic in one place",
    img: slide1Img,
  },
  {
    id: 1,
    description:
      "Perform many functions with files: convert, match, split, deduplicate, suppress. And 10 more available!",
    img: slide2Img,
  },
  {
    id: 2,
    description: "Use database with millions emails",
    img: slide3Img,
  },
  {
    id: 4,
    description: "Export your files in one click",
    img: slide4Img,
  },
];

const LoginSlider: FC = () => {
  const [currentSlide, setCurrentSlide] = useState(sliders[0]);
  const updateCurrentSlide = (newSlide: SlideType) => {
    setCurrentSlide(newSlide);
  };
  return (
    <div className={styles.loginSlider}>
      <SliderContent currentSlide={currentSlide} />
      <SliderControllers
        setCurrentSlide={updateCurrentSlide}
        currentSlide={currentSlide}
        sliders={sliders}
      />
    </div>
  );
};

export { LoginSlider };
