import { FC, useRef, useState } from "react";
import { FormikHelpers, FormikProps } from "formik";
import {
  Button,
  Modal,
  MessageField,
  FormButtons,
  Form,
  useFileParser,
  FormField as UiKitFormField,
} from "@epcnetwork/core-ui-kit";

import { FormField } from "components";
import { DeduplicateFileItem, DeduplicateValues } from "../deduplicate.types";
import { compareOptions, validationSchema } from "../deduplicate.constants";
import { SuppressCheckbox } from "./suppress-checkbox";

import styles from "../deduplicate.module.css";

type FormModalProps = {
  file: DeduplicateFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: DeduplicateFileItem) => void;
};

export const DeduplicateFormModal: FC<FormModalProps> = ({ file, setClose, onSubmitClick }) => {
  const formikRef = useRef<FormikProps<DeduplicateValues> | null>(null);

  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<DeduplicateValues>({
    fileValues: file.data,
  });

  const onSubmit = (
    values: DeduplicateValues,
    { setSubmitting }: FormikHelpers<DeduplicateValues>,
  ) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: { ...file.data, ...values.fileValues },
    });
    setSubmitting(false);
  };

  const emailColumnOption = [{ label: "Email", value: "email" }];

  return (
    <Modal isOpen={!!file} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        <FormField
          type="file-table"
          name="fileValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
          columnOptions={emailColumnOption}
        />
        <div className={styles.compareMode}>
          Deduplication by comparing:
          <UiKitFormField
            type="radio"
            name="fileValues.compareBasedOn"
            options={compareOptions}
            disableError
          />
        </div>

        <div className={styles.actions}>
          <SuppressCheckbox />
          <FormButtons>
            <Button appearance="secondary" onClick={setClose}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </div>
      </Form>
    </Modal>
  );
};
