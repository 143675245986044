import { ApiBuilder } from "@epcnetwork/core-ui-kit";

import store, { setToken } from "store";
import { REFRESH_TOKEN_STORAGE_FIELD, TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { appEnvironment } from "config/environment.config";
import { postRefreshToken } from "./api.refresh-token";

export type ServerErrorTypes =
  | string
  | {
      errors: string[];
      statusCode: number;
    }
  | {
      err: Record<string, string>;
    }
  | {
      message: string;
      statusCode: number;
    }
  | {
      msg: string;
      statusCode: number;
    };

export const apiMiddleware = new ApiBuilder<ServerErrorTypes>({ baseUrl: appEnvironment.apiUrl })
  .setErrorsMapper((error: ServerErrorTypes) => {
    if (typeof error === "string") {
      return error;
    }
    if ("errors" in error && error?.errors?.[0]) {
      return error.errors[0];
    }
    if ("msg" in error && error?.msg) {
      return error.msg;
    }
    if ("message" in error && error?.message) {
      return error.message;
    }
    return JSON.stringify(error);
  })
  .setInterceptors(async (res, instance) => {
    const [, , { status }] = res;
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_FIELD);
    if (refreshToken && status === 401) {
      const [data] = await postRefreshToken.fetch({
        data: {
          refreshToken,
        },
      });

      if (!data) {
        localStorage.removeItem(TOKEN_STORAGE_FIELD);
        localStorage.removeItem(REFRESH_TOKEN_STORAGE_FIELD);
        store.dispatch(setToken(null));
      }

      if (data?.response?.token && data?.response?.refreshToken) {
        store.dispatch(setToken(data.response.token));
        localStorage.setItem(TOKEN_STORAGE_FIELD, data.response.token);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, data.response.refreshToken);
        return instance.setIsRetry(true).fetch();
      }
    }
    return res;
  })
  .setTokenGetter(() => {
    const state = store.getState();
    return state.auth.token;
  })
  .build();
