import { combineReducers, configureStore } from "@reduxjs/toolkit";

import ui from "./reducers/ui";
import auth from "./reducers/auth";

const reducer = combineReducers({
  auth,
  ui,
});

const store = configureStore({
  reducer,
});

export * from "./reducers/auth";
export * from "./reducers/ui";

export type RootState = ReturnType<typeof reducer>;
export default store;
