import { FiltersType } from "types";

export const TABLE_COLUMNS = [
  { label: "Email", queryKey: "email" },
  { label: "Roles", queryKey: "roles" },
  { label: "Group", queryKey: "group" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "" },
];

export const initialQueryParams: FiltersType = {
  limit: "10",
  offset: "0",
  search: "",
};
