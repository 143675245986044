import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import {
  csvFormat,
  docFormat,
  docxFormat,
  gzFormat,
  msgFormat,
  pdfFormat,
  rtfFormat,
  tarFormat,
  txtFormat,
  zipFormat,
} from "constants/file.constants";
import { ExtractFileData } from "./extract.types";

export const supportedFormats = [
  csvFormat,
  txtFormat,
  pdfFormat,
  docFormat,
  docxFormat,
  rtfFormat,
  msgFormat,
  tarFormat,
  gzFormat,
  zipFormat,
].flat();

export const getInitialData = (item: File): ExtractFileData => ({
  fileName: sanitizeFileName(item.name),
  suppress: true,
});
