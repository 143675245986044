import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  FormButtons,
  FormField,
  SelectOption,
  Label,
} from "@epcnetwork/core-ui-kit";

import { InlineSelect } from "components";
import { SplitFileItem, ValuesSize, ValuesLines } from "../split.types";
import {
  validationSchemaLines,
  validationSchemaSize,
  options,
  sizeOptions,
  LINES_OPTION,
} from "../split.constants";

import closeImg from "assets/images/close-default.svg";
import styles from "../split.module.css";

type SplitModalFormProps = {
  file: SplitFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SplitFileItem) => void;
};

export const SplitModalForm: FC<SplitModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const [splitMethod, setSplitMethod] = useState<SelectOption>(
    file.data.lines ? options[0] : options[1],
  );

  const [initialValuesLines] = useState<ValuesLines>({
    lines: file.data.lines || 1,
  });
  const [initialValuesSize] = useState<ValuesSize>({
    batchSize: Number(file.data.batchSize?.slice?.(0, -2) || 1),
    sizeUnit: file.data.batchSize?.slice?.(-2) || sizeOptions[0].value,
  });

  const onSubmitLines = (values: ValuesLines, { setSubmitting }: FormikHelpers<ValuesLines>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: { ...file.data, lines: +values.lines, batchSize: undefined },
    });
    setSubmitting(false);
  };

  const onSubmitSize = (values: ValuesSize, { setSubmitting }: FormikHelpers<ValuesSize>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        batchSize: String(values.batchSize) + values.sizeUnit,
        lines: undefined,
      },
    });
    setSubmitting(false);
  };

  const splitByLines = splitMethod.value === LINES_OPTION.value;

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.portal}>
      <h2>Set values {file.originalFile.name}</h2>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={setClose} width={10} height={10} />
      </div>

      <div className={styles.splitMethodContainer}>
        <InlineSelect
          options={options}
          selected={splitMethod}
          onChange={setSplitMethod}
          label="Split by"
        />
      </div>

      {splitByLines && (
        <Form
          enableReinitialize
          initialValues={initialValuesLines}
          onSubmit={onSubmitLines}
          validationSchema={validationSchemaLines}
        >
          <div className={styles.formWrapper}>
            <FormField type="text" inputMode="numeric" name="lines" label="Values" />
          </div>
          <FormButtons>
            <Button appearance="secondary" onClick={setClose}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      )}
      {!splitByLines && (
        <Form
          enableReinitialize
          initialValues={initialValuesSize}
          onSubmit={onSubmitSize}
          validationSchema={validationSchemaSize}
        >
          <div className={styles.formWrapper}>
            <FormField type="text" inputMode="numeric" name="batchSize" label="Value" />

            <div className={styles.unitRadioWrapper}>
              <Label text="Unit" isInputLabel />

              <FormField
                type="radio"
                name="sizeUnit"
                options={sizeOptions}
                className={styles.radio}
                disableError
              />
            </div>
          </div>
          <FormButtons>
            <Button appearance="secondary" onClick={setClose}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      )}
    </Modal>
  );
};
