import { FC, useMemo, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Modal,
  FormField,
  MessageField,
  FormButtons,
  Button,
  useAutocomplete,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { ListModel } from "models";
import { useUser } from "hooks";
import { FormField as LocalFormField } from "components";
import { getLists } from "api";
import { SuppressionImportItem, SuppressionImportValues } from "../suppression-import.types";
import { validationSchema } from "../suppression-import.constants";
import {
  suppressionCategoryOptions,
  suppressionEspOptions,
  suppressionStatusOptions,
  suppressionTypeOptions,
} from "../../list/suppression-list.constants";

import styles from "../suppression-import.module.css";

type FormModalProps = {
  file: SuppressionImportItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SuppressionImportItem) => void;
};

export const SuppressionImportFormModal: FC<FormModalProps> = ({
  file,
  setClose,
  onSubmitClick,
}) => {
  const { isAdmin } = useUser();
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [selectedLists, setSelectedLists] = useState<ListModel[]>([]);
  const [initialValues] = useState<SuppressionImportValues>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      emailIndex: file.data.emailIndex,
      headers: file.data.headers,
    },
    categories: file.data.categories,
    status: file.data.status || undefined,
    esps: file.data.esps,
    listIds: file.data.listIds,
    type: file.data.type,
  });

  const listTypeAutocomplete = useAutocomplete(getLists, "id", "name");

  const { isOptionSelected, isFirstListGlobal } = useMemo(() => {
    const isOptionSelected = selectedLists.length > 0;
    const isFirstListGlobal = isOptionSelected && selectedLists[0].isGlobal;

    return { isOptionSelected, isFirstListGlobal };
  }, [selectedLists]);

  const mapListOptions = (
    options: (typeof listTypeAutocomplete)["fetchOptions"],
  ): (typeof listTypeAutocomplete)["fetchOptions"] => {
    const onlyLocalEnabled = options.map((option) =>
      option.isGlobal ? { ...option, locked: true } : option,
    );
    const onlyGlobalEnabled = options.map((option) =>
      option.isGlobal ? option : { ...option, locked: true },
    );

    if (!isAdmin) return onlyLocalEnabled;
    if (!isOptionSelected) return options;
    if (isFirstListGlobal) return onlyGlobalEnabled;
    return onlyLocalEnabled;
  };

  const onSubmit = (
    values: SuppressionImportValues,
    { setSubmitting }: FormikHelpers<SuppressionImportValues>,
  ) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        categories: values.categories,
        status: values.status,
        esps: values.esps,
        listIds: values.listIds,
        type: values.type,
      },
    });
    setSubmitting(false);
  };

  const getListsLabel = () => {
    if (!isOptionSelected) return "Lists";
    if (isFirstListGlobal) return "Lists (global)";
    if (!isFirstListGlobal) return "Lists (local)";
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        {({ isValid }) => {
          return (
            <>
              <div className={styles.row}>
                <FormField
                  type="select"
                  name="type"
                  label="Type"
                  placeholder="Choose type from the list"
                  options={suppressionTypeOptions}
                  isSearchable={false}
                  required
                />
                <FormField
                  type="select"
                  name="categories"
                  label="Categories"
                  placeholder="Choose category(ies) from the list"
                  options={suppressionCategoryOptions}
                  isSearchable={false}
                  isMulti
                />
                <FormField
                  type="select"
                  name="esps"
                  label="Email Service Providers"
                  placeholder="Choose ESP(s) from the list"
                  options={suppressionEspOptions}
                  isSearchable={false}
                  isMulti
                />
                <FormField
                  type="select"
                  name="listIds"
                  label={getListsLabel()}
                  placeholder="Select from the lists"
                  asyncOptions={{
                    ...listTypeAutocomplete,
                    fetchOptions: mapListOptions(listTypeAutocomplete.fetchOptions),
                  }}
                  onChange={setSelectedLists}
                  isMulti
                  isSearchable
                  required
                />
              </div>
              <FormField
                type="select"
                name="status"
                label="Email list verification service status"
                placeholder="Choose ELVSS from the list"
                options={suppressionStatusOptions}
                isSearchable={false}
              />
              <LocalFormField
                type="file-table"
                name="headerValues"
                label="Select email column"
                parsedFile={parsedFile}
                loading={loading}
              />
              <FormButtons>
                <Button appearance="secondary" onClick={setClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Set values
                </Button>
              </FormButtons>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
