import { Dispatch, ReactElement, SetStateAction } from "react";
import { FileQueueItem, Switch, Tooltip, useFileQueue } from "@epcnetwork/core-ui-kit";

import { applyBtnText, applyTooltipText } from "./file-preset-switch.constants";

import styles from "./file-preset-switch.module.css";

type FilePresetSwitchProps<T extends FileQueueItem> = {
  isAppliedToAll: boolean;
  files: T[];
  updateFiles: ReturnType<typeof useFileQueue>["updateFiles"];
  setIsAppliedToAll: Dispatch<SetStateAction<boolean>>;
  presetFile: T;
};
const FilePresetSwitch = <T extends FileQueueItem>({
  isAppliedToAll,
  presetFile,
  files,
  updateFiles,
  setIsAppliedToAll,
}: FilePresetSwitchProps<T>): ReactElement => {
  const handleSwitchToggle = (_: unknown, value: boolean) => {
    setIsAppliedToAll(value);
    if (value) {
      return updateFiles(
        files.map((file) => ({
          id: file.id,
          file: {
            isTuned: presetFile.isTuned,
            data: {
              ...presetFile.data,
              fileName: file.data.fileName,
            },
          },
        })),
      );
    }
  };

  return (
    <div className={styles.applyAllControl}>
      <Switch
        value="used-files"
        checked={isAppliedToAll}
        onChange={handleSwitchToggle}
        disableError
        inputSize="small"
      />
      <Tooltip
        triggerElement={() => <span className={styles.applyAllText}>{applyBtnText}</span>}
        position="bottom"
        contentClassName={styles.contentTooltip}
        trigger="hover"
      >
        <p className={styles.tooltip}>{applyTooltipText}</p>
      </Tooltip>
    </div>
  );
};

export { FilePresetSwitch };
