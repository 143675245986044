import { number, object, SchemaOf, string } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { minOneFieldText, requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { ValuesLines, ValuesSize, SplitFileDataSize, SplitFileDataLines } from "./split.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const validationSchemaLines: SchemaOf<ValuesLines> = object({
  lines: number()
    .min(1, minOneFieldText)
    .integer("Value should be an integer number.")
    .required(requiredFieldText)
    .typeError("This value should be a number"),
});

export const validationSchemaSize: SchemaOf<ValuesSize> = object({
  batchSize: number()
    .min(1, minOneFieldText)
    .required(requiredFieldText)
    .typeError("This value should be a number"),
  sizeUnit: string().required(requiredFieldText),
});

export const LINES_OPTION = { value: "lines", label: "Lines number" };
export const SIZE_OPTION = { value: "size", label: "File size" };

export const options = [LINES_OPTION, SIZE_OPTION];

export const sizeOptions = [
  { value: "GB", label: "GB" },
  { value: "MB", label: "MB" },
  { value: "KB", label: "KB" },
];

export const getInitialData = (item: File): SplitFileDataSize | SplitFileDataLines => ({
  fileName: sanitizeFileName(item.name),
});
