import { ActiveShape } from "recharts/types/util/types";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import { PieChart as RechartsPieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import React from "react";

import { SummaryMetricsResponse } from "api/pipelines/pipelines.interface";
import { mapPipelineResponse } from "../../stats.constants";

interface Props {
  data: SummaryMetricsResponse[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

export const PieChart: React.FC<Props> = ({ data, activeIndex, setActiveIndex }) => {
  const renderActiveShape = (props: ActiveShape<PieSectorDataItem>) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      // eslint-disable-next-line
    } = props as any;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={-18} textAnchor={textAnchor} fill="#333">
          {payload.label}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const onPieEnter = (_: Record<string, unknown>, index: number) => {
    setActiveIndex(index);
  };

  return (
    <div>
      <ResponsiveContainer width="99%" height={300}>
        <RechartsPieChart width={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={mapPipelineResponse(data)}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  );
};
