import React from "react";
import { useFormikContext } from "formik";
import { Chip } from "@epcnetwork/core-ui-kit";

import { WarmUpValues } from "pages/pipelines/forms/warm-up/warm-up.types";
import { PipelinesDomain } from "api/pipelines/pipelines.interface";

import styles from "../warm-up-form-modal.module.css";

interface Props {
  total: number;
  domains: PipelinesDomain[];
}

export const TotalEmails: React.FC<Props> = ({ total, domains }) => {
  const { values } = useFormikContext<WarmUpValues>();

  const getRecordsCount = (index: number, initialValue = 0) => {
    const days = getDaysFields(index + 1);

    return days.reduce((acc, curr) => {
      if (curr) {
        acc *= 2;
      }
      return acc;
    }, initialValue);
  };

  const getTypeNumberOfEmails = (numberOfEmails: number) => {
    const initialEmailsNumber = values.initialEmailsNumber || 100;
    const type = values.type || 1;

    if (type === 2) {
      return Math.floor((Number(initialEmailsNumber) * numberOfEmails) / 100);
    }
    return numberOfEmails;
  };

  const getDayEmailsSpread = (initialCount: number, index: number) => {
    const days = getDaysFields(index + 1);

    return days.reduce((acc, index) => {
      if (index === 0) {
        acc = initialCount;
      } else {
        acc *= 2;
      }
      return acc;
    }, 0);
  };

  const getFullSpread = (initialCount: number, index: number) => {
    const days = getDaysFields(index + 1);

    return days.reduce((acc, index) => {
      acc += getDayEmailsSpread(initialCount, index);
      return acc;
    }, 0);
  };

  const getEmailsSpread = (initialValue: number, maxSpread: number, index = 0): number => {
    if (initialValue <= 0) return 0;

    const currentSpread = getTypeNumberOfEmails(getDayEmailsSpread(initialValue, index));
    const fullSpread = getTypeNumberOfEmails(getFullSpread(initialValue, index - 1));
    const possibleSpread = Math.max(0, maxSpread - fullSpread);

    if (fullSpread + currentSpread > maxSpread) {
      return possibleSpread;
    }
    return currentSpread;
  };

  const getDomainsFields = (value: number) => {
    if (Number(value) < 0) {
      return Array.from(Array(1).keys());
    }
    return Array.from(Array(Number(value)).keys());
  };

  const getDaysFields = (value: number) => getDomainsFields(value);

  const getCountByDomain = (domain: string | undefined): number => {
    const currDomain = domains.find((d) => d.key === domain);

    return currDomain?.amount || 0;
  };

  const getDays = (
    daysNumber: WarmUpValues["daysNumber"],
    domainsEmailsSpread: WarmUpValues["domainsEmailsSpread"],
  ) => {
    return getDaysFields(Number(daysNumber)).map((index) => {
      const daySpread = domainsEmailsSpread.map((spread) => ({
        numberOfEmails: getEmailsSpread(
          Number(spread.numberOfEmails || 0),
          getCountByDomain(spread.domain),
          index,
        ),
        domain: spread.domain,
      }));
      return { data: daySpread };
    });
  };

  return (
    <>
      <div>Total emails: {total}</div>
      <div className={styles.preview}>
        {getDays(values.daysNumber, values.domainsEmailsSpread).map((day, index) => (
          <div key={index} className={styles.day}>
            <span>Day{index + 1}</span> -{" "}
            {getRecordsCount(index, Number(values.initialEmailsNumber))} records of
            <div className={styles.chips}>
              {day.data.map((domain) => (
                <Chip key={domain.domain} text={`${domain.domain}: ${domain.numberOfEmails}`} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
