import { FC, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import cn from "classnames";
import { Search, Container, ArrayElement, NoContent } from "@epcnetwork/core-ui-kit";

import { useAuthorization } from "hooks";
import { PIPELINES_LIST } from "constants/pipelines.constants";
import { PipelineWrapper } from "./components/pipeline-wrapper/pipeline-wrapper";

import styles from "./pipelines-list.module.css";

const PipelinesListPage: FC = () => {
  const ability = useAuthorization();

  const [searchValue, setSearch] = useState("");

  const filteredList = useMemo(() => {
    let list = PIPELINES_LIST.filter((pipeline) => ability.can("create", pipeline.authEntity));

    if (searchValue) {
      const keys: Array<keyof ArrayElement<typeof PIPELINES_LIST>> = ["title", "subtitle"];
      list = matchSorter(list, searchValue, { keys });
    }

    return list;
  }, [ability, searchValue]);

  const pipelinesWithoutDB = filteredList?.filter((list) => !list.db);
  const pipelinesWithDB = filteredList?.filter((list) => list.db);

  return (
    <Container>
      <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />
      {!pipelinesWithoutDB.length && !pipelinesWithDB.length && (
        <div
          className={cn(styles.wrapper, {
            [styles.noContentList]: !pipelinesWithoutDB.length && !pipelinesWithDB.length,
          })}
        >
          <NoContent size="medium" subtitle="List in empty" showButton={false} />
        </div>
      )}

      {!!pipelinesWithoutDB.length && (
        <PipelineWrapper
          list={pipelinesWithoutDB}
          title="Expeditious pipelines"
          subtitle={
            <>
              These pipelines do not use the database. <br /> More jobs can be processed{" "}
              <b>concurrently</b>.
            </>
          }
        />
      )}

      {!!pipelinesWithDB.length && (
        <PipelineWrapper
          list={pipelinesWithDB}
          title="Lengthy process pipelines"
          subtitle={
            <>
              These pipelines use database. Files processing can <b>take some time</b>
              ,
              <br /> and files
              <b>&nbsp;can be queued</b> in case of uploading more than 1 file at a time.
            </>
          }
        />
      )}
    </Container>
  );
};

export { PipelinesListPage };
