import { array, boolean, object, SchemaOf, string } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { emailColumnValidation, headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { SuppressionFileData, SuppressionValues } from "./suppression.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const validationSchema: SchemaOf<SuppressionValues> = object({
  headerValues: object({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
  listIds: array().of(string().required()).notRequired(),
});

export const getInitialData = (item: File): SuppressionFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  emailIndex: -1,
});
