import React from "react";
import { useFormikContext } from "formik";
import { FormField, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { DeduplicateValues } from "../deduplicate.types";

export const SuppressCheckbox: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<DeduplicateValues>();

  useDidUpdate(
    () => {
      const { fileValues } = values;

      const emailColumnSelected = fileValues.headers.includes("email");
      if (emailColumnSelected) {
        setFieldValue("fileValues.suppress", true);
      }
    },
    [values.fileValues.headers],
    true,
  );

  useDidUpdate(
    () => {
      const { fileValues } = values;

      const emailColumnSelected = fileValues.headers.includes("email");
      const basedOnRows = fileValues.compareBasedOn === "rows";

      if (basedOnRows && !emailColumnSelected) {
        setFieldValue("fileValues.suppress", false);
      }
    },
    [values.fileValues.headers, values.fileValues.compareBasedOn],
    true,
  );

  const emailColumnSelected = values.fileValues.headers.includes("email");
  return (
    <FormField
      type="checkbox"
      name="fileValues.suppress"
      label={suppressionCheckboxLabel}
      disabled={!emailColumnSelected}
      disableError
    />
  );
};
