import { FC, useMemo, useRef, useState } from "react";
import {
  MessageField,
  NoContent,
  Switch,
  useDidUpdate,
  SelectOption,
} from "@epcnetwork/core-ui-kit";

import { isValidEmail } from "utils";
import { FormFileTableHeaders } from "types";
import { ColumnSelects } from "./components/column-selects";

import globalStyles from "assets/styles/global.module.css";
import styles from "./file-table.module.css";

export type FileTableProps = {
  onChange?: (value: FormFileTableHeaders) => void;
  value?: FormFileTableHeaders | null;
  parsedFile: Array<Array<string>>;
  label?: string;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  selector?: "select" | "checkbox" | "radio";
  isEmailRequired?: boolean;
  showHeadersSwitch?: boolean;
  autocompleteColumns?: boolean;
  status?: "default" | "error" | "warning" | "success";
  columnOptions?: SelectOption<string>[];
};

const EMAIL_COLUMN_NAME = "Email";

const createInitialArray = (arrayLength: number): SelectOption<string>[] =>
  Array.from({ length: arrayLength }, () => ({ value: "", label: "" }));

const initialValue: FormFileTableHeaders = {
  emailIndex: -1,
  headers: [],
  hasHeaders: false,
};

const getFirstRow = (parsedFile: string[][]): string[] =>
  parsedFile.find((row) => !!row[0].length) || [];

export const FileTable: FC<FileTableProps> = ({
  value,
  parsedFile,
  onChange,
  label = "Set column(s) data type(s)",
  error = "",
  autocompleteColumns = true,
  disabled = false,
  status = "default",
  loading = false,
  showHeadersSwitch = true,
  selector = "select",
  columnOptions,
}) => {
  const initialHeaders = useRef(value?.headers || initialValue.headers);

  const [hasHeaders, setHasHeaders] = useState(Boolean(value?.hasHeaders));
  const [headersData, setHeadersData] = useState<FormFileTableHeaders>(value || initialValue);

  const columnsAmount = parsedFile?.[0]?.length || 0;
  const firstRow = getFirstRow(parsedFile);

  const handleSelectHeaders = (headerValues: SelectOption<string>[], hasHeadersValue: boolean) => {
    const newData: FormFileTableHeaders = {
      ...value,
      emailIndex: -1,
      headers: headerValues
        .map((header) => (!header ? { value: "", label: "" } : header))
        .map((header) => header.value),
      hasHeaders: hasHeadersValue,
    };

    const emailIndex = headerValues.findIndex((header) => header?.label?.toUpperCase() === "EMAIL");

    if (emailIndex !== -1) {
      newData.emailIndex = emailIndex;
    }

    setHeadersData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const setFirstRowAsHeaders = () => {
    let hasEmail = false;

    const headerValues = firstRow.map((column) => {
      if (!hasEmail && column.toUpperCase() === EMAIL_COLUMN_NAME.toUpperCase()) {
        hasEmail = true;
        return { value: column.toLowerCase(), label: EMAIL_COLUMN_NAME };
      }

      return { value: column.toLowerCase(), label: column };
    });

    return headerValues;
  };

  useDidUpdate(() => {
    const isInitialRender = !initialHeaders.current.length;

    if (isInitialRender) {
      // headers have not been set yet
      const hasEmailInFirstRow = firstRow.some((column) => isValidEmail(column));

      if (!hasEmailInFirstRow) {
        // set first row as a header
        setHasHeaders(true);

        const headerValues = setFirstRowAsHeaders();
        handleSelectHeaders(headerValues, true);
      } else {
        if (columnsAmount === 1) {
          // only one column exists, we can assume this is an email column
          const emailHeader = [{ value: "email", label: EMAIL_COLUMN_NAME }];
          handleSelectHeaders(emailHeader, false);
        } else {
          // prepare headers for all columns
          handleSelectHeaders(createInitialArray(columnsAmount), false);
        }
      }
    }
  }, [parsedFile]);

  const handleSwitchToggle = (_: unknown, value: boolean) => {
    setHasHeaders(value);

    if (value && autocompleteColumns) {
      const headerValues = setFirstRowAsHeaders();

      handleSelectHeaders(headerValues, value);
    } else if (columnsAmount) {
      handleSelectHeaders(createInitialArray(columnsAmount), value);
    }
  };

  const fileToRender = useMemo(() => {
    if (hasHeaders && selector === "checkbox") return parsedFile.slice(1);
    return parsedFile;
  }, [parsedFile, selector, hasHeaders]);

  return (
    <div className={styles.itemModal}>
      <div className={styles.itemModalContentWrap}>
        <div className={styles.itemModalTableHeaderWrap}>
          <div className={`${styles.itemModalTableHeaderHint} ${styles.itemModalWrapTitle}`}>
            {label}
          </div>
          {Boolean(parsedFile.length) && showHeadersSwitch && (
            <div className={styles.itemModalTableHasHeaderWrap}>
              Has header
              <Switch
                value="switch"
                checked={hasHeaders}
                onChange={handleSwitchToggle}
                inputSize="small"
                disabled={disabled}
                disableError
              />
              <span>{hasHeaders ? "Yes" : "No"}</span>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${styles.itemModalContentGridContainer} ${styles.hasItems} ${styles[status]}`}
      >
        <div className={`${styles.itemModalContentGrid} ${globalStyles.addScrollStyles}`}>
          <ColumnSelects
            firstRow={firstRow}
            columnsAmount={columnsAmount}
            hasHeaders={hasHeaders}
            headers={headersData.headers}
            setHeaders={handleSelectHeaders}
            disabled={disabled}
            selector={selector}
            columnOptions={columnOptions}
          />
          {fileToRender.map((row, rowIndex) => {
            const isHeaderRow = hasHeaders && rowIndex === 0 && selector !== "checkbox";

            return (
              <div
                key={rowIndex}
                className={`${styles.itemModalGridRow} ${isHeaderRow ? styles.headerRow : ""}`}
              >
                {row.map((column, columnIndex) => (
                  <div className={styles.itemModalGridCell} key={columnIndex}>
                    <span className={styles.itemModalGridCellText}>{column}</span>
                  </div>
                ))}
              </div>
            );
          })}
          <div className={styles.gridScrollBGRow} />
          {!loading && !parsedFile.length && (
            <div className={styles.noContent}>
              <NoContent
                title="File is empty"
                subtitle="There is no preview after file parsing"
                size="medium"
                showButton={false}
              />
            </div>
          )}
          {loading && <div className={styles.noContent}>Loading file preview...</div>}
        </div>
      </div>
      <MessageField message={error} />
    </div>
  );
};
