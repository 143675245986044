import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { Statuses, FiltersQueryType, TableColumnsType } from "./jobs-list.types";

export const tableColumns: TableColumnsType[] = [
  { label: "Input File Name(s)", queryKey: "filename" },
  { label: "Pipeline", queryKey: "pipelineName" },
  { label: "Status", queryKey: "status" },
  { label: "Started at", queryKey: "startedAt" },
  { label: "User", queryKey: "user" },
  { label: "Group", queryKey: "group" },
  { label: "Databases", queryKey: "databases" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "" }, // status column
  { label: "" }, // menu column
];

export const tabStatesMap: Record<number, Statuses> = {
  0: "",
  1: "done",
  2: "in progress",
  3: "queued",
  4: "failed",
};

export const queryKeys: string[] = tableColumns.map(({ queryKey }) => `order[${queryKey}]`);

export const initialFilters: UseFiltersProps<FiltersQueryType> = {
  initialState: {
    search: "",
    status: "",
    offset: 0,
  },
};
