import { FC } from "react";
import { FormField } from "@epcnetwork/core-ui-kit";

import { hashOptions } from "../reverse.constants";

export const HashTypes: FC = () => {
  return (
    <>
      <FormField type="radio" name="fileValues.hashType" options={hashOptions} disableError />
      <br />
    </>
  );
};
