import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FC } from "react";
import { useDidUpdate } from "@epcnetwork/core-ui-kit";

import { AuthorizationEntityNameType, AuthorizationActionType } from "types";
import { RootState } from "store";
import { UsersListPage } from "pages/users/list";
import { SuppressionListsPage } from "pages/suppression-lists/list";
import { SuppressionListPage } from "pages/suppression/list";
import { SuppressionImportPage } from "pages/suppression/import";
import { StatsPage } from "pages/stats/stats.page";
import { PipelinesListPage } from "pages/pipelines/list";
import { JobListPage } from "pages/jobs/list";
import { JobDetailsPage } from "pages/jobs/details";
import { Logout } from "pages/auth/logout";
import { LoginPage } from "pages/auth/login";
import { SendEmail, ResetPassword } from "pages/auth/forgot-password";
import { ActivityListPage } from "pages/activity/list";
import { MyAccountPage } from "pages/account/my-account";
import { NotFoundPage } from "pages/404";
import { useAuthorization } from "hooks";
import {
  LOGIN_PAGE,
  LOGOUT_PAGE,
  FORGOT_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  NOT_FOUND_PAGE,
  DEFAULT_PAGE,
  DASHBOARD_PAGE,
  JOBS_LIST_PAGE,
  JOBS_DETAILS_PAGE,
  USERS_LIST_PAGE,
  STATS_PAGE,
  MY_ACCOUNT_PAGE,
  ACTIVITY_LIST_PAGE,
  SUPPRESSION_LIST_PAGE,
  SUPPRESSION_IMPORT_PAGE,
  SUPPRESSION_SETTINGS_LIST_PAGE,
  PIPELINES_LIST_PAGE,
} from "constants/routes.constants";
import {
  JOBS_PERMISSION,
  USERS_PERMISSION,
  SUPPRESSION_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  SUPPRESSION_FILE_PERMISSION,
  PIPELINES_LIST_PERMISSION,
  STATS_PERMISSION,
} from "constants/roles.constants";
import { PIPELINES_LIST } from "constants/pipelines.constants";
import { TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { Navigation } from "components";

export interface RouteConfig {
  path: string;
  element: FC;
  name: string;
  exact: boolean;
  auth: boolean;
  showNavigation: boolean;
  authEntity?: AuthorizationEntityNameType;
  authAction?: AuthorizationActionType;
}

export const PageRoute: FC<RouteConfig> = ({
  element,
  showNavigation,
  authEntity,
  authAction,
  ...route
}) => {
  const navigate = useNavigate();
  const ability = useAuthorization();

  const { user } = useSelector((state: RootState) => state.auth);
  const isValidRoute = localStorage.getItem(TOKEN_STORAGE_FIELD) && user;

  const isAuthorized =
    route.auth && authEntity ? ability.can(authAction || "read", authEntity) : true;

  useDidUpdate(
    () => {
      if (route.auth && !isValidRoute) {
        navigate("/login");
      }
    },
    [route.auth, isValidRoute],
    true,
  );

  return (
    <Navigation
      element={element}
      showNavigation={showNavigation}
      isAuthorized={isAuthorized}
      {...route}
    />
  );
};

const pipelinesRoutes: RouteConfig[] = PIPELINES_LIST?.map((pipeline) => ({
  path: pipeline.path,
  element: pipeline.element,
  name: pipeline.title,
  authAction: "create",
  authEntity: pipeline.authEntity,
  exact: true,
  auth: true,
  showNavigation: true,
}));

export const routes: RouteConfig[] = [
  {
    path: LOGIN_PAGE.path,
    element: LoginPage,
    name: LOGIN_PAGE.name,
    exact: LOGIN_PAGE.exact,
    auth: LOGIN_PAGE.auth,
    showNavigation: LOGIN_PAGE.showNavigation,
  },
  {
    path: LOGOUT_PAGE.path,
    element: Logout,
    name: LOGOUT_PAGE.name,
    exact: LOGOUT_PAGE.exact,
    auth: LOGOUT_PAGE.auth,
    showNavigation: LOGOUT_PAGE.showNavigation,
  },
  {
    path: FORGOT_PASSWORD_PAGE.path,
    element: SendEmail,
    name: FORGOT_PASSWORD_PAGE.name,
    exact: FORGOT_PASSWORD_PAGE.exact,
    auth: FORGOT_PASSWORD_PAGE.auth,
    showNavigation: FORGOT_PASSWORD_PAGE.showNavigation,
  },
  {
    path: RESET_PASSWORD_PAGE.path,
    element: ResetPassword,
    name: RESET_PASSWORD_PAGE.name,
    exact: RESET_PASSWORD_PAGE.exact,
    auth: RESET_PASSWORD_PAGE.auth,
    showNavigation: RESET_PASSWORD_PAGE.showNavigation,
  },
  {
    path: DEFAULT_PAGE.path,
    element: JobListPage,
    name: JOBS_LIST_PAGE.name,
    exact: JOBS_LIST_PAGE.exact,
    auth: JOBS_LIST_PAGE.auth,
    showNavigation: JOBS_LIST_PAGE.showNavigation,
  },
  {
    path: DASHBOARD_PAGE.path,
    element: JobListPage,
    name: JOBS_LIST_PAGE.name,
    exact: JOBS_LIST_PAGE.exact,
    auth: JOBS_LIST_PAGE.auth,
    showNavigation: JOBS_LIST_PAGE.showNavigation,
  },
  {
    path: JOBS_LIST_PAGE.path,
    element: JobListPage,
    name: JOBS_LIST_PAGE.name,
    exact: JOBS_LIST_PAGE.exact,
    auth: JOBS_LIST_PAGE.auth,
    showNavigation: JOBS_LIST_PAGE.showNavigation,
    authAction: "read",
    authEntity: JOBS_PERMISSION.name,
  },
  {
    path: JOBS_DETAILS_PAGE.path,
    element: JobDetailsPage,
    name: JOBS_DETAILS_PAGE.name,
    exact: JOBS_DETAILS_PAGE.exact,
    auth: JOBS_DETAILS_PAGE.auth,
    showNavigation: JOBS_DETAILS_PAGE.showNavigation,
    authAction: "read",
    authEntity: JOBS_PERMISSION.name,
  },
  {
    path: STATS_PAGE.path,
    element: StatsPage,
    name: STATS_PAGE.name,
    exact: STATS_PAGE.exact,
    auth: STATS_PAGE.auth,
    showNavigation: STATS_PAGE.showNavigation,
    authAction: "read",
    authEntity: STATS_PERMISSION.name,
  },
  {
    path: USERS_LIST_PAGE.path,
    element: UsersListPage,
    name: USERS_LIST_PAGE.name,
    exact: USERS_LIST_PAGE.exact,
    auth: USERS_LIST_PAGE.auth,
    showNavigation: USERS_LIST_PAGE.showNavigation,
    authAction: "read",
    authEntity: USERS_PERMISSION.name,
  },
  {
    path: ACTIVITY_LIST_PAGE.path,
    element: ActivityListPage,
    name: ACTIVITY_LIST_PAGE.name,
    exact: ACTIVITY_LIST_PAGE.exact,
    auth: ACTIVITY_LIST_PAGE.auth,
    showNavigation: ACTIVITY_LIST_PAGE.showNavigation,
  },
  {
    path: MY_ACCOUNT_PAGE.path,
    element: MyAccountPage,
    name: MY_ACCOUNT_PAGE.name,
    exact: MY_ACCOUNT_PAGE.exact,
    auth: MY_ACCOUNT_PAGE.auth,
    showNavigation: MY_ACCOUNT_PAGE.showNavigation,
  },
  {
    path: SUPPRESSION_LIST_PAGE.path,
    element: SuppressionListPage,
    name: SUPPRESSION_LIST_PAGE.name,
    exact: SUPPRESSION_LIST_PAGE.exact,
    auth: SUPPRESSION_LIST_PAGE.auth,
    showNavigation: SUPPRESSION_LIST_PAGE.showNavigation,
    authAction: "read",
    authEntity: SUPPRESSION_PERMISSION.name,
  },
  {
    path: SUPPRESSION_IMPORT_PAGE.path,
    element: SuppressionImportPage,
    name: SUPPRESSION_IMPORT_PAGE.name,
    exact: SUPPRESSION_IMPORT_PAGE.exact,
    auth: SUPPRESSION_IMPORT_PAGE.auth,
    showNavigation: SUPPRESSION_IMPORT_PAGE.showNavigation,
    authAction: "create",
    authEntity: SUPPRESSION_FILE_PERMISSION.name,
  },
  {
    path: SUPPRESSION_SETTINGS_LIST_PAGE.path,
    element: SuppressionListsPage,
    name: SUPPRESSION_SETTINGS_LIST_PAGE.name,
    exact: SUPPRESSION_SETTINGS_LIST_PAGE.exact,
    auth: SUPPRESSION_SETTINGS_LIST_PAGE.auth,
    showNavigation: SUPPRESSION_SETTINGS_LIST_PAGE.showNavigation,
    authAction: "read",
    authEntity: SUPPRESSION_LIST_PERMISSION.name,
  },
  {
    path: PIPELINES_LIST_PAGE.path,
    element: PipelinesListPage,
    name: PIPELINES_LIST_PAGE.name,
    exact: PIPELINES_LIST_PAGE.exact,
    auth: PIPELINES_LIST_PAGE.auth,
    showNavigation: PIPELINES_LIST_PAGE.showNavigation,
    authAction: "read",
    authEntity: PIPELINES_LIST_PERMISSION.name,
  },
  ...(pipelinesRoutes ?? []),
  {
    path: NOT_FOUND_PAGE.path,
    element: NotFoundPage,
    name: NOT_FOUND_PAGE.name,
    exact: NOT_FOUND_PAGE.exact,
    auth: NOT_FOUND_PAGE.auth,
    showNavigation: NOT_FOUND_PAGE.showNavigation,
  },
];
