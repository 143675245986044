import { format } from "date-fns";
import { ChipColorKeys, isDeepEmpty } from "@epcnetwork/core-ui-kit";

import {
  SuppressionCategory,
  SuppressionCategoryOption,
  SuppressionEsp,
  SuppressionEspOption,
  SuppressionModel,
  SuppressionStatus,
  SuppressionStatusOption,
  SuppressionTypeOption,
} from "models";
import { QueryParams, FiltersType, AppearanceMap } from "./suppression-list.types";

export const tableColumns = [
  { label: "Value", queryKey: "value" },
  { label: "Type", queryKey: "type" },
  { label: "Lists" },
  { label: "Source" },
  { label: "Categories", queryKey: "categories" },
  { label: "Esp", queryKey: "esps" },
  { label: "Kind" },
  { label: "Status", queryKey: "status" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "" },
];

export const suppressionTypeOptions: SuppressionTypeOption[] = [
  { value: "Email", label: "Email" },
  { value: "Domain", label: "Domain" },
  { value: "Keyword", label: "Keyword" },
  { value: "Regex", label: "Regex" },
];

export const suppressionCategoryOptions: SuppressionCategoryOption[] = [
  { value: "Catch All", label: "Catch All" },
  { value: "Hard Bounce", label: "Hard Bounce" },
  { value: "Complainer", label: "Complainer" },
  { value: "Click Bots", label: "Click Bots" },
  { value: "Plus sign alias", label: "Plus Sign Alias" },
  { value: "Supersup", label: "Supersup" },
];

export const suppressionStatusOptions: SuppressionStatusOption[] = [
  { value: "Valid", label: "Valid" },
  { value: "Bounce", label: "Bounce" },
  { value: "Discretionary", label: "Discretionary" },
  { value: "Invalid", label: "Invalid" },
  { value: "Threat", label: "Threat" },
];

export const suppressionEspOptions: SuppressionEspOption[] = [
  { value: "Iterable", label: "Iterable" },
  { value: "Maropost", label: "Maropost" },
  { value: "Omnisend", label: "Omnisend" },
  { value: "Bronto", label: "Bronto" },
  { value: "Blueshift", label: "Blueshift" },
  { value: "Remarkety", label: "Remarkety" },
  { value: "Raze", label: "Raze" },
  { value: "Exact-target", label: "Exact Target" },
  { value: "REST API", label: "REST API" },
];

export const suppressionKindOptions = [
  { label: "Global", value: "true" },
  { label: "Local", value: "false" },
];

export const queryKeys = tableColumns.map(({ queryKey }) => `order[${queryKey}]`);
export const nonClearableKeys: string[] | Array<keyof FiltersType> = ["value"].concat(queryKeys);

export const initialQueryParams: QueryParams = {
  limit: 10,
  offset: 0,
};

const chipAppearance: AppearanceMap = {
  Valid: "success",
  Bounce: "warning",
  Discretionary: "warning",
  Invalid: "error",
  Threat: "error",
};

export const getCategories = (categories: SuppressionCategory[]): string => {
  if (isDeepEmpty(categories) || !categories.length) return "";

  const findFromOptions = (category: SuppressionCategory) =>
    suppressionCategoryOptions.find((item) => item.value === category)?.label;

  const categoriesNames = categories.map((category) => findFromOptions(category));
  return categoriesNames.filter(Boolean).join("; ");
};

export const getEsp = (esps: SuppressionEsp[]): string => {
  if (isDeepEmpty(esps) || !esps.length) return "";

  const findFromOptions = (esp: SuppressionEsp) =>
    suppressionEspOptions.find((item) => item.value === esp)?.label;

  const espsNames = esps.map((esp) => findFromOptions(esp));
  return espsNames.filter(Boolean).join("; ");
};

export const getListNames = (lists: SuppressionModel["lists"]): string => {
  if (isDeepEmpty(lists) || !lists.length) return "";

  return lists.map((list) => list.name).join("; ");
};

export const getSuppressionListKind = (isGlobal: boolean | undefined): string => {
  return isGlobal ? "Global" : "Local";
};

export const getSuppressionKind = (listType: SuppressionModel["lists"]): string => {
  if (isDeepEmpty(listType) || !listType.length) return "";

  const kind = listType.map((list) => getSuppressionListKind(list.isGlobal));
  return [...new Set(kind)].join("; ");
};

export const getStatusAppearance = (status: SuppressionStatus): ChipColorKeys => {
  return chipAppearance[status] ?? "primary";
};

export const getFileName = (): string => {
  const date = new Date();
  const formattedDate = format(date, "MM-dd-yyyy-HH-mm-s");
  return `suppression-${formattedDate}.csv`;
};
