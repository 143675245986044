import { List } from "@epcnetwork/core-ui-kit";

import { JobModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { ListAdditionalData } from "./jobs.types";

export const getJobs = apiMiddleware<List<JobModel> & ListAdditionalData>()({
  method: "get",
  endpoint: "/pipeline/jobs",
});

export const getJobFile = apiMiddleware<{ downloadUrl: string }, { fileName: string }>()({
  method: "post",
  endpoint: "/pipeline/signed-url",
});

export const deleteJob = apiMiddleware<{ downloadUrl: string }>()({
  method: "delete",
  endpoint: "/pipeline/jobs/:jobId",
});
