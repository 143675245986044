import { FC } from "react";
import { Filters, Select, useAutocomplete } from "@epcnetwork/core-ui-kit";

import { convertToNumber } from "utils";
import { suppressionKindOptions } from "pages/suppression/list/suppression-list.constants";
import { getGroups } from "api";
import { FiltersType } from "../suppression-lists.types";
import { nonClearableKeys } from "../suppression-lists.constants";

const initialFilters: FiltersType = {
  groupIds: [],
  isGlobal: "",
};

const ListFilters: FC = () => {
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");

  return (
    <Filters
      initialFilters={initialFilters}
      searchKey="value"
      nonClearableKeys={nonClearableKeys as Array<keyof FiltersType>}
    >
      {({ setSelect, getSelect }) => {
        const groupIds = convertToNumber(getSelect("groupIds", true));
        const kindValue = getSelect("isGlobal");

        return (
          <div>
            <Filters.ContentItem>
              <Select
                label="Group"
                onChange={setSelect("groupIds", true)}
                selectedOptionsKeys={groupIds}
                asyncOptions={groupsAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Kind"
                onChange={setSelect("isGlobal")}
                selectedOptionsKeys={kindValue}
                options={suppressionKindOptions}
                disableError
              />
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export { ListFilters };
