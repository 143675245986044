import { object, AnySchema, number } from "yup";

import { requiredFieldText } from "constants/form.constants";
import { UpdateUserFormData } from "./update-user.types";

export const initialData: UpdateUserFormData = {
  email: "",
  groupId: 0,
};

export const roles = [
  { value: "ADMIN", label: "Admin" },
  { value: "SELF", label: "Self" },
];

export const updateUserValidationSchema: AnySchema = object().shape({
  groupId: number().required(requiredFieldText),
});
