import { FC, useRef, useState } from "react";
import { FormikHelpers, FormikProps } from "formik";
import {
  Button,
  Form,
  FormButtons,
  FormField as UiKitFormField,
  FormField,
  MessageField,
  Modal,
  useAutocomplete,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { filterDatabasesPipelines } from "utils";
import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { FormField as LocalFormField } from "components";
import { getDatabasesNames } from "api";
import { ReverseFileItem, ReverseValues } from "../reverse.types";
import { validationSchema } from "../reverse.constants";
import { HashTypes } from "./hash-types";

import styles from "../reverse.module.css";

type ReverseModalFormProps = {
  file: ReverseFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: ReverseFileItem) => void;
};

export const ReverseFormModal: FC<ReverseModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<ReverseValues>({
    fileValues: {
      headers: file.data.headers,
      suppress: file.data.suppress,
      databases: file.data.databases,
      hashType: file.data.hashType,
    },
  });

  const formRef = useRef<FormikProps<ReverseValues> | null>(null);

  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");
  databasesAutocomplete.fetchOptions = filterDatabasesPipelines(databasesAutocomplete.fetchOptions);

  const onSubmit = (values: ReverseValues, { setSubmitting }: FormikHelpers<ReverseValues>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.fileValues,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.modal}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <FormField
          type="select"
          name="fileValues.databases"
          label="Databases"
          placeholder="Databases"
          asyncOptions={databasesAutocomplete}
          isMulti
          canSelectAll
        />
        <HashTypes />
        <LocalFormField
          type="file-table"
          name="fileValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
          selector="radio"
          autocompleteColumns={false}
        />

        <div className={styles.actions}>
          <UiKitFormField
            type="checkbox"
            name="fileValues.suppress"
            label={suppressionCheckboxLabel}
            disableError
          />
          <FormButtons>
            <Button appearance="secondary" onClick={setClose}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </div>
      </Form>
    </Modal>
  );
};
