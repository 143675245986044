import { FC, useCallback, useState } from "react";
import { format } from "date-fns";
import {
  useCall,
  notification,
  Menu,
  MenuButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@epcnetwork/core-ui-kit";

import { getSuppressionListKind } from "pages/suppression/list/suppression-list.constants";
import { ListModel } from "models";
import { useAuthorization, useUser } from "hooks";
import { SUPPRESSION_LIST_PERMISSION } from "constants/roles.constants";
import { deleteList } from "api";
import { deletingMessage } from "../suppression-lists.constants";

import clockImg from "assets/images/clock.svg";
import calendarImg from "assets/images/calendar.svg";
import styles from "./list-table-row.module.css";

type RowProps = {
  item: ListModel;
  refresh: VoidFunction;
  handleEdit: (id: number) => void;
  handleSetValue: (value: string) => void;
  openDeletePermissionsError: VoidFunction;
};

export const ListTableRow: FC<RowProps> = ({
  item,
  refresh,
  handleEdit,
  handleSetValue,
  openDeletePermissionsError,
}) => {
  const ability = useAuthorization();

  const { isAdmin, user } = useUser();
  const { confirm, success, error } = deletingMessage;

  const [isDeleted, setIsDeleted] = useState(false);

  const { submit, onCallError, onCallSuccess } = useCall(deleteList);
  onCallSuccess(() => {
    setIsDeleted(true);
    refresh();
    notification.success(success.title, success.message);
  });

  onCallError(() => {
    setIsDeleted(false);
    notification.error(error.title, error.message);
  });

  const handleItemDelete = () => {
    if (item.isGlobal || !isAdmin) {
      handleSetValue(item.name);
      openDeletePermissionsError();
      return;
    }

    notification.confirm(confirm.title, confirm.message, {
      onOk: () => {
        setIsDeleted(true);
        submit({ params: { id: item.id } });
      },
      okText: "Delete",
      icon: "delete",
    });
  };

  const handleItemEdit = () => {
    handleEdit(item.id);
  };

  const canDelete = !item.isGlobal && isAdmin;

  const hasPermissionToUpdate = ability.can("update", SUPPRESSION_LIST_PERMISSION.name);
  const isListInSameGroup = item?.groupId === user?.groupId;
  const canUpdate = hasPermissionToUpdate && !item.isGlobal && isListInSameGroup;

  const createdAtDate = new Date(item.createdAt);

  const showDropdownMenu = canDelete || canUpdate;

  const prepareDeleteTooltipMessage = useCallback(() => {
    if (item.isGlobal) return "You can't delete a global list";
    if (!isAdmin) return "You don't have an access to delete this list";
  }, [item.isGlobal, isAdmin]);

  const prepareUpdateTooltipMessage = useCallback(() => {
    if (item.isGlobal) return "You can't update a global list";
    if (!hasPermissionToUpdate) return "You don't have permission to update a list";
    if (!isListInSameGroup) return "You can't update a list from outside a group";
  }, [item.isGlobal, hasPermissionToUpdate, isListInSameGroup]);

  const deleteButton = (
    <MenuButton appearance="delete" icon="delete" onClick={handleItemDelete} disabled={!canDelete}>
      Delete
    </MenuButton>
  );

  const updateButton = (
    <MenuButton
      onClick={handleItemEdit}
      icon="edit"
      className={styles.updateItem}
      disabled={!canUpdate}
    >
      Edit
    </MenuButton>
  );

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={item.id}>
      <TableCell>
        <div className={styles.value}>{item.name}</div>
      </TableCell>
      <TableCell>
        <div className={styles.values}>{item?.suppressionsAmount || "0"}</div>
      </TableCell>
      <TableCell>
        <div className={styles.value}>{item?.groupName || "-"}</div>
      </TableCell>
      <TableCell>
        <div className={styles.values}>{getSuppressionListKind(item.isGlobal)}</div>
      </TableCell>
      <TableCell>
        <TableCell className={styles.date}>
          <img src={calendarImg} alt="" width={11} height={11} />
          {createdAtDate ? format(createdAtDate, "MMM dd, yyyy") : "-"}
          <img src={clockImg} alt="" width={11} height={11} />
          {createdAtDate ? format(createdAtDate, "hh:mm aaa") : "-"}
        </TableCell>
      </TableCell>
      <TableCell>
        {showDropdownMenu && (
          <Menu>
            {canUpdate ? (
              updateButton
            ) : (
              <Tooltip trigger="hover" position="top" triggerElement={updateButton}>
                {prepareUpdateTooltipMessage()}
              </Tooltip>
            )}

            {canDelete ? (
              deleteButton
            ) : (
              <Tooltip trigger="hover" position="bottom" triggerElement={deleteButton}>
                {prepareDeleteTooltipMessage()}
              </Tooltip>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};
