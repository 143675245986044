import { AnySchema, array, boolean, mixed, number, object, SchemaOf, string } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { minOneEmailText, requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { DeduplicateFileData, DeduplicateValues } from "./deduplicate.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const compareOptions = [
  { value: "rows", label: "Rows" },
  { value: "emails", label: "Emails" },
];

export const getInitialData = (item: File): DeduplicateFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  suppress: true,
  emailIndex: -1,
  compareBasedOn: "rows",
});

export const validationSchema: SchemaOf<DeduplicateValues> = object({
  fileValues: object({
    headers: array().of(string().ensure()),
    hasHeaders: boolean().required(requiredFieldText),
    suppress: boolean(),
    compareBasedOn: mixed().oneOf(["emails", "rows"]),
    emailIndex: number().when("compareBasedOn", {
      is: "emails",
      then: number().min(0, minOneEmailText).required(requiredFieldText),
      otherwise: number(),
    }) as AnySchema,
  }).required(requiredFieldText),
});
