import React, { FC } from "react";
import cn from "classnames";
import { Sidebar as SidebarComponent, useWindowSize } from "@epcnetwork/core-ui-kit";

import {
  sidebarWidth,
  collapsedDesktop,
  collapsedMobile,
  placement,
  SIDEBAR_COLLAPSED_FIELD,
} from "./sidebar.constants";
import { Menu } from "./components/menu";
import { Logo } from "./components/logo";

import togglerArrow from "assets/images/arrow-right-grey.svg";
import styles from "./sidebar.module.css";

type SidebarProps = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Sidebar: FC<SidebarProps> = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width } = useWindowSize();

  const toggleCollapsed = () => setSidebarCollapsed((prev) => !prev);

  const isMobile = width < 992;
  const collapsedWidth = isMobile ? collapsedMobile : collapsedDesktop;

  const togglerStyles = cn(styles.toggler, styles[placement], {
    [styles.collapsed]: sidebarCollapsed,
  });
  const sidebarStyles = cn(styles.sidebar, { [styles.collapsed]: sidebarCollapsed });

  return (
    <SidebarComponent
      wrapperClassName={styles.sidebarWrapper}
      contentClassName={sidebarStyles}
      setIsCollapsed={setSidebarCollapsed}
      isCollapsed={sidebarCollapsed}
      sidebarWidth={sidebarWidth}
      collapsedWidth={collapsedWidth}
      entityName={SIDEBAR_COLLAPSED_FIELD}
      isContentOffset
    >
      <Logo isCollapsed={sidebarCollapsed} />
      {!isMobile && (
        <button className={togglerStyles} onClick={toggleCollapsed}>
          <img width={12} height={12} className={styles.togglerImage} src={togglerArrow} alt="" />
        </button>
      )}
      <Menu />
    </SidebarComponent>
  );
};
