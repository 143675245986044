import { ref, object, string, SchemaOf, number } from "yup";

import { passwordValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { UserFormData } from "./create-user.types";

export const initialData: UserFormData = {
  email: "",
  password: "",
  confirmPassword: "",
  groupId: null,
  role: "",
};

export const roles = [
  { value: "ADMIN", label: "Admin" },
  { value: "SELF", label: "Self" },
];

export const createUserValidationSchema: SchemaOf<UserFormData> = object().shape({
  email: string().email("Email is not valid").required(requiredFieldText),
  password: string()
    .min(8, "Password needs to include at least 8 characters")
    .max(30, "Password is too long")
    .test("password validation", "", passwordValidation)
    .required(requiredFieldText),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required(requiredFieldText),
  groupId: number().typeError(requiredFieldText).required(requiredFieldText),
  role: string().required(requiredFieldText),
});
