import { FC } from "react";
import { Button, MessageField, Form, FormButtons, FormField } from "@epcnetwork/core-ui-kit";

import { Hint } from "components";
import { useLoginHook } from "./login.hook";
import { initialValues, validationSchema } from "./login.constants";
import { LoginSlider } from "./login-slider/login-slider";

import logo from "assets/images/logo.svg";
import styles from "./login.module.css";

const LoginPage: FC = () => {
  const { onSubmit, handleForgotPassword, errorMessage, hasPassChanged } = useLoginHook();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img className={styles.icon} src={logo} width="36" height="36" alt="Company logo" />
        <div className={styles.logoText}>Mergex</div>
      </div>
      <div className={styles.sidebar}>
        <div className={styles.content}>
          <LoginSlider />
          <div className={styles.info}>
            <div className={styles.option}>&#169;{new Date().getFullYear()} Mergex</div>
          </div>
        </div>
      </div>
      <div className={styles.loginForm}>
        <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          <div className={styles.title}>Welcome to Mergex</div>
          <div className={styles.subtitle}>Please use your credential to Log In</div>
          <MessageField message={errorMessage} align="left" />
          <FormField type="text" name="email" label="Email" placeholder="Fill your email" />
          <FormField
            type="password"
            name="password"
            label="Password"
            placeholder="Fill your password"
          />
          {hasPassChanged && (
            <Hint type="success">
              Your password has been reset successfully, <br />
              you can use your new password to log in.
            </Hint>
          )}
          <div className={styles.forgotPassword} onClick={handleForgotPassword}>
            Forgot password
          </div>
          <FormButtons align="right">
            <Button className={styles.btn} type="submit">
              Log in
            </Button>
          </FormButtons>
        </Form>
      </div>
    </div>
  );
};

export { LoginPage };
