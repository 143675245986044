import { useNavigate } from "react-router-dom";
import { FC, memo, useCallback, useRef, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  notification,
  useWillUnmount,
  useDidUpdate,
  useFileQueue,
  Checkbox,
} from "@epcnetwork/core-ui-kit";

import { getAcceptedFilesText, getPipelineRoute, getSupportedFormats, onFileRedirect } from "utils";
import { useTracking } from "hooks";
import { SMARTLOOK_EVENTS } from "constants/smartlook.constants";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { postExtractFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { ExtractFileInfo, ExtractFileItem, PipelineExtractData } from "./extract.types";
import { supportedFormats, getInitialData } from "./extract.constants";

import styles from "./extract.module.css";

const ExtractPage: FC = memo(() => {
  const componentIsMounted = useRef(true);
  const navigate = useNavigate();

  const { pipelineSubmitEvent } = useTracking();

  const pipeline = getPipelineRoute("Extract");

  const {
    files,
    createInitialFile,
    addFiles,
    removeFiles,
    clearEntity,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllSuccessFiles,
    submitOneByOne,
  } = useFileQueue<ExtractFileItem, PipelineExtractData>(pipeline.title, postExtractFile);

  useWillUnmount(() => {
    componentIsMounted.current = false;
  });

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [suppress, setSuppress] = useState(true);
  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleSuppressChange = useCallback((value: string, isChecked: boolean) => {
    setSuppress(isChecked);
  }, []);

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();

      const { fileName } = file.data;

      const extractData: ExtractFileInfo = { suppress };

      formData.append("fileInfo", JSON.stringify(extractData));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
    pipelineSubmitEvent(SMARTLOOK_EVENTS.extractSubmit, files);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, navigate });
  };

  const handleRedirectToPipelines = () => {
    navigate(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                isTuned
              />
            ))}
          </div>
          <div className={styles.actions}>
            <Checkbox
              checked={suppress}
              value="suppress"
              onChange={handleSuppressChange}
              label={suppressionCheckboxLabel}
              disabled={false}
              disableError
            />
            <div className={styles.buttons}>
              <Button
                appearance="secondary"
                onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
                disabled={isEntityFinished && !hasAllSuccessFiles}
              >
                Cancel
              </Button>
              {!isEntityFinished && (
                <Button
                  onClick={handleSubmit}
                  disabled={!files.length}
                  loading={!isEntityInConfiguration}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
});

export { ExtractPage };
