import { List } from "@epcnetwork/core-ui-kit";

import { UserModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { UserRolesFormData, UserData, UserDetails, UpdateUserData } from "./users.interface";

export const getUsers = apiMiddleware<List<UserDetails>>()({
  method: "get",
  endpoint: "/users",
});

export const getUser = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: "/users/:userId",
});

export const postUser = apiMiddleware<UserDetails, UserData>()({
  method: "post",
  endpoint: "/users",
});

export const putUser = apiMiddleware<UserDetails, UpdateUserData>()({
  method: "put",
  endpoint: "/users/:userId",
});

export const putUserRoles = apiMiddleware<UserDetails, UserRolesFormData>()({
  method: "put",
  endpoint: "/users/roles/:userId",
});

export const deleteUser = apiMiddleware<UserDetails>()({
  method: "delete",
  endpoint: "/users/:userId",
});
