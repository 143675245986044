import { FileQueueItem } from "@epcnetwork/core-ui-kit";

import { FormFile, FormFileTableHeaders } from "types";

export enum HashTypesEnum {
  md5 = "md5",
  sha256 = "sha256",
}

export type ReverseFileData = {
  fileName: string;
  databases: string[];
  hashType: HashTypesEnum;
  suppress: boolean;
  headers: string[];
};

export type ReverseFileItem = FileQueueItem<ReverseFileData>;

export type ReverseFileInfo = Pick<ReverseFileData, "databases" | "hashType" | "suppress"> & {
  hashIndex: number;
};

export type PipelineReverseData = {
  file: FormFile;
  fileInfo: ReverseFileInfo;
};

export type ReverseValues = {
  fileValues: Omit<FormFileTableHeaders, "emailIndex" | "hasHeaders"> & {
    databases: string[];
    hashType: HashTypesEnum;
  };
};
