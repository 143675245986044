import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  Form,
  FormField,
  MessageField,
  notification,
  useFormValues,
  useSubmit,
} from "@epcnetwork/core-ui-kit";

import { getList, postList, putList } from "api";
import { SuppressionSettingsFormProps } from "./suppression-lists-form.types";
import { initialValues, validationSchema } from "./suppression-lists-form.constants";

import styles from "./suppression-lists-form.module.css";

const SuppressionListsFormPage: FC<SuppressionSettingsFormProps> = ({
  closeModal,
  handleItemChange,
  editId,
}) => {
  const [error, setError] = useState("");

  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getList.setParams({ id: editId || "" }),
    !!editId,
  );

  mapInitialValues((payload) => {
    return {
      name: payload.name,
    };
  });

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(
    postList,
    putList.setParams({ id: editId || "" }),
    !!editId,
  );
  onSubmitSuccess((payload) => {
    handleItemChange && handleItemChange(payload);
    const title = editId ? "List type edited" : "List type added";
    const subtitle = editId
      ? "You have successfully edited list type."
      : "You have successfully added list type.";
    notification.success(title, subtitle);
    closeModal();
  });
  onSubmitError((error) => setError(error.message));

  return (
    <div className={styles.container}>
      <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        <MessageField message={error} className={styles.errorMsg} />
        <div className={styles.title}>{editId ? "Edit" : "Create new list"}</div>
        <FormField type="text" name="name" label="Name" placeholder="Enter name here..." required />

        <div className={cn(styles.buttons, styles.buttonsWrapper)}>
          <Button appearance="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};

export { SuppressionListsFormPage };
