import { ApiResponse, ApiStatusResponse, ApiErrorResponse } from "@epcnetwork/core-ui-kit";

import { ServerErrorTypes } from "api/api.middleware";

type Props = {
  payload: ApiResponse<unknown>;
  loading: boolean;
  error: ApiErrorResponse<ServerErrorTypes> | null;
  details: ApiStatusResponse;
};

type UseShowContentReturnType = {
  showContent: boolean;
  showNoContent: boolean;
  showError: boolean;
  showLoader: boolean;
  showPagination: boolean;
};

function useShowContent({ payload, loading, error, details }: Props): UseShowContentReturnType {
  function checkPayloadData() {
    if (payload?.["hydra:member"]) {
      return Boolean(payload["hydra:member"].length);
    }
    return Boolean(payload);
  }

  const hasPayload = checkPayloadData();
  const showLoader = loading;
  const showContent = !error && hasPayload;
  const showNoContent = !loading && !error && !hasPayload;
  const showError = Boolean(!loading && error && !details.isCanceled && !hasPayload);
  const showPagination = hasPayload && !showError && !showNoContent;

  return {
    showContent,
    showNoContent,
    showError,
    showLoader,
    showPagination,
  };
}

export { useShowContent };
