import { FC } from "react";
import cn from "classnames";

import { rangeOptions } from "pages/stats/stats.constants";
import { Range } from "../stats.types";

import styles from "./range-switcher.module.scss";

interface ChartTimeUnitSwitcherProps {
  currentRange: Range;
  setRange: (range: Range) => void;
}

export const RangeSwitcher: FC<ChartTimeUnitSwitcherProps> = ({ currentRange, setRange }) => {
  const handleRangeChange = (range: Range) => () => {
    setRange(range);
  };

  return (
    <div className={styles.timeSwitcherGroup}>
      {rangeOptions.map(({ days, shortLabel }) => (
        <button
          key={days}
          className={cn(styles.timeSwitcher, currentRange === days && styles.active)}
          onClick={handleRangeChange(days)}
        >
          {shortLabel}
        </button>
      ))}
    </div>
  );
};
