import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts";
import React from "react";
import { Nullable } from "@epcnetwork/core-ui-kit";

import { Range } from "../../stats.types";

import styles from "./custom-tooltip.module.scss";

interface Props {
  tooltipProps: TooltipProps<ValueType, NameType>;
  range: Nullable<Range>;
}

export const CustomTooltip: React.FC<Props> = ({ tooltipProps, range }) => {
  return (
    <div className={styles.tooltipWrapper}>
      <h4 className={styles.title}>
        {tooltipProps?.payload?.[0]?.payload?.fullName || tooltipProps.label}
      </h4>
      <p className={styles.usage}>
        <span>{tooltipProps.payload?.[0]?.value}</span> in last {range} days
      </p>
    </div>
  );
};
