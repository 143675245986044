import { FC, useState } from "react";
import { format } from "date-fns";
import {
  TableRow,
  TableCell,
  notification,
  MenuButton,
  Menu,
  useCall,
} from "@epcnetwork/core-ui-kit";

import { useAuthorization } from "hooks";
import { USERS_PERMISSION } from "constants/roles.constants";
import { deleteUser, UserDetails } from "api";

import lock from "assets/images/lock.svg";
import clockImg from "assets/images/clock.svg";
import calendarImg from "assets/images/calendar.svg";
import styles from "./user-table-row.module.css";

type RowProps = {
  item: UserDetails;
  setUserToEdit: (id: number | null) => () => void;
  setRolesToEdit: (id: number | null) => () => void;
};

export const UserRow: FC<RowProps> = ({ item, setUserToEdit, setRolesToEdit }) => {
  const ability = useAuthorization();

  const [isDeleted, setDeleted] = useState(false);

  const { submit, onCallSuccess, onCallError } = useCall(deleteUser);
  onCallSuccess(() => {
    notification.success("User deleted", "User successfully deleted");
  });
  onCallError(() => {
    setDeleted(false);
    notification.error("Deleting error", "Cannot delete user");
  });

  const handleRemoveItem = (id: number) => {
    notification.confirm("Deleting user?", "Are you sure?", {
      onOk: () => {
        setDeleted(true);
        submit({ params: { userId: id } });
      },
      okText: "Delete",
      icon: "delete",
    });
  };

  const date = new Date(item.createdAt);
  const canUpdateUser = ability.can("create", USERS_PERMISSION.name);
  const canDeleteUser = ability.can("delete", USERS_PERMISSION.name);

  const showDropdown = canUpdateUser || canDeleteUser;

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={item.id}>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.roles[0]}</TableCell>
      <TableCell>{item.groupName || "-"}</TableCell>
      <TableCell>
        <div className={styles.date}>
          <img src={calendarImg} alt="" width="11" height="11" />
          {format(date, "EEE, MMM dd, yyyy")}
          <img src={clockImg} alt="" width="11" height="11" />
          {format(date, "hh:mm aaa")}
        </div>
      </TableCell>

      <TableCell>
        {showDropdown && (
          <Menu>
            {canUpdateUser && (
              <MenuButton
                onClick={setUserToEdit(item.id)}
                icon="edit"
                className={styles.menuButton}
              >
                Edit
              </MenuButton>
            )}
            {canUpdateUser && (
              <MenuButton onClick={setRolesToEdit(item.id)}>
                <img src={lock} alt="" width={13} height={16} className={styles.lockIcon} />
                Edit Permissions
              </MenuButton>
            )}
            {canDeleteUser && (
              <MenuButton
                appearance="delete"
                icon="delete"
                onClick={() => handleRemoveItem(item.id)}
              >
                Delete
              </MenuButton>
            )}
          </Menu>
        )}
        {!showDropdown && <div style={{ height: "30px" }} />}
      </TableCell>
    </TableRow>
  );
};
