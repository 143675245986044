import { FC, Fragment } from "react";

import { AuthorizationEntityNameType, PipelinesNamesType } from "types";
import { WarmUpPage } from "pages/pipelines/forms/warm-up";
import { SuppressionPage } from "pages/pipelines/forms/suppression";
import { SumClicksPage } from "pages/pipelines/forms/sum-clicks";
import { SplitPage } from "pages/pipelines/forms/split";
import { Sha256Page } from "pages/pipelines/forms/sha256";
import { ReversePage } from "pages/pipelines/forms/reverse";
import { MergePage } from "pages/pipelines/forms/merge";
import { Md5Page } from "pages/pipelines/forms/md5";
import { MatchAndEliminatePage } from "pages/pipelines/forms/match-and-eliminate";
import { MatchPage } from "pages/pipelines/forms/match";
import { IspSplitterPage } from "pages/pipelines/forms/isp-splitter";
import { ExtractPage } from "pages/pipelines/forms/extract";
import { DomainPurifyPage } from "pages/pipelines/forms/domain-purify";
import { DiffCommonPage } from "pages/pipelines/forms/diff-common";
import { DeduplicatePage } from "pages/pipelines/forms/deduplicate";
import { CutPage } from "pages/pipelines/forms/cut";
import { ConvertPage } from "pages/pipelines/forms/convert";
import {
  PIPELINE_CONVERT_PERMISSION,
  PIPELINE_CUT_PERMISSION,
  PIPELINE_DEDUPLICATE_PERMISSION,
  PIPELINE_DIFF_AND_COMMON_PERMISSION,
  PIPELINE_DOMAIN_PURIFIER_PERMISSION,
  PIPELINE_EXTRACT_EMAIL_PERMISSION,
  PIPELINE_MATCH_EMAILS_PERMISSION,
  PIPELINE_ISP_SPLITTER_PERMISSION,
  PIPELINE_SPLIT_FILE_PERMISSION,
  PIPELINE_SUM_CLICKS_PERMISSION,
  PIPELINE_SUPPRESS_PERMISSION,
  PIPELINE_WAM_UP_PERMISSION,
  PIPELINE_MD5_PERMISSION,
  PIPELINE_SHA256_PERMISSION,
  PIPELINE_REVERSE_PERMISSION,
  PIPELINE_MERGE_PERMISSION,
  PIPELINE_MATCH_AND_ELIMINATE_PERMISSION,
} from "./roles.constants";

import warmUpImg from "assets/images/pipelines-warmUp.svg";
import suppressImg from "assets/images/pipelines-suppress.svg";
import sumClicksImg from "assets/images/pipelines-sumClicks.svg";
import splitImg from "assets/images/pipelines-split.svg";
import reverseImg from "assets/images/pipelines-reverse.svg";
import purifyImg from "assets/images/pipelines-purify.svg";
import mergeImg from "assets/images/pipelines-merge.svg";
import md5Img from "assets/images/pipelines-md5.svg";
import matchImg from "assets/images/pipelines-match.svg";
import ispImg from "assets/images/pipelines-isp.svg";
import gavinImg from "assets/images/pipelines-gavin.svg";
import extractImg from "assets/images/pipelines-extract.svg";
import diffCommonImg from "assets/images/pipelines-diffCommon.svg";
import deduplicateImg from "assets/images/pipelines-deduplicate.svg";
import cutImg from "assets/images/pipelines-cut.svg";
import convertImg from "assets/images/pipelines-convert.svg";

export type PipelineRoutes = {
  element: FC;
  src: string;
  title: PipelinesNamesType;
  subtitle: string;
  path: string;
  authEntity: AuthorizationEntityNameType;
  backendName: string;
  db: boolean;
};

export const PIPELINES_LIST: PipelineRoutes[] = [
  {
    element: ConvertPage,
    src: convertImg,
    authEntity: PIPELINE_CONVERT_PERMISSION.name,
    title: "Convert",
    subtitle: "Convert file from XLSX to CSV",
    path: "/pipelines/convert",
    backendName: "convert",
    db: false,
  },
  {
    element: CutPage,
    src: cutImg,
    authEntity: PIPELINE_CUT_PERMISSION.name,
    title: "Cut",
    subtitle: "Returns file(s) containing only selected columns",
    path: "/pipelines/cut",
    backendName: "cut",
    db: false,
  },
  {
    element: DeduplicatePage,
    src: deduplicateImg,
    authEntity: PIPELINE_DEDUPLICATE_PERMISSION.name,
    title: "Deduplicate",
    subtitle: "Remove duplicates from the file",
    path: "/pipelines/deduplicate",
    backendName: "deduplicate",
    db: false,
  },
  {
    element: DiffCommonPage,
    src: diffCommonImg,
    authEntity: PIPELINE_DIFF_AND_COMMON_PERMISSION.name,
    title: "Diff and Common",
    subtitle:
      "Returns ZIP with 4 files: common part, unique elements for the first file (A - B), unique elements for the second file (B - A), unique elements from both files",
    path: "/pipelines/diff-common",
    backendName: "diffAndCommon",
    db: false,
  },
  {
    element: DomainPurifyPage,
    src: purifyImg,
    authEntity: PIPELINE_DOMAIN_PURIFIER_PERMISSION.name,
    title: "Domain Purify",
    subtitle: "Deletes emails  hosted on selected domains from the file",
    path: "/pipelines/domain-purify",
    backendName: "domainPurify",
    db: false,
  },
  {
    element: ExtractPage,
    src: extractImg,
    authEntity: PIPELINE_EXTRACT_EMAIL_PERMISSION.name,
    title: "Extract",
    subtitle: "Returns a .csv with email header and all emails the file contains",
    path: "/pipelines/extract",
    backendName: "extract",
    db: false,
  },
  {
    element: MatchPage,
    src: matchImg,
    authEntity: PIPELINE_MATCH_EMAILS_PERMISSION.name,
    title: "Match",
    subtitle: "Match emails with a database, returned matched emails",
    path: "/pipelines/match",
    backendName: "match",
    db: true,
  },
  {
    element: MergePage,
    src: mergeImg,
    authEntity: PIPELINE_MERGE_PERMISSION.name,
    title: "Merge",
    subtitle: "Merge files into one",
    path: "/pipelines/merge",
    backendName: "merge",
    db: false,
  },
  {
    element: Md5Page,
    src: md5Img,
    authEntity: PIPELINE_MD5_PERMISSION.name,
    title: "MD5",
    subtitle: "Hashes emails from the file using MD5",
    path: "/pipelines/md5",
    backendName: "md5",
    db: false,
  },
  {
    element: Sha256Page,
    src: md5Img,
    authEntity: PIPELINE_SHA256_PERMISSION.name,
    title: "SHA256",
    subtitle: "Hashes emails from the file using SHA256",
    path: "/pipelines/sha256",
    backendName: "sha256",
    db: false,
  },
  {
    element: ReversePage,
    src: reverseImg,
    authEntity: PIPELINE_REVERSE_PERMISSION.name,
    title: "Reverse",
    subtitle: "Reverse either MD5 or SHA256 hashes to emails with selected database(s)",
    path: "/pipelines/reverse",
    backendName: "reverse",
    db: true,
  },
  {
    element: SplitPage,
    src: splitImg,
    authEntity: PIPELINE_SPLIT_FILE_PERMISSION.name,
    title: "Split",
    subtitle: "Split file by line number or size",
    path: "/pipelines/split",
    backendName: "split",
    db: false,
  },
  {
    element: SumClicksPage,
    src: sumClicksImg,
    authEntity: PIPELINE_SUM_CLICKS_PERMISSION.name,
    title: "Sum Clicks",
    subtitle: "Combines files into one file containing the sum of all unique URLs clicked",
    path: "/pipelines/sum-clicks",
    backendName: "sumClicks",
    db: false,
  },
  {
    element: MatchAndEliminatePage,
    src: suppressImg,
    authEntity: PIPELINE_MATCH_AND_ELIMINATE_PERMISSION.name,
    title: "Match and Eliminate",
    subtitle: "Exclude emails from the file that are found in our databases as per your selection.",
    path: "/pipelines/match-and-eliminate",
    backendName: "matchAndEliminate",
    db: true,
  },
  {
    element: WarmUpPage,
    src: warmUpImg,
    authEntity: PIPELINE_WAM_UP_PERMISSION.name,
    title: "Warm Up Splitter",
    subtitle: "Return file(s) with doubled number of emails starting from initial values",
    path: "/pipelines/warm-up-splitter",
    backendName: "warmUpSplitter",
    db: false,
  },
  {
    element: SuppressionPage,
    src: suppressImg,
    authEntity: PIPELINE_SUPPRESS_PERMISSION.name,
    title: "Suppression",
    subtitle:
      "Purify the file by eliminating emails that are listed in our suppression database or include suppression-related keywords, domains or regex patterns.",
    path: "/pipelines/suppression",
    backendName: "suppress",
    db: false,
  },
  {
    element: Fragment,
    src: "",
    authEntity: "" as AuthorizationEntityNameType,
    title: "Export",
    subtitle: "Export",
    path: "/pipelines/export",
    backendName: "export",
    db: true,
  },
  {
    element: IspSplitterPage,
    src: ispImg,
    authEntity: PIPELINE_ISP_SPLITTER_PERMISSION.name,
    title: "ISP Domain Splitter",
    subtitle: "Split ISP domains to csv files",
    path: "/pipelines/isp",
    backendName: "ispDomainSplitter",
    db: true,
  },
];

export const suppressionCheckboxLabel =
  "Remove emails from the output file matching suppression list, keywords, domains, or regex.";
