import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  MessageField,
  FormButtons,
  useFileParser,
  FormField as UiKitFormField,
} from "@epcnetwork/core-ui-kit";

import { suppressionCheckboxLabel } from "constants/pipelines.constants";
import { FormField } from "components";
import { IspSplitterValues, IspSplitterFileItem } from "../isp-splitter.types";
import { validationSchema } from "../isp-splitter.constants";

import styles from "../isp-splitter.module.css";

type IspSplitterModalFormProps = {
  file: IspSplitterFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: IspSplitterFileItem) => void;
};

export const IspSplitterFormModal: FC<IspSplitterModalFormProps> = ({
  file,
  setClose,
  onSubmitClick,
}) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);
  const [initialValues] = useState<IspSplitterValues>({ fileValues: file.data });

  const onSubmit = (
    values: IspSplitterValues,
    { setSubmitting }: FormikHelpers<IspSplitterValues>,
  ) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: { ...file.data, ...values.fileValues },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        {({ values }) => {
          const emailColumnSelected = values.fileValues.headers.includes("email");

          return (
            <>
              <FormField
                type="file-table"
                name="fileValues"
                label="Select Email column"
                parsedFile={parsedFile}
                loading={loading}
              />

              <div className={styles.actions}>
                <UiKitFormField
                  type="checkbox"
                  name="fileValues.suppress"
                  label={suppressionCheckboxLabel}
                  disabled={!emailColumnSelected}
                  disableError
                />
                <FormButtons>
                  <Button appearance="secondary" onClick={setClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Set values</Button>
                </FormButtons>
              </div>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
