import { useSelector } from "react-redux";
import { useMemo } from "react";

import { RootState } from "store";
import { UserModel } from "models";
import { ADMIN_PERMISSION } from "constants/roles.constants";

type UserDetails = {
  user: UserModel | null;
  isAdmin: boolean;
};

export const useUser = (): UserDetails => {
  const { user } = useSelector((state: RootState) => state.auth);

  const isAdmin = useMemo(() => {
    if (!user) return false;

    return user.roles.includes(ADMIN_PERMISSION);
  }, [user]);

  return { user, isAdmin };
};
