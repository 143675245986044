import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import React, { useState } from "react";
import { Loader, useFetch } from "@epcnetwork/core-ui-kit";

import { usePayload, useShowContent } from "hooks";
import { getSummaryMetrics } from "api";
import { Range } from "../stats.types";
import { mapPipelineResponse } from "../stats.constants";
import { RangeSwitcher } from "../range-switcher/range-switcher";
import { CustomTooltip } from "./custom-tooltip/custom-tooltip";

import styles from "./pipeline-usage-chart.module.scss";

export const PipelineUsageChart: React.FC = () => {
  const [range, setRange] = useState<Range>(7);

  const apiResponse = useFetch(getSummaryMetrics.setQueryParams(`?days=${range}`), {
    dependencies: [range],
  });
  const { showLoader, showContent } = useShowContent(apiResponse);
  const { payload: data } = usePayload(apiResponse);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Total pipeline usage</h3>

        <RangeSwitcher currentRange={range} setRange={setRange} />
      </div>

      {showLoader && <Loader />}
      {showContent && (
        <div style={{ marginLeft: "-30px" }}>
          <ResponsiveContainer width="99%" height={260}>
            <BarChart data={mapPipelineResponse(data)}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="label"
                padding={{
                  right: 3,
                  left: 3,
                }}
                tickLine={false}
              />
              <YAxis tickLine={false} width={50} />
              <Tooltip
                cursor={false}
                content={(props) => <CustomTooltip tooltipProps={props} range={range} />}
              />
              <ReferenceLine y={0} stroke="#000" strokeWidth={1.5} />
              <Bar
                dataKey="value"
                fill="var(--palette-primary)"
                stroke="var(--table-row-selected)"
                barSize={12}
                radius={[3, 3, 0, 0]}
              />
              <Brush dataKey="label" height={16} stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
