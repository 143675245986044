import { FC, memo, useState, CSSProperties } from "react";
import cn from "classnames";
import {
  useWindowSize,
  NotAuthorized,
  useLocalStorage,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import {
  collapsedDesktop,
  sidebarWidth,
  SIDEBAR_COLLAPSED_FIELD,
} from "../sidebar/sidebar.constants";
import { Sidebar } from "../sidebar/sidebar";
import { Navbar } from "../navbar/navbar";

import styles from "./navigation.module.css";

interface Props {
  element: FC;
  showNavigation: boolean;
  isAuthorized?: boolean;
}

export const Navigation = memo(
  ({ element: Component, showNavigation, isAuthorized = true, ...rest }: Props) => {
    const { height } = useWindowSize();
    const { value: isCollapsed } = useLocalStorage(SIDEBAR_COLLAPSED_FIELD);

    const [isSidebarCollapsed, setSidebarCollapsed] = useState<boolean>(isCollapsed || false);

    useDidUpdate(
      () => {
        setSidebarCollapsed(isCollapsed || false);
      },
      [isCollapsed],
      true,
    );

    const toggleSidebar = () => {
      setSidebarCollapsed((prevStatus) => !prevStatus);
    };

    const width = isSidebarCollapsed ? collapsedDesktop : sidebarWidth;
    const contentStyles = cn(styles.content, { [styles.closed]: isSidebarCollapsed });

    const inlineStyles: CSSProperties = {
      maxWidth: `calc(100vw - ${width})`,
      height,
    };

    if (!isAuthorized) {
      return (
        <NotAuthorized title="Access Denied" subtitle="You're not authorized to view this page" />
      );
    }

    if (!showNavigation) {
      return <Component {...rest} />;
    }

    return (
      <div className={styles.container} data-testid="app">
        <Sidebar
          data-testid="sidebar"
          sidebarCollapsed={isSidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <div className={contentStyles} data-testid="content" style={inlineStyles}>
          <Navbar toggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />
          <Component {...rest} />
        </div>
      </div>
    );
  },
);
