import { object, string, array } from "yup";

import { atLeastOneOption, requiredFieldText } from "constants/form.constants";

const valueOrMd5Required = "Value or MD5 column is required";

export const columns = [
  { value: "suppression.value", label: "Value" },
  { value: "suppression.md5", label: "MD5" },
  { value: "suppression.categories", label: "Category" },
  { value: "suppression.esps", label: "Esp" },
  { value: "list.name", label: "List type" },
  { value: "suppression.status", label: "Status" },
];

const includesOneOfRequired = (column?: string): boolean => {
  return column === "suppression.value" || column === "suppression.md5";
};

export const columnsValidation = array()
  .of(string().required())
  .typeError(requiredFieldText)
  .min(1, atLeastOneOption)
  .required(requiredFieldText)
  .test("includesRequired", valueOrMd5Required, (columns) => {
    if (!columns) return false;

    return columns?.some((column) => includesOneOfRequired(column)) || false;
  });

export const validationSchema = object().shape({
  filename: string()
    .min(10, "File Name should have more than 10 symbols")
    .required(requiredFieldText),
  columns: columnsValidation,
  filters: array().of(string()),
});
