import React from "react";
import cn from "classnames";
import { Label, SelectOption, SelectOptionValue } from "@epcnetwork/core-ui-kit";

import styles from "./inline-select.module.css";

interface Props {
  options: SelectOption[];
  selected: SelectOptionValue | SelectOption;
  onChange: (value: SelectOption) => void;
  label?: string;
}

export const InlineSelect: React.FC<Props> = ({ options, selected, onChange, label }) => {
  const isPrimitive = typeof selected === "string" || typeof selected === "number";

  const currentValue = isPrimitive ? selected : selected.value;

  return (
    <div className={styles.wrapper}>
      {label && <Label text={label} isInputLabel className={styles.label} />}

      <div className={styles.container}>
        {options.map((option) => {
          const isActive = currentValue === option.value;

          return (
            <button
              key={option.value}
              type="button"
              onClick={() => onChange(option)}
              className={cn(styles.optionButton, { [styles.activeButton]: isActive })}
            >
              {option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
