import { array, boolean, number, object, SchemaOf, string } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { emailColumnValidation, headersValidation, selectNumberValidation } from "utils";
import { minOneFieldText, minZeroFieldText, requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { PipelinesDomain } from "api/pipelines/pipelines.interface";
import { WarmUpFileData, WarmUpValues } from "./warm-up.types";

export const supportedFormats = [csvFormat, txtFormat].flat();
export const typeOptions = [
  {
    label: "Email amount",
    value: 1,
  },
  // {
  //   label: "With %",
  //   value: 2,
  // },
];

export const getInitialData = (item: File): WarmUpFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  emailIndex: -1,
  type: typeOptions[0].value,
  days: [{ data: [{ numberOfEmails: 100, domain: "1" }] }],
  suppress: true,
});

export const validationSchema = (domains: PipelinesDomain[]): SchemaOf<WarmUpValues> =>
  object({
    fileValues: object({
      emailIndex: emailColumnValidation(),
      headers: headersValidation(),
      hasHeaders: boolean().required(requiredFieldText),
      suppress: boolean(),
    }).required(requiredFieldText),
    daysNumber: number().min(1, minOneFieldText).required(requiredFieldText),
    type: selectNumberValidation(),
    domainNumber: number().min(1, minOneFieldText).required(requiredFieldText),
    initialEmailsNumber: number().min(1, minOneFieldText).required(requiredFieldText),
    domainsEmailsSpread: array()
      .of(
        object()
          .shape({
            domain: string().min(1, minOneFieldText).required(requiredFieldText),
            numberOfEmails: number()
              .min(0, minZeroFieldText)
              .typeError("Incorrect value type")
              .required(requiredFieldText),
          })
          .test("emailsLowerThanMax", "Value must be less than all emails", (value, context) => {
            const currentDomain = domains.find((domain) => domain.key === value.domain);

            if (!currentDomain || !value.numberOfEmails) return true;

            return currentDomain.amount >= value.numberOfEmails;
          })
          .required(requiredFieldText),
      )
      .test("domainsCount", "You must select all domains inputs", (value, context) => {
        const { domainNumber } = context.parent;

        return Number(domainNumber) === value?.length;
      })
      .min(1, minOneFieldText)
      .required(requiredFieldText),
  });
