import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts";
import React from "react";

import { Pipelines } from "api/pipelines/pipelines.interface";
import { pipelineDetails } from "../../stats.constants";

import styles from "./custom-tooltip.module.scss";

interface Props {
  tooltipProps: TooltipProps<ValueType, NameType>;
}

export const CustomTooltip: React.FC<Props> = ({ tooltipProps }) => {
  const sortedData = tooltipProps.payload?.sort(
    (a, b) => (b.value as number) - (a.value as number),
  );

  return (
    <div className={styles.tooltipWrapper}>
      <h4 className={styles.title}>
        {tooltipProps.payload?.[0]?.payload?.range || tooltipProps.label}
      </h4>

      {sortedData?.map((pipeline) => {
        const details = pipelineDetails[pipeline.dataKey as Pipelines];

        return (
          <div key={pipeline.dataKey} className={styles.row}>
            <div className={styles.colorBox} style={{ backgroundColor: details.fill }} />
            <p className={styles.usage}>
              <span>
                {details?.fullName || details.label} -{" "}
                <strong>{pipeline.payload?.[pipeline.dataKey as Pipelines]}</strong>
              </span>
            </p>
          </div>
        );
      })}
    </div>
  );
};
