import { array, boolean, object, SchemaOf, string } from "yup";
import { NonNullableKeys, sanitizeFileName } from "@epcnetwork/core-ui-kit";

import {
  emailColumnValidation,
  headersValidation,
  selectMultipleValidation,
  selectValidation,
} from "utils";
import { requiredFieldText } from "constants/form.constants";
import { SuppressionImportType, SuppressionImportValues } from "./suppression-import.types";

export const supportedFormats = [
  ".csv",
  "text/csv",
  "application/csv",
  "text/x-csv",
  "application/x-csv",
];

export const suppressionEntity = "email-suppression";

export const getInitialData = (item: File): SuppressionImportType => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  emailIndex: -1,
  categories: [],
  status: "",
  esps: [],
  listIds: [],
  type: "",
});

export const validationSchema: SchemaOf<NonNullableKeys<SuppressionImportValues>> = object().shape({
  headerValues: object({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
  categories: array().of(string().required()),
  status: string().default(""),
  esps: array().of(string().required()),
  listIds: selectMultipleValidation(),
  type: selectValidation(),
});
