import { List } from "@epcnetwork/core-ui-kit";

import { ListModel } from "models";
import { apiMiddleware } from "api/api.middleware";
import { ListData } from "./lists.types";

export const getLists = apiMiddleware<List<ListModel>>()({
  method: "get",
  endpoint: "/lists",
});

export const getList = apiMiddleware<ListModel>()({
  method: "get",
  endpoint: "/lists/:id",
});

export const postList = apiMiddleware<ListModel, ListData>()({
  method: "post",
  endpoint: "/lists",
});

export const putList = apiMiddleware<ListModel, ListData>()({
  method: "put",
  endpoint: "/lists/:id",
});

export const deleteList = apiMiddleware<null>()({
  method: "delete",
  endpoint: "/lists/:id",
});
