import { useAbility } from "@casl/react";
import { AnyAbility } from "@casl/ability";

import { AbilityContext } from "components/client/authorization/authorization";

const useAuthorization = (): AnyAbility => {
  return useAbility(AbilityContext);
};

export { useAuthorization };
