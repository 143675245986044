import Countdown, { CountdownRenderProps } from "react-countdown";
import { FC, useCallback } from "react";
import { format } from "date-fns";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";

import deleteIcon from "assets/images/delete.svg";
import clockImg from "assets/images/clock.svg";
import calendarImg from "assets/images/calendar.svg";
import styles from "../job-row.module.css";

type StartedAtCellProps = {
  startedAt: Date | undefined;
  onDeleteClick: () => void;
};

export const StartedAtCell: FC<StartedAtCellProps> = ({ startedAt, onDeleteClick }) => {
  const renderer = useCallback(
    ({ seconds, completed }: CountdownRenderProps) => {
      if (completed) {
        return (
          <div className={styles.date}>
            <div className={styles.dateItem}>
              <img src={calendarImg} alt="" width={11} height={11} />
              {startedAt ? format(startedAt, "MMM dd, yyyy") : "-"}
            </div>
            <div className={styles.dateItem}>
              <img src={clockImg} alt="" width={11} height={11} />
              {startedAt ? format(startedAt, "hh:mm aaa") : "-"}
            </div>
          </div>
        );
      } else {
        return (
          <>
            <span>{!!seconds && `${seconds}s`} left</span>
            <Tooltip
              triggerElement={() => (
                <Button appearance="none" onClick={onDeleteClick} aria-label="Delete job">
                  <img src={deleteIcon} width="14" height="16" alt="Trash icon" />
                </Button>
              )}
              trigger="hover"
              position="top"
            >
              <span>
                The job is not processed yet. <br /> You can delete the job without adding to
                Database
              </span>
            </Tooltip>
          </>
        );
      }
    },
    [onDeleteClick, startedAt],
  );

  return startedAt ? <Countdown date={startedAt} renderer={renderer} /> : null;
};
