import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  CheckOption,
  Container,
  List,
  Modal,
  Table,
  Tooltip,
  useDevice,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { SuppressionListsFormPage } from "pages/suppression-lists";
import { SuppressionModel } from "models";
import { useAuthorization, usePayload } from "hooks";
import { SUPPRESSION_IMPORT_PAGE } from "constants/routes.constants";
import {
  SUPPRESSION_FILE_PERMISSION,
  SUPPRESSION_LIST_PERMISSION,
  SUPPRESSION_PERMISSION,
} from "constants/roles.constants";
import { searchMinLength } from "constants/form.constants";
import { DeleteErrorModal, Toolbar } from "components";
import { getSuppressions } from "api";
import { SuppressionCreateForm, SuppressionEditForm } from "../form";
import { SuppressionRow } from "./suppression-table-row/suppression-table-row";
import { QueryParams } from "./suppression-list.types";
import { initialQueryParams, nonClearableKeys, tableColumns } from "./suppression-list.constants";
import { SuppressionListFilters } from "./list-filters/suppression-list-filters";
import { ExportSuppressionModal } from "./export-modal/export-suppression-modal";
import { DeleteSuppressionModal } from "./delete-modal/delete-suppression-modal";

import suppressionImg from "assets/images/suppression-list.svg";
import saveFileImg from "assets/images/save-file.svg";
import addImg from "assets/images/add-with-background.svg";
import styles from "./suppression-list.module.css";

const SuppressionListPage: FC = () => {
  const ability = useAuthorization();
  const navigate = useNavigate();

  const { isMobileDevice } = useDevice();

  const [isCreateListOpen, setCreateListOpen] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [editId, setEditId] = useState<number | null>(null);
  const [suppressionToDelete, setSuppressionToDelete] = useState<SuppressionModel | null>(null);

  const [email, setEmail] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { queryString, searchValue, setSearch, query } = useFilters<QueryParams>({
    initialState: initialQueryParams,
    searchOptions: { searchKey: "value", searchMinLength },
    nonClearableKeys,
  });

  const handleSetEmail = (value: string) => {
    setEmail(value);
  };

  const apiResponse = useFetch(getSuppressions.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, list, refresh } = usePayload(apiResponse);

  const pagination = usePagination({ listPayload: apiResponse.payload });

  const handleEditSuppression = (id: number | null) => {
    setEditId(id);
    setModalOpen(true);
  };

  const handleDeleteSuppression = (item: SuppressionModel) => {
    setSuppressionToDelete(item);
    setIsDeleteModalOpen(true);
  };

  const handleImport = () => {
    navigate(SUPPRESSION_IMPORT_PAGE.path);
  };

  const openModal = () => {
    setIsTooltipOpen(false);
    setModalOpen(true);
  };

  const closeModal = () => {
    setEditId(null);
    setModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const toggleListCreation = (isOpen: boolean) => () => {
    setCreateListOpen(isOpen);
  };

  const toggleExportModal = (isOpen: boolean) => () => {
    setIsExportModalOpen(isOpen);
  };

  const handleTooltipOpen = () => setIsTooltipOpen(true);

  const handleItemChange = (data: List<SuppressionModel>) => {
    apiResponse.actions.setData(data);
    setModalOpen(false);
    setEditId(null);
  };

  const onCreateSuccess = (values: SuppressionModel[]) => {
    const newData: List<SuppressionModel> = {
      ...payload,
      data: [...values, ...list],
      total: Number(payload.total) + values.length,
    };
    handleItemChange(newData);
  };

  const onUpdateSuccess = (values: SuppressionModel) => {
    const filteredList = list.filter((listElem) => listElem.id !== values.id);

    const newData: List<SuppressionModel> = {
      ...payload,
      data: [values, ...filteredList],
      total: Number(payload.total),
    };
    handleItemChange(newData);
  };

  const buttonOption = {
    export: "Export",
    newListType: "New List",
  };

  const canCreateFromFile = ability.can("create", SUPPRESSION_FILE_PERMISSION.name);
  const canCreateSuppression = ability.can("create", SUPPRESSION_PERMISSION.name);
  const canExport = ability.can("read", SUPPRESSION_PERMISSION.name);
  const canCreateList = ability.can("create", SUPPRESSION_LIST_PERMISSION.name);

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch} filters={<SuppressionListFilters />}>
        <div className={styles.buttons}>
          {canExport && (
            <Button
              appearance="text"
              onClick={toggleExportModal(true)}
              className={styles.btn}
              disabled={payload?.total === 0}
            >
              <Tooltip
                triggerElement={<img src={saveFileImg} alt="" width={20} height={20} />}
                trigger="hover"
              >
                {buttonOption.export}
              </Tooltip>
              <span>{buttonOption.export}</span>
            </Button>
          )}
          {canCreateList && (
            <Button appearance="text" onClick={toggleListCreation(true)} className={styles.btn}>
              <Tooltip
                triggerElement={<img src={suppressionImg} alt="" width={20} height={20} />}
                trigger="hover"
              >
                {buttonOption.newListType}
              </Tooltip>
              <span>{buttonOption.newListType}</span>
            </Button>
          )}
          {(canCreateFromFile || canCreateSuppression) && (
            <Tooltip
              triggerElement={
                <Button className={styles.tooltipButtons} onClick={handleTooltipOpen}>
                  <img src={addImg} alt="" width={16} height={16} />
                  Suppress New Item(s)
                </Button>
              }
              position="bottom"
              trigger="click"
              stretchWidth
              isOpen={isTooltipOpen}
              isManualControl
              outsideClickOption={CheckOption.contains}
              onCanShowChange={setIsTooltipOpen}
            >
              {canCreateFromFile && (
                <Button appearance="text" onClick={handleImport} className={styles.btnMenu}>
                  Upload From File
                </Button>
              )}
              {canCreateSuppression && (
                <Button onClick={openModal} appearance="text" className={styles.btnMenu}>
                  Create items
                </Button>
              )}
            </Tooltip>
          )}
        </div>
      </Toolbar>
      <Table
        {...apiResponse}
        error={apiResponse.error?.message}
        list={list}
        columns={tableColumns}
        pagination={{ ...pagination, className: cn({ [styles.pagination]: isMobileDevice }) }}
        row={(item) => (
          <SuppressionRow
            key={item.id}
            item={item}
            refresh={refresh}
            handleEditSuppression={handleEditSuppression}
            handleDeleteSuppression={handleDeleteSuppression}
            handleSetEmail={handleSetEmail}
          />
        )}
      />
      <Modal isOpen={isModalOpen} setClose={closeModal} contentClassName={styles.modal}>
        {!!editId && (
          <SuppressionEditForm
            closeModal={closeModal}
            handleItemChange={onUpdateSuccess}
            editId={editId}
          />
        )}
        {!editId && (
          <SuppressionCreateForm closeModal={closeModal} handleItemChange={onCreateSuccess} />
        )}
      </Modal>

      <Modal
        isOpen={isCreateListOpen}
        setClose={toggleListCreation(false)}
        contentClassName={styles.listTypeModal}
      >
        <SuppressionListsFormPage closeModal={toggleListCreation(false)} editId={null} />
      </Modal>

      <Modal
        isOpen={isExportModalOpen}
        setClose={toggleExportModal(false)}
        contentClassName={styles.exportModal}
      >
        <ExportSuppressionModal closeModal={toggleExportModal(false)} appliedFilters={query} />
      </Modal>
      {suppressionToDelete && email && (
        <Modal isOpen={isDeleteModalOpen} setClose={closeDeleteModal} size="small">
          <DeleteSuppressionModal
            closeModal={closeDeleteModal}
            itemToDelete={suppressionToDelete}
            refresh={refresh}
            email={email}
          />
        </Modal>
      )}
    </Container>
  );
};
export { SuppressionListPage };
