import { boolean, object, SchemaOf } from "yup";
import { sanitizeFileName } from "@epcnetwork/core-ui-kit";

import { emailColumnValidation, headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { MergeFileData, MergeValues } from "./merge.types";

export const supportedFormats = [csvFormat, txtFormat].flat();
export const maxFiles = 15;

export const getInitialData = (item: File): MergeFileData => ({
  fileName: sanitizeFileName(item.name),
  emailIndex: -1,
  headers: [],
  hasHeaders: false,
});

export const validationSchema: SchemaOf<MergeValues> = object({
  headerValues: object({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
});
