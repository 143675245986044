import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  List,
  Modal,
  Table,
  useDevice,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { ListModel } from "models";
import { useAuthorization, usePayload } from "hooks";
import { SUPPRESSION_LIST_PERMISSION } from "constants/roles.constants";
import { searchMinLength } from "constants/form.constants";
import { DeleteErrorModal, Toolbar } from "components";
import { getLists } from "api";
import { SuppressionListsFormPage } from "../form/suppression-lists-form.page";
import { ListTableRow } from "./table-row/list-table-row";
import { QueryParams } from "./suppression-lists.types";
import { initialQueryParams, nonClearableKeys, tableColumns } from "./suppression-lists.constants";
import { ListFilters } from "./list-filters/list-filters";

import addImg from "assets/images/add-with-background.svg";
import styles from "./suppression-lists.module.css";

export const SuppressionListsPage: FC = () => {
  const ability = useAuthorization();

  const { isMobileDevice } = useDevice();

  const [isModalOpen, setModalOpen] = useState(false);
  const [deletePermissionsOpen, setDeletePermissionsOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<number | null>(null);
  const [value, setValue] = useState("");

  const { queryString, searchValue, setSearch } = useFilters<QueryParams>({
    initialState: initialQueryParams,
    searchOptions: { searchKey: "value", searchMinLength },
    nonClearableKeys,
  });

  const dependencies = [queryString];
  const apiResponse = useFetch(getLists.setQueryParams(queryString), {
    dependencies,
  });
  const pagination = usePagination({ listPayload: apiResponse.payload });
  const { payload, list, refresh } = usePayload(apiResponse);

  const handleEdit = (id: number) => {
    setEditId(id);
    setModalOpen(true);
  };

  const handleSetValue = (value: string) => {
    setValue(value);
  };

  const togglePermissionErrorOpen = (isOpen: boolean) => () => {
    setDeletePermissionsOpen(isOpen);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setEditId(null);
    setModalOpen(false);
  };

  const handleItemChange = (values: ListModel) => {
    const elementExists = list.some((el) => el.id === values.id);
    const filteredList = list.filter((listElem) => listElem.id !== values.id);
    const newData: List<ListModel> = {
      ...payload,
      data: [values, ...filteredList],
      total: elementExists ? Number(payload.total) : Number(payload.total) + 1,
    };
    apiResponse.actions.setData(newData);
    setModalOpen(false);
  };

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch} filters={<ListFilters />}>
        {ability.can("create", SUPPRESSION_LIST_PERMISSION.name) && (
          <Button className={styles.addBtn} onClick={openModal}>
            <img src={addImg} alt="" width={16} height={16} />
            <span className={styles.addBtnText}>New List</span>
          </Button>
        )}
      </Toolbar>
      <Table
        {...apiResponse}
        error={apiResponse.error?.message}
        list={list}
        columns={tableColumns}
        pagination={{
          ...pagination,
          className: cn({ [styles.pagination]: isMobileDevice }),
        }}
        row={(item) => (
          <ListTableRow
            key={item.id}
            item={item}
            refresh={refresh}
            handleEdit={handleEdit}
            handleSetValue={handleSetValue}
            openDeletePermissionsError={togglePermissionErrorOpen(true)}
          />
        )}
      />

      <Modal isOpen={isModalOpen} setClose={closeModal} contentClassName={styles.modal}>
        <SuppressionListsFormPage
          closeModal={closeModal}
          handleItemChange={handleItemChange}
          editId={editId}
        />
      </Modal>

      <DeleteErrorModal
        closeModal={togglePermissionErrorOpen(false)}
        isOpen={deletePermissionsOpen}
        value={value}
      />
    </Container>
  );
};
