import * as process from "process";

const appEnv = process.env.REACT_APP_ENV || "local";

interface Environment {
  apiUrl: string;
  smartlookKey: string;
}

interface Environments {
  [key: string]: Environment;
}

const environments: Environments = {
  local: {
    apiUrl: process.env.REACT_APP_API || "http://localhost:3001",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
  },
  development: {
    apiUrl: process.env.REACT_APP_API || "https://apidev.mergex.dev",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
  },
  staging: {
    apiUrl: process.env.REACT_APP_API || "https://api.mergex.dev",
    smartlookKey: process.env.REACT_APP_SMARTLOOK_KEY || "",
  },
  prod: {
    apiUrl: "",
    smartlookKey: "",
  },
};

export const appEnvironment: Environment = environments[appEnv];
