import { useLocation } from "react-router-dom";
import { memo, useMemo } from "react";
import cn from "classnames";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";

import { getActiveRoute } from "utils";
import { Menu } from "./components/menu";

import userImg from "assets/images/user.svg";
import openedImg from "assets/images/navigation-menu-opened.svg";
import closedImg from "assets/images/navigation-menu-closed.svg";
import styles from "./navbar.module.css";

type NavbarProps = {
  toggleSidebar: () => void;
  isSidebarCollapsed: boolean;
};

export const Navbar = memo<NavbarProps>(({ toggleSidebar, isSidebarCollapsed }) => {
  const location = useLocation();

  const activeRoute = useMemo(() => getActiveRoute(location), [location]);

  const menuIcon = isSidebarCollapsed ? closedImg : openedImg;
  const hamburgerStyles = cn(styles.hamburger, { [styles.open]: !isSidebarCollapsed });

  return (
    <header className={styles.container} data-testid="navbar">
      <Button appearance="transparent" className={hamburgerStyles} onClick={toggleSidebar}>
        <img src={menuIcon} alt="" width={20} height={20} />
      </Button>
      <div className={styles.title}>{activeRoute.name}</div>
      <div className={styles.spacer} />
      <Tooltip
        triggerElement={
          <Button appearance="transparent" imageBtn>
            <img src={userImg} alt="" width={36} height={36} />
          </Button>
        }
        position="bottom-right"
        trigger="click"
      >
        <Menu />
      </Tooltip>
    </header>
  );
});
