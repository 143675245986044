import { FC } from "react";
import cn from "classnames";

import { PipelineRoutes } from "constants/pipelines.constants";

import styles from "./pipeline-header-section.module.css";

type PipelineHeaderSectionProps = {
  pipeline: PipelineRoutes;
};

const PipelineHeaderSection: FC<PipelineHeaderSectionProps> = ({ pipeline }) => {
  const { src, db, title, subtitle } = pipeline;
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.cardImageWrapper, { [styles.cardImageWrapperWithBd]: db })}>
        <img className={cn(styles.cardImage, { [styles.cardImageWithBd]: db })} src={src} alt="" />
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

export { PipelineHeaderSection };
