import { List } from "@epcnetwork/core-ui-kit";

import { SuppressionModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { PostSuppressionFormData, PostSuppressionFileFormData } from "./suppression.interfaces";

export const getSuppressions = apiMiddleware<List<SuppressionModel>>()({
  method: "get",
  endpoint: "/suppressions",
});

export const getSuppression = apiMiddleware<SuppressionModel>()({
  method: "get",
  endpoint: "/suppressions/:suppressionId",
});

export const postSuppression = apiMiddleware<SuppressionModel[], PostSuppressionFormData>()({
  method: "post",
  endpoint: "/suppressions",
});

export const putSuppression = apiMiddleware<SuppressionModel, PostSuppressionFormData>()({
  method: "put",
  endpoint: "/suppressions/:id",
});

export const postSuppressionFile = apiMiddleware<null, PostSuppressionFileFormData>()({
  method: "post",
  endpoint: "/suppressions/file/insert",
  isFormData: true,
});

export const deleteSuppression = apiMiddleware<null>()({
  method: "delete",
  endpoint: "/suppressions/:suppressionId",
});
