import React, { useState } from "react";
import { Loader, Select, SelectOption, useFetch } from "@epcnetwork/core-ui-kit";

import { usePayload, useShowContent } from "hooks";
import { Pipelines } from "api/pipelines/pipelines.interface";
import { getRangeMetrics } from "api";
import { Range } from "../stats.types";
import { pipelineDetails, pipelineOptions } from "../stats.constants";
import { RangeSwitcher } from "../range-switcher/range-switcher";
import { LineChart } from "./line-chart/line-chart";

import styles from "./pipeline-range-usage-chart.module.scss";

const initialPipelines = pipelineOptions.map((option) => option.value);

export const PipelineRangeUsageChart: React.FC = () => {
  const [range, setRange] = useState<Range>(7);
  const [selectedPipelines, setSelectedPipelines] = useState<Pipelines[]>(initialPipelines);

  const apiResponse = useFetch(getRangeMetrics.setQueryParams(`?days=${range}`), {
    dependencies: [range],
  });
  const { showLoader, showContent } = useShowContent(apiResponse);
  const { payload: data } = usePayload(apiResponse);

  const handlePipelineChange = (options: SelectOption<Pipelines>[]) => {
    const pipelines = options.map((item) => item.value);

    setSelectedPipelines(pipelines);
  };

  const handlePipelineSelect = (value: Pipelines) => () => {
    if (selectedPipelines.includes(value)) {
      setSelectedPipelines((prevState) => prevState.filter((pipeline) => pipeline !== value));
    } else {
      setSelectedPipelines((prevState) => [...prevState, value]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Range usage</h3>
        <RangeSwitcher currentRange={range} setRange={setRange} />
      </div>

      {showLoader && <Loader />}
      {showContent && !showLoader && <LineChart data={data} currentPipelines={selectedPipelines} />}
      {showContent && !showLoader && (
        <div className={styles.row}>
          {pipelineOptions.map(({ value, label }) => {
            const details = pipelineDetails[value];
            const selected = selectedPipelines.includes(value);

            return (
              <button
                key={value}
                type="button"
                className={styles.button}
                onClick={handlePipelineSelect(value)}
              >
                <div
                  className={selected ? styles.selected : ""}
                  style={{ backgroundColor: selected ? details.fill : "transparent" }}
                />
                <span style={{ color: details.fill }}>{label}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
