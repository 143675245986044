import React from "react";
import { Container } from "@epcnetwork/core-ui-kit";

import { Summary } from "./summary/summary";
import { PipelineUsageChart } from "./pipeline-usage-chart/pipeline-usage-chart";
import { PipelineRangeUsageChart } from "./pipeline-range-usage-chart/pipeline-range-usage-chart";

import styles from "./stats.module.scss";

export const StatsPage: React.FC = () => {
  return (
    <Container contentClassName={styles.container}>
      <PipelineUsageChart />
      <Summary />
      <PipelineRangeUsageChart />
    </Container>
  );
};
