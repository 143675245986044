import { FC, useState } from "react";
import cn from "classnames";
import {
  Form,
  FormField,
  MessageField,
  Button,
  SelectOption,
  SelectOptionValue,
  useQuery,
  useCall,
} from "@epcnetwork/core-ui-kit";

import { Hint } from "components";
import { getSuppressionsExport } from "api";
import { getFileName } from "../suppression-list.constants";
import { ExportModalProps, ExportValues } from "./export-suppression-modal.types";
import { columns, validationSchema } from "./export-suppression-modal.constants";
import { ConfirmMessage } from "./confirm-message/confirm-message";

import styles from "./export-suppression-modal.module.css";

export const ExportSuppressionModal: FC<ExportModalProps> = ({ closeModal, appliedFilters }) => {
  const [selectedColumns, setSelectedColumns] = useState<SelectOptionValue[]>([]);
  const [fileName, setFileName] = useState(getFileName());
  const [isConfirm, setIsConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { onCallError, onCallSuccess, submit } = useCall(getSuppressionsExport);
  onCallSuccess(() => {
    setIsConfirm(true);
  });
  onCallError((error) => {
    setErrorMessage(error.message);
  });

  const { stringify } = useQuery();

  const onSubmit = () => {
    const params = stringify({ filename: fileName, columns: selectedColumns, ...appliedFilters });
    getSuppressionsExport.setQueryParams(params);
    submit();
  };

  const filtersNames = Object.getOwnPropertyNames(appliedFilters);

  const filters = Object.entries(filtersNames).map(([, value]) => ({
    value,
    label: value,
    locked: true,
  }));

  const initialValues: ExportValues = {
    filters: filters.map((option: SelectOption) => option.value),
    columns: [],
    filename: fileName,
  };

  const handleColumnSelect = (selectedOptions: SelectOption[]) => {
    const cols = selectedOptions.map((option: SelectOption) => option.value);
    setSelectedColumns(cols);
  };

  const handleFileNameChange = (value: string) => setFileName(value);

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ isValid }) => (
        <>
          {!isConfirm ? (
            <>
              <div className={styles.title}>Export</div>
              {errorMessage && <MessageField message={errorMessage} />}

              {!!filters.length && (
                <FormField
                  type="select"
                  name="filters"
                  label="Applied filter list"
                  placeholder="None applies..."
                  options={filters}
                  isMulti
                  disableArrow
                  disableClearing
                />
              )}
              <FormField
                type="select"
                name="columns"
                label="Select Columns to Export"
                placeholder="None selected..."
                options={columns}
                onChange={handleColumnSelect}
                isMulti
                required
              />
              <FormField
                type="text"
                name="filename"
                label="File Name"
                placeholder="File Name"
                onChange={handleFileNameChange}
              />
              <Hint type="warning">
                Please, make sure you select at least 1 column for export: <strong>Value</strong> or{" "}
                <strong>MD5.</strong>
              </Hint>
              <div className={cn(styles.buttons, styles.buttonsWrapper)}>
                <Button appearance="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Export
                </Button>
              </div>
            </>
          ) : (
            <ConfirmMessage closeModal={closeModal} fileName={fileName} />
          )}
        </>
      )}
    </Form>
  );
};
