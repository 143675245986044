import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  MessageField,
  FormButtons,
  useAutocomplete,
  useFileParser,
  FormField,
} from "@epcnetwork/core-ui-kit";

import { FormField as LocalFormField, Hint } from "components";
import { getLists } from "api";
import { SuppressionFileItem, SuppressionValues } from "../suppression.types";
import { validationSchema } from "../suppression.constants";

import styles from "../suppression.module.css";

type SuppressionModalFormProps = {
  file: SuppressionFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SuppressionFileItem) => void;
};

export const SuppressionFormModal: FC<SuppressionModalFormProps> = ({
  file,
  setClose,
  onSubmitClick,
}) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<SuppressionValues>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      emailIndex: file.data.emailIndex,
      headers: file.data.headers,
    },
    listIds: file.data.listIds,
  });

  const listTypeAutocomplete = useAutocomplete(getLists, "id", "name");

  const listTypeMapOptions = (
    options: (typeof listTypeAutocomplete)["fetchOptions"],
  ): (typeof listTypeAutocomplete)["fetchOptions"] => {
    return options.filter((option) => !option.isGlobal);
  };

  const onSubmit = (
    values: SuppressionValues,
    { setSubmitting }: FormikHelpers<SuppressionValues>,
  ) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        listIds: values.listIds,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.modal}>
      <h2 className={styles.title}>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <Hint type="info" className={styles.info}>
          By default, the cleaning process uses the global suppression list. If you require
          additional lists, please choose them below.
        </Hint>

        <FormField
          type="select"
          name="listIds"
          label="Lists"
          placeholder="Select from the list"
          asyncOptions={{
            ...listTypeAutocomplete,
            fetchOptions: listTypeMapOptions(listTypeAutocomplete.fetchOptions),
          }}
          isMulti
          isSearchable
        />

        <LocalFormField
          type="file-table"
          name="headerValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
        />
        <FormButtons>
          <Button appearance="secondary" onClick={setClose}>
            Cancel
          </Button>
          <Button type="submit">Set values</Button>
        </FormButtons>
      </Form>
    </Modal>
  );
};
