export const csvFormat = ["file/csv", ".csv"];
export const xlsxFormat = ["file/xlsx", ".xlsx"];
export const xlsFormat = ["file/xls", ".xls"];
export const txtFormat = ["file/txt", ".txt"];
export const pdfFormat = ["file/pdf", ".pdf"];
export const docFormat = ["file/doc", ".doc"];
export const docxFormat = ["file/docx", ".docx"];
export const rtfFormat = ["file/rtf", ".rtf"];

export const msgFormat = ["file/msg", ".msg"];
export const tarFormat = ["file/tar", ".tar"];
export const gzFormat = ["file/gz", ".gz"];
export const zipFormat = ["file/zip", ".zip"];
