import { List } from "@epcnetwork/core-ui-kit";

import { PipelineWarmUpData } from "pages/pipelines/forms/warm-up/warm-up.types";
import { PipelineSuppressionData } from "pages/pipelines/forms/suppression/suppression.types";
import { PipelineSumClicksData } from "pages/pipelines/forms/sum-clicks/sum-clicks.types";
import { PipelineSplitData } from "pages/pipelines/forms/split/split.types";
import { PipelineSHA256Data } from "pages/pipelines/forms/sha256/sha256.types";
import { PipelineReverseData } from "pages/pipelines/forms/reverse/reverse.types";
import { PipelineMergeData } from "pages/pipelines/forms/merge/merge.types";
import { PipelineMD5Data } from "pages/pipelines/forms/md5/md5.types";
import { PipelineMatchAndEliminateData } from "pages/pipelines/forms/match-and-eliminate/match-and-eliminate.types";
import { PipelineMatchData } from "pages/pipelines/forms/match/match.types";
import { PipelineISPDomainSplitterData } from "pages/pipelines/forms/isp-splitter/isp-splitter.types";
import { PipelineExtractData } from "pages/pipelines/forms/extract/extract.types";
import { PipelineDomainPurifyData } from "pages/pipelines/forms/domain-purify/domain-purify.types";
import { PipelineDiffAndCommonData } from "pages/pipelines/forms/diff-common/diff-common.types";
import { PipelineDeduplicateData } from "pages/pipelines/forms/deduplicate/deduplicate.types";
import { PipelineCutData } from "pages/pipelines/forms/cut/cut.types";
import { PipelineConvertData } from "pages/pipelines/forms/convert/convert.types";
import { SuppressionModel } from "models";
import { apiMiddleware } from "api";
import {
  PipelinesDomain,
  PipelineFileEmailsDomainsGetData,
  SummaryMetricsResponse,
  RangeMetricsResponse,
} from "./pipelines.interface";

export const getFileEmailsDomains = apiMiddleware<
  { domains: PipelinesDomain[]; total: number },
  PipelineFileEmailsDomainsGetData | FormData
>()({
  method: "post",
  endpoint: "/pipeline/counters/isps",
  isFormData: true,
});

export const getSummaryMetrics = apiMiddleware<SummaryMetricsResponse[]>()({
  method: "get",
  endpoint: "/pipeline/metrics/summary",
});

export const getRangeMetrics = apiMiddleware<RangeMetricsResponse>()({
  method: "get",
  endpoint: "/pipeline/metrics/range",
});

export const getSuppressionsExport = apiMiddleware<List<SuppressionModel>>()({
  baseUrl: "",
  method: "get",
  endpoint: "/pipeline/suppressions/export",
});

export const postDeduplicateFile = apiMiddleware<undefined, PipelineDeduplicateData>()({
  method: "post",
  endpoint: "/pipeline/jobs/deduplicate",
  isFormData: true,
});

export const postDiffAndCommonFile = apiMiddleware<undefined, PipelineDiffAndCommonData>()({
  method: "post",
  endpoint: "/pipeline/jobs/diffAndCommon",
  isFormData: true,
});

export const postExtractFile = apiMiddleware<undefined, PipelineExtractData>()({
  method: "post",
  endpoint: "/pipeline/jobs/extract",
  isFormData: true,
});

export const postISPDomainSplitterFile = apiMiddleware<undefined, PipelineISPDomainSplitterData>()({
  method: "post",
  endpoint: "/pipeline/jobs/ispDomainSplitter",
  isFormData: true,
});

export const postMatchFile = apiMiddleware<undefined, PipelineMatchData>()({
  method: "post",
  endpoint: "/pipeline/jobs/match",
  isFormData: true,
});

export const postMD5File = apiMiddleware<undefined, PipelineMD5Data>()({
  method: "post",
  endpoint: "/pipeline/jobs/md5",
  isFormData: true,
});

export const postSHA256File = apiMiddleware<undefined, PipelineSHA256Data>()({
  method: "post",
  endpoint: "/pipeline/jobs/sha256",
  isFormData: true,
});

export const postMergeFile = apiMiddleware<undefined, PipelineMergeData>()({
  method: "post",
  endpoint: "/pipeline/jobs/merge",
  isFormData: true,
});

export const postSumClicksFile = apiMiddleware<undefined, PipelineSumClicksData>()({
  method: "post",
  endpoint: "/pipeline/jobs/sumClicks",
  isFormData: true,
});

export const postSuppressFile = apiMiddleware<undefined, PipelineMatchAndEliminateData>()({
  method: "post",
  endpoint: "/pipeline/jobs/matchAndEliminate",
  isFormData: true,
});

export const postNewSuppressionFile = apiMiddleware<undefined, PipelineSuppressionData>()({
  method: "post",
  endpoint: "/pipeline/jobs/suppress",
  isFormData: true,
});

export const postReverseFile = apiMiddleware<undefined, PipelineReverseData>()({
  method: "post",
  endpoint: "/pipeline/jobs/reverse",
  isFormData: true,
});

export const postSplitFile = apiMiddleware<undefined, PipelineSplitData>()({
  method: "post",
  endpoint: "/pipeline/jobs/split",
  isFormData: true,
});

export const postWarmUpSplitterFile = apiMiddleware<undefined, PipelineWarmUpData>()({
  method: "post",
  endpoint: "/pipeline/jobs/warmUpSplitter",
  isFormData: true,
});

export const postDomainPurifyFile = apiMiddleware<undefined, PipelineDomainPurifyData>()({
  method: "post",
  endpoint: "/pipeline/jobs/domainPurify",
  isFormData: true,
});

export const postConvertFile = apiMiddleware<undefined, PipelineConvertData>()({
  method: "post",
  endpoint: "/pipeline/jobs/convert",
  isFormData: true,
});

export const postCutFile = apiMiddleware<undefined, PipelineCutData>()({
  method: "post",
  endpoint: "/pipeline/jobs/cut",
  isFormData: true,
});
