import { FC, useEffect, useMemo, useRef, useState } from "react";
import { FormikHelpers } from "formik";
import cn from "classnames";
import {
  Button,
  Form,
  FormField,
  notification,
  useAutocomplete,
  useSubmit,
} from "@epcnetwork/core-ui-kit";

import { ListModel } from "models";
import { useUser } from "hooks";
import { PostSuppressionFormData } from "api/suppression/suppression.interfaces";
import { getLists, postSuppression } from "api";
import {
  suppressionCategoryOptions,
  suppressionEspOptions,
  suppressionStatusOptions,
} from "../list/suppression-list.constants";
import { CreateSuppressionValues, SuppressionCreateFormProps } from "./suppression-form.types";
import {
  createSuppressionInitialValues,
  suppressionTypes,
  createValidationSchema,
} from "./suppression-form.constants";
import { SuppressPopup } from "./suppress-popup/suppress-popup";

import styles from "./suppression-form.module.css";

export const SuppressionCreateForm: FC<SuppressionCreateFormProps> = ({
  closeModal,
  handleItemChange,
}) => {
  const ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const { isAdmin } = useUser();

  const [textareaValue, setTextareaValue] = useState<string>("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLists, setSelectedLists] = useState<ListModel[]>([]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "100px";
      const scrollHeight = ref.current.scrollHeight;

      ref.current.style.height = `${scrollHeight}px`;
    }
  }, [ref, textareaValue]);

  const handleClosePopup = () => {
    setError("");
    setIsOpen(false);
  };

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postSuppression);
  onSubmitSuccess((payload) => {
    handleItemChange(payload);
    const title = "Suppression added";
    const subtitle = "You have successfully added suppression.";

    notification.success(title, subtitle);
  });
  onSubmitError((error) => setError(error.message));

  const handleSubmit = async (
    data: CreateSuppressionValues,
    helpers: FormikHelpers<CreateSuppressionValues>,
  ) => {
    const values = data.values.split(/\r?\n/).filter(Boolean);
    const payload: PostSuppressionFormData = { ...data, values };
    // @ts-ignore
    await onSubmit(payload, helpers);
  };

  const listTypeAutocomplete = useAutocomplete(getLists, "id", "name");

  const { isOptionSelected, isFirstListGlobal } = useMemo(() => {
    const isOptionSelected = selectedLists.length > 0;
    const isFirstListGlobal = isOptionSelected && selectedLists[0].isGlobal;

    return { isOptionSelected, isFirstListGlobal };
  }, [selectedLists]);

  const mapListOptions = (
    options: (typeof listTypeAutocomplete)["fetchOptions"],
  ): (typeof listTypeAutocomplete)["fetchOptions"] => {
    const onlyLocalEnabled = options.map((option) =>
      option.isGlobal ? { ...option, locked: true } : option,
    );
    const onlyGlobalEnabled = options.map((option) =>
      option.isGlobal ? option : { ...option, locked: true },
    );

    if (!isAdmin) return onlyLocalEnabled;
    if (!isOptionSelected) return options;
    if (isFirstListGlobal) return onlyGlobalEnabled;
    return onlyLocalEnabled;
  };

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const getListsLabel = () => {
    if (!isOptionSelected) return "Lists";
    if (isFirstListGlobal) return "Lists (global)";
    if (!isFirstListGlobal) return "Lists (local)";
  };

  return (
    <div className={styles.container}>
      <Form
        onSubmit={handleSubmit}
        validationSchema={createValidationSchema}
        initialValues={createSuppressionInitialValues}
        enableReinitialize
      >
        {({ isValid }) => {
          return (
            <>
              <div className={styles.title}>Suppression Form</div>
              <div className={styles.row}>
                <FormField
                  type="select"
                  name="type"
                  label="Type"
                  placeholder="Choose type from the list"
                  options={suppressionTypes}
                  required
                />
                <FormField
                  type="select"
                  name="categories"
                  label="Categories"
                  placeholder="Choose Category(ies) from the list"
                  options={suppressionCategoryOptions}
                  className={styles.suppressionLabel}
                  isMulti
                  multilineChips
                  maxChipsVisible={3}
                />
                <FormField
                  type="textarea"
                  forwardRef={ref}
                  name="values"
                  label="Values"
                  placeholder="Type emails and separate them with a new line"
                  onChange={setTextareaValue}
                  inputClassName={styles.valuesTextarea}
                  required
                />
                <FormField
                  type="select"
                  name="esps"
                  label="Esp"
                  placeholder="Choose ESP(s) from the list"
                  options={suppressionEspOptions}
                  className={styles.suppressionLabel}
                  isMulti
                  multilineChips
                  maxChipsVisible={3}
                />
                <FormField
                  type="select"
                  name="status"
                  label="Email list verification service status"
                  placeholder="Choose ELVSS from the list"
                  options={suppressionStatusOptions}
                  className={styles.suppressionLabel}
                />
                <FormField
                  type="select"
                  name="listIds"
                  label={getListsLabel()}
                  placeholder="Select from the list"
                  asyncOptions={{
                    ...listTypeAutocomplete,
                    fetchOptions: mapListOptions(listTypeAutocomplete.fetchOptions),
                  }}
                  className={styles.suppressionLabel}
                  multilineChips
                  onChange={setSelectedLists}
                  maxChipsVisible={2}
                  isMulti
                  isSearchable
                  required
                />
              </div>

              {isOpen && (
                <SuppressPopup
                  isOpen={isOpen}
                  setClose={handleClosePopup}
                  value={textareaValue}
                  error={error}
                />
              )}
              <div className={cn(styles.buttons, styles.buttonsWrapper)}>
                <Button appearance="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button type="button" onClick={handleOpenPopup} disabled={!isValid}>
                  Submit
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
